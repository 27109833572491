import { useEffect, useState } from 'react'
import { MultiSelect } from 'primereact/multiselect'
import { Stats } from '../Stats'
import { serviceService } from 'services/serviceService'
import { ListBox } from 'primereact/listbox'
import Button from 'components/Button'
import { ManageSelectStores } from '../manageSelectStores'
import { DropDownData } from '../types'

const Four = (props: any) => {
  const [selectedService, setSelectedService] = useState<string[]>([])
  const [selectedServiceAux, setSelectedServiceAux] = useState<string[]>([])
  const [partnerListService, setPartnerListService] = useState<string[]>([])
  const [isServiceListShow, setIsServiceListShow] = useState(false)
  const [lazyLoading, setLazyLoading] = useState(false)
  const [services, setServices] = useState<DropDownData[]>([])
  const [selectServiceList, setSelectServiceList] = useState<DropDownData[]>([])
  const [isRemoveService, setIsRemoveService] = useState(false)

  useEffect(() => {
    setSelectedService([])
  }, [props.state?.form?.email])

  useEffect(() => {
    setLazyLoading(props.lazyLoading)
  }, [props.lazyLoading])

  useEffect(() => {
    if (props.isActive) handleService(!!props.state?.infoPartner.idParceiro)
  }, [props.isActive])

  const handleService = (isEdit: boolean) => {
    props.setLazyLoading(true)
    serviceService
      .getAllActive()
      .then(data => {
        var itemsAux = data.items.map(
          (item: any) =>
            ({
              name: item.nomeAbreviado,
              code: item.id.toString()
            } as DropDownData)
        )
        setServices(itemsAux)
        if (isEdit) {
          handlerServicesPartner(props.state?.infoPartner)
        }
      })
      .catch(error => {
        setServices([])
      })
      .finally(() => props.setLazyLoading(false))
  }

  const handlerServicesPartner = (info: any) => {
    const servicesIdList = info.servicos?.map(({ id }) => id.toString())

    setSelectedService(servicesIdList)
    setSelectedServiceAux(servicesIdList)
    setPartnerListService(servicesIdList)
  }

  const searchElement = (item: any) => {
    if (item && item.length > 0) {
      return item.map((index: string) => {
        return services.find(service => service.code === index) as any;
      })?.filter((x: any) => x);
    }
  }

  const handleClickLinkStore = updatedSelectedStore => {
    setSelectedService(updatedSelectedStore)
    setIsServiceListShow(false)
    setIsRemoveService(false)
  }

  const handleClickCancelLink = cancelAddNewStore => {
    setIsServiceListShow(false)
    setSelectedServiceAux(cancelAddNewStore)
    setIsRemoveService(false)
  }
  useEffect(() => {
    if (selectedService && selectedService.length > 0) {
      let servicesSelected = searchElement(selectedService);
      if (servicesSelected && servicesSelected.length > 0) {
        setSelectServiceList(servicesSelected)
      }
    }
  }, [selectedService])

  const getSelectedList = () => {
    return services.filter(item => selectedService.indexOf(item.code) != -1)
  }
  const getUnselectedList = () => {
    const unlinkedList = partnerListService.filter(
      item => selectedService.indexOf(item) == -1
    )
    return services.filter(item => unlinkedList.indexOf(item.code) != -1)
  }
  const hasChanges = () => {
    const hasUnlink =
      partnerListService.filter(item => selectedService.indexOf(item) == -1)
        ?.length > 0

    const hasLink =
      selectedService.filter(code => partnerListService.indexOf(code) == -1)
        ?.length > 0

    return hasUnlink || hasLink
  }

  return (
    <div>
      <h3 className="text-center">
        Para cadastrar o parceiro, preencha os dados abaixo.
      </h3>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-6">
          <label className="p-text-bold">Serviços disponíveis</label>
          <ListBox
            listStyle={{ maxHeight: '250px', maxWidth: 'auto' }}
            options={getSelectedList() || []}
            optionValue="code"
            optionLabel="name"
          />
        </div>
        <div className="p-col p-d-flex p-flex-column p-jc-center p-ai-center">
          {!isServiceListShow ? (
            <div className="p-col p-d-flex p-flex-column p-jc-center p-ai-center">
              <Button
                className="p-mb-4"
                onClick={() => setIsServiceListShow(true)}
              >
                Adicionar Serviço
              </Button>
              <Button
                onClick={() => {
                  setIsServiceListShow(true)
                  setIsRemoveService(true)
                }}
              >
                Desvincular Serviço
              </Button>
            </div>
          ) : (
            <div>
              <ManageSelectStores
                selectedItem={selectedService}
                itemListAux={services}
                lazyLoading={lazyLoading}
                idParceiro={props.state?.infoPartner.idParceiro}
                handleClickCancel={handleClickCancelLink}
                handleClickLink={handleClickLinkStore}
                placeholder={`Selecione o(s) Serviço(s) que deseja ${
                  isRemoveService ? 'desvincular' : 'adicionar'
                }:`}
                isRemoveStore={isRemoveService}
              />
            </div>
          )}
        </div>
      </div>
      <Stats
        step={props.currentStep}
        {...props}
        selectedService={selectServiceList}
        setHeaderDialog={props.setHeaderDialog}
        hasChanges={hasChanges()}
        selectedServiceUnlink={getUnselectedList() || []}
      />
    </div>
  )
}

export default Four
