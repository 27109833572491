import "./manageService.scss";
import { useEffect, useState } from "react";
import PageContainer from "../../../components/PartnerComponents/PartnerPageContainer/pageContainer";
import { usePartnerContext } from "../../../contexts/partnerContextProvider";
import Sidebar from "components/PartnerComponents/ManageService/SideBar/sidebar";
import { useRouter } from "hooks/useRouter";
import ServicesLinkedGrid from "components/PartnerComponents/ManageService/ServicesGrid/servicesLinkedGrid";
import { partnerService } from "services/partnerService";

interface Service {
  ativo: boolean;
  codProduto: string;
  id: number;
  nome: string;
  ordem: number;
}

function ManageService() {
  const [scheduleData, setScheduleData] = useState<any>(null);
  const { isLoading, setIsLoading } = usePartnerContext();
  const [partnerId, setPartnerId] = useState(0);
  const [services, setServices] = useState<Service[]>([]);
  const router = useRouter();

  useEffect(() => {
    const { pl } = router.query as any;
    setPartnerId(parseInt(pl.split("|")[0]));
  }, []);

  useEffect(() => {
    handleServicesLinked();
  }, [partnerId]);

  const handleServicesLinked = () => {
    if(partnerId > 0){
      setIsLoading(true);
      partnerService
        .getAllServiceLinkedByPartnerId(partnerId)
        .then((data) => setServices(data))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PageContainer
      sidebar={<Sidebar></Sidebar>}
      content={
        <div className="manageServiceContent">
          <div className="manageServiceContent__header">
            <h1>Gerenciamento de serviços</h1>
          </div>

          <ServicesLinkedGrid services={services} setServices={setServices} _partnerId={partnerId} />
        </div>
      }
    />
  );
}

export { ManageService };
