import styled from "styled-components";

import { colors } from "tokens";

export const Container = styled.div`
  display: flex;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 25px;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-left: 20px;
  padding-right: 50px;

  div h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: ${colors.dark};
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .formRow {
      display: flex;

      & + .formRow {
        margin-top: 16px;
      }

      .selectContainer {
        min-width: 290px;
        max-width: 290px;
        width: 100%;
        display: flex;
        flex-direction: column;

        & + .selectContainer {
          margin-left: 28px;
        }

        label {
          font-family: "Source Sans Pro", sans-serif;
          color: ${colors.dark};

          span {
            color: #ed1d24;
          }
        }

        input {
          width: 100%;
        }
      }

      button {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background: ${colors.blue};

        font-family: "Source Sans Pro", sans-serif;
        color: ${colors.white};

        :disabled {
          background: #f2f2f2;
          color: ${colors.gray.text};
        }
      }
    }
    
    .formActions{
      display: flex;
      align-items: center;
      justify-content: space-around;
      
      .clearBtn{
        background: white;
        color: #0054a5;
        border: 1px solid #0054a5;
      }
      
    }
  }

  .gridContainer {
    margin-top: 24px;

    p {
      margin: 0;
      margin-bottom: 8px;
      font-size: 14px;
      font-family: "Source Sans Pro", sans-serif;
      color: ${colors.dark};
    }
  }
`;
