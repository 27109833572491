import styled from 'styled-components';
import { colors } from '../../tokens';

export const Container = styled.footer`
  width: 100%;
  height: 24px;
  background: ${colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  z-index: 999;

  p {
    color: ${colors.white};
    font-family: 'Source Sans Pro';
    font-size: 14px;
    margin: 0;
  }
`;