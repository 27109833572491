import {
    createContext,
    useContext,
    useState,
} from "react";
import { toast } from "react-toastify";
import { partnerLoginService } from '../services/partnerLoginService'

interface PartnerAuthContext {
    access_token: string;
    partnerName: string;
    partnerEmail: string;
    isLoading: boolean;
    setAccess_token: (access_token: string) => void;
    setPartnerName: (name: string) => void;
    setPartnerEmail: (email: string) => void;
    handlePartnerSession: () => void;
    handlePartnerSignin: (user: string, password: string, updateHash: boolean) => Promise<any>;
    handlePartnerSignout: () => void;
}

const AuthContext = createContext({} as PartnerAuthContext);

function PartnerAuthProvider({ children }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [access_token, setAccess_token] = useState("")
    const [partnerName, setPartnerName] = useState("");
    const [partnerEmail, setPartnerEmail] = useState("")

    async function handlePartnerSession() {
        setIsLoading(true);
        console.log("handle session");

        try {
            const storedHash = localStorage.getItem("@portalAdmin:partnerHash");
            const storedPartnerName = localStorage.getItem("@portalAdmin:partnerName");
            const storedPartnerEmail = localStorage.getItem("@portalAdmin:partnerEmail");

            if (storedHash && storedPartnerName && storedPartnerEmail) {
                console.log("LOGIN PARCEIRO ATIVO")

                setAccess_token(storedHash)
                setPartnerName(storedPartnerName)
                setPartnerEmail(storedPartnerEmail)
                return
            } else {
                console.log("LOGIN PARCEIRO INATIVO")
                handlePartnerSignout()
            }
        } catch (err: any) {
            console.error(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    async function handlePartnerSignin(user: string, password: string, updateHash: boolean) {

        const userInfo = {
            userEmail: user,
            userPassword: password
        }

        const loginData = await partnerLoginService.performLogin(userInfo)

        switch (loginData.success) {
            case false: {
                const { errors } = loginData
                const parsedErrors = JSON.parse(errors)
                parsedErrors.forEach(err => {
                    toast.error(err.message)
                })
                setIsLoading(false)

                return null;
            }

            case true: {
                const { token: hash } = loginData
                const { nome, email } = loginData.user

                if (updateHash) {
                    localStorage.setItem("@portalAdmin:partnerHash", hash)
                    localStorage.setItem("@portalAdmin:partnerName", nome);
                    localStorage.setItem("@portalAdmin:partnerEmail", email);
                }

                toast.success("Login realizado com sucesso!")

                setIsLoading(false)

                return loginData
            }
        }

        setIsLoading(false);
    }

    function handlePartnerSignout() {
        setIsLoading(true);

        setAccess_token("")
        setPartnerName("")
        setPartnerEmail("")

        localStorage.removeItem("@portalAdmin:partnerHash");
        localStorage.removeItem("@portalAdmin:partnerName");
        localStorage.removeItem("@portalAdmin:partnerEmail");
    }

    return (
        <AuthContext.Provider
            value={{
                isLoading,
                access_token,
                partnerName,
                partnerEmail,
                setAccess_token,
                setPartnerName,
                setPartnerEmail,
                handlePartnerSession,
                handlePartnerSignin,
                handlePartnerSignout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

function usePartnerAuth() {
    const context = useContext(AuthContext);
    return context;
}

export { PartnerAuthProvider, usePartnerAuth };
