import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";
import PartnerRoute from "./PartnerRoute";

import { Login } from "pages/Login";
import { Home } from "pages/Home/index";
import { Agendamento } from "pages/Agendamento";
import { AgendamentosCliente } from "pages/AgendamentosCliente";
import { Servico } from "pages/Servico";
import { FormService } from "pages/FormService";
import { ConfigurarAgenda } from "pages/ConfigurarAgenda/ScheduleControl";
import { DetailsService } from "pages/DetailsService";
import { LojaServico } from "pages/LojaServico";
import { AgendaBloqueioLiberacao } from "pages/AgendaBloqueioLiberacao";
import { Parcerias } from "pages/Parcerias";
import { DetalhesParceria } from "pages/Parcerias/Detalhes";
import { CadastroParceria } from "pages/Parcerias/Cadastro";
import { EditarParceria } from "pages/Parcerias/Editar";
import { Parceiros } from "pages/Parceiros";
import { GerenciarParceiro } from "pages/Parceiros/Gerenciar";
import { DetalhesParceiro } from "pages/Parceiros/Detalhes";
import { Clientes } from "pages/Clientes";

import { PartnerHome } from "../pages/Partner/Home/partnerHome";
import { ScheduleControl } from "../pages/Partner/ScheduleControl/ScheduleControl";
import { ManageService } from "pages/Partner/ManageService/manageService";
import { LinkServices } from "pages/Partner/ManageService/LinkServices/linkServices";
import { ScheduleLockRelease } from "../pages/Partner/ScheduleLockRelease/scheduleLockRelease";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/portaladmin/login" exact component={Login} />
    <Route path="/portaladmin/home" exact isPrivate component={Home} />
    <Route
      path="/portaladmin/agenda"
      exact
      isPrivate
      component={ConfigurarAgenda}
    />
    <Route
      path="/portaladmin/agendamento"
      exact
      isPrivate
      component={Agendamento}
    />
    <Route
      path="/portaladmin/agendamentos-cliente"
      exact
      isPrivate
      component={AgendamentosCliente}
    />
    <Route path="/portaladmin/servico" exact isPrivate component={Servico} />
    <Route
      path="/portaladmin/register-service"
      exact
      isPrivate
      component={FormService}
    />
    <Route
      path="/portaladmin/detail-service"
      exact
      isPrivate
      component={DetailsService}
    />
    <Route
      path="/portaladmin/loja-servico"
      exact
      isPrivate
      component={LojaServico}
    />
    <Route
      path="/portaladmin/agenda-bloqueio-liberacao"
      exact
      isPrivate
      component={AgendaBloqueioLiberacao}
    />
    <Route
      path="/portaladmin/parcerias"
      exact
      isPrivate
      component={Parcerias}
    />
    <Route
      path="/portaladmin/parcerias/detalhes"
      exact
      isPrivate
      component={DetalhesParceria}
    />
    <Route
      path="/portaladmin/parcerias/cadastro"
      exact
      isPrivate
      component={CadastroParceria}
    />
    <Route
      path="/portaladmin/parcerias/editar"
      exact
      isPrivate
      component={EditarParceria}
    />
    <Route
      path="/portaladmin/parceiros"
      exact
      isPrivate
      component={Parceiros}
    />
    <Route
      path="/portaladmin/parceiros/cadastro"
      exact
      isPrivate
      component={() => <GerenciarParceiro isCreation />}
    />
    <Route
      path="/portaladmin/parceiros/editar"
      exact
      isPrivate
      component={() => <GerenciarParceiro />}
    />
    <Route
      path="/portaladmin/parceiros/detalhes"
      exact
      isPrivate
      component={DetalhesParceiro}
    />
    <Route path="/portaladmin/clientes" exact isPrivate component={Clientes} />

    {/* Rotas login parceiro */}
    <PartnerRoute
      path="/portaladmin/partner/home"
      exact
      isPrivate
      component={PartnerHome}
    />

    <PartnerRoute
      path="/portaladmin/partner/scheduleControl"
      exact
      isPrivate
      component={ScheduleControl}
    />

    <PartnerRoute
      path="/portaladmin/partner/manage-services"
      exact
      isPrivate
      component={ManageService}
    />

    <PartnerRoute
      path="/portaladmin/partner/linkServices"
      exact
      isPrivate
      component={LinkServices}
    />

    <PartnerRoute
      path="/portaladmin/partner/scheduleLockRelease"
      exact
      isPrivate
      component={ScheduleLockRelease}
    />
  </Switch>
);

export default Routes;
