import { Form } from "@unform/web";
import { ModalBase } from "../ModalBase";
import { Input } from "components/Input";
import Button from "components/Button";
import { Grid } from "components/Grid";
import IconClose from "images/icons/icon-close-modal.png";
import IconSearch from "images/icons/icon-search.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import { debounce } from "utils/debounce";
import { useLoader } from "hooks/loader";

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  cpf: string;
}

interface HistoricoProps {
  codigo: string;
  servico: string;
  data: string;
}

export function ModalHistoricoConsulta({
  isOpen,
  onRequestClose,
  cpf,
}: ModalProps) {
  const { handleSetIsLoader } = useLoader();
  const [data, setData] = useState<HistoricoProps[]>([]);
  const [servicoSearch, setServicoSearch] = useState("");
  const [dataFilter, setDataFilter] = useState<HistoricoProps[]>([]);

  useEffect(() => {
    if (cpf !== undefined) loadHistorico(cpf);
  }, [cpf]);

  useEffect(() => {
    debounce(() => {
      loadServico(servicoSearch);
    }, 500);
  }, [servicoSearch]);

  function loadServico(nome: string) {
    handleSetIsLoader(true);
    if (!nome || !nome.length) {
      if (data && data.length) setDataFilter(data);
      handleSetIsLoader(false);
      return;
    }

    const filterServico = data.filter((item: HistoricoProps) => {
      return item.servico.toLowerCase().includes(nome.toLowerCase());
    });

    setDataFilter(filterServico);
    handleSetIsLoader(false);
  }

  async function loadHistorico(cpf: string) {
    handleSetIsLoader(true);
    const response = await axios.get(
      `/BeneficioData/GetHistoricoConsumo/${cpf}`
    );

    if (response.data.result === "Error") {
      handleSetIsLoader(false);
      return;
    }

    const data = response.data.items.map((item: any) => ({
      id: item.id,
      codigo: item.servico.codProduto,
      servico: item.servico.nome,
      data: item.dataConsumo,
    }));

    setData(data);
    setDataFilter(data);
    handleSetIsLoader(false);
  }

  return (
    <ModalBase
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type="large-width"
    >
      <Form onSubmit={() => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <img
          src={IconClose}
          alt="close"
          onClick={() => onRequestClose()}
          style={{
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
          }}
        />
        <h2
          style={{
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            color: "#111417",
          }}
        >
          Histórico de consumo
        </h2>
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <Input
            name="busca"
            placeholder="Busca"
            width={"565px"}
            style={{
              color: "#7B8FA5",
            }}
            onChange={(event) => setServicoSearch(event.target.value)}
          />
          <img
            src={IconSearch}
            alt="search"
            style={{
              position: "absolute",
              right: "40px",
              top: "81px",
            }}
          />
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <Grid
            key={"id"}
            omit={["id"]}
            columns={["Código", "Serviço consumido", "Data"]}
            columnsWidth={[
              {
                column: "Código",
                width: "82px",
              },
              {
                column: "Serviço consumido",
                width: "376px",
              },
              {
                column: "Data",
                width: "106px",
              },
            ]}
            data={dataFilter}
            customStyle={{
              fontWeight: "normal",
              color: "#2A333E",
              maxWidth: "684px",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          />
        </div>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "15px",
        }}>
          <Button
            inverted
            width={"123px"}
            style={{
              fontFamily: "Source Sans Pro",
            }}
            onClick={() => onRequestClose()}
          >
            Fechar
          </Button>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "32px",
            display: "flex",
            marginLeft: "221px",
          }}
        >
        </div>
      </Form>
    </ModalBase>
  );
}
