import { useEffect, useRef, useState } from "react";

import DashboardUser from "../../../components/DashboardUser";
import { Footer } from "../../../components/Footer";
import Header from "../../../components/Header";
import Main from "../../../components/Main";
import StepPartner from "../../../components/StepsPartner";
import { Card } from 'primereact/card';

import * as S from "./styles";
import { useRouter } from "hooks/useRouter";

interface GerenciarParceiroProps {
  isCreation?: boolean
}

export const GerenciarParceiro = ({ isCreation = false }: GerenciarParceiroProps) => {
  const router = useRouter();
  const [partnerId, setPartnerId] = useState(-1);
  
  useEffect(() => {
    const { p } = router.query as any;
    setPartnerId(parseInt(p));
  }, []);

  return (
    <>
      <Header />
      <Main>
        <S.DashboardContainer>
          <DashboardUser />
        </S.DashboardContainer>
        <S.Content>
          <h2>{`${isCreation ? 'CRIAR' : 'EDITAR'} PARCEIRO`}</h2>
          <Card className="contentBody">
            <StepPartner editPartnerId={partnerId} />
          </Card>
        </S.Content>
      </Main>
      <Footer />
    </>
  );
}
