import './blockUnblockPeriodsModal.scss'
import {Popup, Position, ToolbarItem} from "devextreme-react/popup";
import PartnerTimePickerModal from "../PartnerTimePickerModal/partnerTimePickerModal";
import {useState} from "react";
import {format} from "date-fns-tz";
import DateRangeModal from "../PartnerDateRangePickerModal/dateRangeModal";
import {usePartnerContext} from "../../../contexts/partnerContextProvider";
import {partnerScheduleLockReleaseService} from "../../../services/partnerScheduleLockReleaseService";
import Swal from "sweetalert2";
import {partnerLoginService} from "../../../services/partnerLoginService";
import {usePartnerAuth} from "../../../hooks/partnerAuth";

function BlockUnblockPeriodsModal({open, setOpen, action, handleData}) {
    const {isLoading, setIsLoading, selectedStores, wipeOutPartnerData} = usePartnerContext()
    const {partnerEmail, handlePartnerSignout} = usePartnerAuth()
    const [startHourOpen, setStartHourOpen] = useState(false);
    const [selectedStartHour, setSelectedStartHour] = useState("");
    const [endHourOpen, setEndHourOpen] = useState(false);
    const [selectedEndHour, setSelectedEndHour] = useState("");
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);

    const handleBlockUnblockSchedule = async () => {
        setIsLoading(true)

        const partnerId = await partnerLoginService.acquirePartnerId(partnerEmail)
        if (partnerId == null || partnerId < 0) {
            wipeOutPartnerData()
            handlePartnerSignout()
        }

        const filters = {
            partnerId,
            "selectedStores": `${selectedStores.join(',')}`,
            "startDate": selectedDates[0].startDate,
            "endDate": selectedDates[0].endDate,
            "startTime": `${selectedStartHour}`,
            "endTime": `${selectedEndHour}`
        }

        const hasSchedules = await partnerScheduleLockReleaseService.checkExitingSchedules(filters)

        const {existeAgendamento} = hasSchedules

        setIsLoading(false)

        if (!existeAgendamento) {
            setOpen(false)

            Swal.fire({
                title: "Atenção!",
                html: `Deseja realmente ${action === "BLOCK" ? 'bloquear' : 'desbloquear'} o(s) período(s) selecionado(s)?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0054A6",
                cancelButtonColor: "#ed1d24",
                confirmButtonText: "Prosseguir",
                cancelButtonText: "Abandonar",
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setIsLoading(true);

                    const reasons =
                        await partnerScheduleLockReleaseService.acquireReasons(`${action}`);

                    setIsLoading(false);

                    let items = reasons.items.map((reason) => {
                        return reason.descricao;
                    });

                    items = ["OUTRO", ...items];

                    const handleText = async () => {
                        const {value: text} = await Swal.fire({
                            input: "textarea",
                            inputLabel: `Motivo ${action === "BLOCK" ? 'do bloqueio' : 'da liberação'}`,
                            confirmButtonColor: "#0054A6",
                            confirmButtonText: "Confirmar",
                            inputPlaceholder: "Descreva brevemente o motivo...",
                            inputAttributes: {
                                "aria-label": "Descreva brevemente o motivo...",
                            },
                            showCancelButton: false,
                            allowOutsideClick: false,
                            inputValidator: (value) => {
                                return new Promise((resolve) => {
                                    if (value && value.trim().length) {
                                        resolve(null);
                                    } else {
                                        resolve("Por favor, descreva motivo acima");
                                    }
                                });
                            },
                        });

                        return text;
                    };

                    if (reasons.result === "Success") {
                        let text = "";

                        const {value: reason} = await Swal.fire({
                            title: `Motivo ${action === "BLOCK" ? 'do bloqueio' : 'da liberação'}`,
                            input: "select",
                            inputOptions: Object.assign({}, items),
                            inputPlaceholder: "Selecione o motivo",
                            allowOutsideClick: false,
                            showCancelButton: true,
                            confirmButtonColor: "#0054A6",
                            cancelButtonColor: "#ed1d24",
                            confirmButtonText: "Prosseguir",
                            cancelButtonText: "Abandonar",
                            reverseButtons: true,
                            inputValidator: (value) => {
                                return new Promise((resolve) => {
                                    if (value && value.length) {
                                        resolve(null);
                                    } else {
                                        resolve("Por favor, selecione o motivo acima");
                                    }
                                });
                            },
                        });

                        if (reason) {
                            if (reason === "0") {
                                text = await handleText();
                            }

                            setIsLoading(true);

                            const filters = {
                                "idParceiro": partnerId,
                                "idAgenda": 0,
                                "lojas": `${selectedStores.join(',')}`,
                                "dataInicial": `${format(selectedDates[0].startDate, 'dd/MM/yyyy')} ${selectedStartHour}`,
                                "dataFinal": `${format(selectedDates[0].endDate, 'dd/MM/yyyy')} ${selectedEndHour}`,
                                "horaInicial": `${selectedStartHour}`,
                                "horaFinal": `${selectedEndHour}`,
                                "idMotivo": parseInt(`${reason}`),
                                "motivo": `${text}`
                            }

                            const performBlockUnblock = await partnerScheduleLockReleaseService.performBulkBlockUnblock(filters, action)

                            setIsLoading(false)

                            if (performBlockUnblock.result === "Success") {
                                Swal.fire({
                                    title: `${action === "BLOCK" ? 'Bloqueio' : 'Liberação'}`,
                                    text: "Os períodos da agenda foram atualizados com sucesso.",
                                    icon: "success",
                                    confirmButtonColor: "#0054A6",
                                    confirmButtonText: "Entendi",
                                }).then(() => {
                                    handleData();
                                });
                            } else {
                                Swal.fire({
                                    title: `${action === "BLOCK" ? 'Bloqueio' : 'Liberação'}`,
                                    text: performBlockUnblock.message,
                                    icon: "error",
                                    confirmButtonColor: "#0054A6",
                                    confirmButtonText: "Entendi",
                                })
                            }
                        }
                    }
                }
            });
        } else {
            Swal.fire({
                title: `${action === "BLOCK" ? 'Bloqueio' : 'Liberação'}`,
                text: "Existem agendamentos para a(s) data(s) selecionada(s)!",
                icon: "error",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Entendi",
            })
        }
    };

    const saveButtonOptions = {
        icon: 'save',
        text: action === 'BLOCK' ? 'Bloquear' : 'Desbloquear',
        disabled: selectedStartHour === "" || selectedEndHour === "",
        onClick: () => handleBlockUnblockSchedule(),
    };

    const closeButtonOptions = {
        text: 'Cancelar',
        onClick: () => {
            setSelectedStartHour("")
            setSelectedEndHour("")
            setOpen(false)
        },
    };

    return (
        <>
            <Popup
                style={{zIndex: '1060 !important'}}
                visible={open}
                dragEnabled={true}
                closeOnOutsideClick={false}
                showTitle={true}
                showCloseButton={false}
                title={`${action === "BLOCK" ? 'Bloquear' : 'Desbloquear'} Agenda`}
                width={420}
                height={280}
            >
                <Position
                    at="center"
                    my="center"/>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={closeButtonOptions}/>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={saveButtonOptions}/>

                <p>Selecione a(s) data(s) e período(s)</p>

                <div className="dateSelection">
                    <button disabled={isLoading} className="datePickerTrigger"
                            onClick={() => setDateRangeModalOpen(true)}>
                        {format(selectedDates[0].startDate, "dd/MM/yyyy")} ➟ {format(selectedDates[0].endDate, "dd/MM/yyyy")}
                    </button>
                </div>
                <br/>
                <div className="timeSelection">
                    <button className="timeSelection__startTime" onClick={() => setStartHourOpen(true)}>
                        {selectedStartHour === "" ? "Selecione" : selectedStartHour}
                    </button>
                    &nbsp;
                    &nbsp;
                    <span>às</span>
                    &nbsp;
                    &nbsp;
                    <button className="timeSelection__endTime" onClick={() => setEndHourOpen(true)}>
                        {selectedEndHour === "" ? "Selecione" : selectedEndHour}
                    </button>
                </div>
            </Popup>

            <PartnerTimePickerModal
                open={startHourOpen}
                setOpen={setStartHourOpen}
                selectedHour={selectedStartHour.split(":")}
                setSelectedHour={setSelectedStartHour}
                modalName="Hora Inicial"
            />

            <PartnerTimePickerModal
                open={endHourOpen}
                setOpen={setEndHourOpen}
                selectedHour={selectedEndHour.split(":")}
                setSelectedHour={setSelectedEndHour}
                modalName="Hora Final"
            />

            <DateRangeModal
                open={dateRangeModalOpen}
                setOpen={setDateRangeModalOpen}
                context={[selectedDates, setSelectedDates]}
            />
        </>
    );
}

export default BlockUnblockPeriodsModal;