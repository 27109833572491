export function addMinutes(initialHour: string, minutesToAdd: number) {
  const [hour, minute] = initialHour.split(':');

  const updatedMinutes = Number(minute) + minutesToAdd;

  if (updatedMinutes === 60) {
    return `${Number(hour) + 1}:00`
  }

  return `${hour}:${updatedMinutes}`;
}