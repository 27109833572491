import { useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import axios from "axios";
import { isAfter } from "date-fns";

import { ModalBase } from "../ModalBase/index";
import SelectDropdown from "components/SelectDropdown";
import { InputCalendar } from "components/InputCalendar";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import errorImg from "images/iconError.png";

import { Container, ButtonCancel, ButtonContinue } from "./styles";
import { CheckboxParceriasAtivas } from "components/CheckboxParceriasAtivas";

import IconClose from "images/icons/icon-close-modal.png";

interface FilterProps {
  classificacao: string;
  inicio: string;
  termino: string;
  somenteAtivas: boolean;
}

interface TipoParceriaData {
  id: number;
  value: number;
  nome: string;
  label: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (data: FilterProps) => void;
  defaultData: {
    filterDateInicio: string;
    filterDateTermino: string;
    filterClassificacao: string;
    filterSomenteAtivas: boolean;
  };
}

export function ParceiroFiltroModal({
  isOpen,
  onRequestClose,
  onContinue,
  defaultData,
}: ModalProps) {
  const [classifications, setClassifications] = useState<TipoParceriaData[]>(
    []
  );
  const [inicio, setInicio] = useState<Date | undefined>(
    defaultData.filterDateInicio !== ""
      ? new Date(defaultData.filterDateInicio)
      : undefined
  );
  const [termino, setTermino] = useState<Date | undefined>(
    defaultData.filterDateTermino !== ""
      ? new Date(defaultData.filterDateTermino)
      : undefined
  );
  const [somenteAtivasLocal, setSomenteAtivasLocal] = useState<boolean>(
    defaultData.filterSomenteAtivas
      ? defaultData.filterSomenteAtivas
      : false
  );
  const [isDisable, setIsDisable] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [filterData, setFilterData] = useState({} as FilterProps);
  const [selectedClassification, setSelectedClassification] = useState<number>(
    0
  );

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    (async () => {
      const response = await axios.get("/beneficioData/GetAllTipo");
      setClassifications(
        response.data.items.map((item: TipoParceriaData) => ({
          value: item.id,
          label: item.nome,
        }))
      );
      defaultData.filterClassificacao &&
        classifications.map((item) => {
          if (item.nome === defaultData.filterClassificacao) {
            setSelectedClassification(item.value);
          }
        });
      defaultData.filterSomenteAtivas &&
        setSomenteAtivasLocal(true);
    })();
  }, []);

  useEffect(() => {
    if (inicio !== undefined && termino !== undefined && isAfter(inicio as Date, termino as Date) && somenteAtivasLocal) {
      setIsDisable(false);
      return;
    }

    if (
      selectedClassification > 0 ||
      inicio !== undefined ||
      termino !== undefined ||
      somenteAtivasLocal
    ) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }

  }, [inicio, termino, selectedClassification, somenteAtivasLocal]);

  function handleSubmit({ classificacao, inicio, termino, somenteAtivas }: FilterProps) {
    setFilterData({
      classificacao,
      inicio,
      termino,
      somenteAtivas,
    });
    onContinue({
      classificacao,
      inicio,
      termino,
      somenteAtivas: somenteAtivasLocal,
    });
    onRequestClose();
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <img
        src={IconClose}
        alt="close"
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          cursor: "pointer",
        }}
        onClick={() => onRequestClose()}
      />
      <Container>
        <Form onSubmit={handleSubmit} ref={formRef} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <h2>Filtro</h2>
          <div className="classificacao">
            <span>Classificação</span>
            <SelectDropdown
              name="classificacao"
              placeholder="Selecione o período"
              styleSingleValue="#7B8FA5"
              newValue={{
                value: selectedClassification,
                label:
                  classifications.find(
                    (classification) =>
                      classification.value === selectedClassification
                  )?.label || "Selecione o período",
              }}
              options={classifications}
              onChange={(event: any) => setSelectedClassification(event.value)}
              style={{
                width: "461px",
              }}
            />
          </div>

          <div className="dates">
            <div className="dateStart">
              <InputCalendar
                name="inicio"
                isRange={false}
                title="Inicio"
                titleStyle="#2A333E"
                placeholder="00/00/0000"
                defaultDateSelected={inicio}
                inputStyle={{
                  width: "223px",
                  color: "#7B8FA5",
                }}
                handleDateChange={(event) => {
                  const date = event as Date;
                  if (termino && isAfter(date, termino)) {
                    setIsModalErrorOpen(true);
                  }
                  setInicio(date as Date);
                }}
              />
            </div>

            <div className="dateEnd">
              <InputCalendar
                name="termino"
                isRange={false}
                title="Término"
                titleStyle="#2A333E"
                placeholder="00/00/0000"
                defaultDateSelected={termino}
                inputStyle={{
                  width: "223px",
                  color: "#7B8FA5",
                }}
                handleDateChange={(event) => {
                  const date = event as Date;
                  if (inicio && isAfter(inicio, date)) {
                    setIsModalErrorOpen(true);
                  }
                  setTermino(date as Date);
                }}
              />
            </div>
          </div>
          <div className="somenteAtivas">
            <br></br>
            <span>Somente parcerias ativas</span>
            <CheckboxParceriasAtivas
              name={"somenteAtivas"}
              checked={somenteAtivasLocal || false}
              onClickChecked={() => setSomenteAtivasLocal(!somenteAtivasLocal)}
            />
          </div>
          <div className="buttons">
            <ButtonCancel onClick={onRequestClose}>Cancelar</ButtonCancel>
            <ButtonContinue disabled={!isDisable}>Aplicar</ButtonContinue>
          </div>
        </Form>
      </Container>
      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => setIsModalErrorOpen(false)}
        icon={errorImg}
        title="Erro"
        message="A data inicial deve ser menor que a data final."
        continueButtonText="Fechar"
      />
    </ModalBase>
  );
}
