import { Form } from "@unform/web";
import Button from "components/Button";
import { Grid } from "components/Grid";
import { NoResults } from "components/NoResults";
import React, { useState } from "react";
import { cpfMask, validateCpf } from "components/ValidationCpf";
import * as S from "./styles";
import { ModalHistoricoConsulta } from "components/ModalGroup/ModalHistoricoConsulta";
import { ModalHistoricoTransferencia } from "components/ModalGroup/ModalHistoricoTransferencia";
import { ModalTransferenciaBeneficio } from "components/ModalGroup/ModalTransferenciaBeneficio";
import { useLoader } from "hooks/loader";

import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import { Column } from "primereact/column";

import axios from "axios";
import IconAtencao from "images/icons/icon-atencao.svg";
import { getAuthToken } from "services/authService";

import { RenovacaoBeneficio } from "components/Quantity";

interface ParceiroProps {
  parceria: string;
  tipo: string;
}

interface BeneficioProps {
  id: number;
  codigo: string;
  servico: string;
  renovaEm: string;
  quantidade: string;
  permiteTransferencia: boolean;
  renovacaoEnum: number;
}

interface CadastroClienteTransfBeneficioFormData {
  nome: string;
  genero: string;
  dataNasc: string;
  celular: string;
  email: string;
}

const MessageError = (message: string, width: string) => {
  return (
    <div
      style={{
        width: width,
        height: "32px",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        background: "#FFE4E4",
        marginTop: "8px",
        marginLeft: ".2rem",
      }}
    >
      <img
        src={IconAtencao}
        alt="atencao"
        style={{
          width: "16px",
          height: "16px",
          marginRight: "4px",
        }}
      />
      <span
        style={{
          color: "#D14A4A",
          fontSize: "14px",
        }}
      >
        {message}
      </span>
    </div>
  );
};

export function ClientesAbaConsultar() {
  const { handleSetIsLoader } = useLoader();
  const [openModalHistorico, setOpenModalHistorico] = useState(false);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);
  const [openModalTransferenciaBeneficio, setOpenModalTransferenciaBeneficio] =
    useState(false);
  const [isCpfState, setIsCpfState] = useState("");
  const [cpfIsValid, setCpfIsValid] = useState(false);
  const [cpfNotFound, setCpfNotFound] = useState(false);
  const [beneficios, setBeneficios] = useState<BeneficioProps[]>([]);
  const [selectedbeneficios, setSelectedBeneficios] =
    useState<BeneficioProps>();
  const [parcerias, setParcerias] = useState<ParceiroProps[]>([]);
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [itemsActions, setItemsActions] = useState<any[]>([
    {
      label: "Transferir Serviço",
      icon: "pi pi-arrow-right-arrow-left",
      command: (e: any) => {
        setSelectedBeneficios(undefined);
        setSelectedBeneficios(e.item.data);
        setOpenModalTransferenciaBeneficio(true);
      },
      disabled: false,
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const [cadastroClienteTransfBeneficioFormData] = useState(
    {} as CadastroClienteTransfBeneficioFormData
  );

  const ActionButtonModalTransferir = (data: BeneficioProps) => {
    return (
      <SplitButton
        key={data.id}
        model={itemsActions}
        label="Ações"
        className="button-action p-button-outlined w-100"
        onShow={() => {
          setItemsActions(
            itemsActions?.map((item) => {
              if (item.label === "Transferir Serviço" && data) {
                item.disabled = !data.permiteTransferencia;
                item.data = data;
              }

              return item;
            })
          );
        }}
        onClick={(e: any) => e.target.parentNode.parentNode.lastChild.click()}
      />
    );
  };

  function handleMaskCpf(e: React.ChangeEvent<HTMLInputElement>) {
    if (cpfIsValid || cpfNotFound) {
      setCpfIsValid(false);
      setCpfNotFound(false);
    }
    setIsCpfState(cpfMask(e.target.value));
  }

  function formatParcerias(value: []) {
    let parceriasAux: ParceiroProps[] = [];
    value?.map((item: any) => {
      let parceiro = {} as ParceiroProps;
      parceiro.parceria = item.nome;
      parceiro.tipo = item.tipoParceria.nome;
      parceriasAux.push(parceiro);
    });
    setParcerias(parceriasAux);
  }

  function onFocusOutCPF(event: React.FocusEvent<HTMLInputElement>) {
    if (event.target.value === "") clearData();
  }

  async function getBeneficios() {
    const cpfClient = isCpfState;

    handleSetIsLoader(true);
    setIsLoading(true);
    clearData();

    var token = await getAuthToken();

    const response = await axios.get(`/beneficioData/cpf/${cpfClient}`, {
      headers: {
        'Authorization': token
      }
    });

    setIsLoading(false);
    handleSetIsLoader(false);
    if (response.data.result === "Error") {
      setCpfNotFound(true);
      return;
    }

    const { nome, cpf } = response.data.beneficioCliente;

    setCpf(cpf);
    setNome(nome);

    let beneficios: BeneficioProps[] = [];

    response.data.beneficioCliente.beneficios?.map((item: any) => {
      let beneficio = {} as BeneficioProps;
      beneficio.id = item.idServico;
      beneficio.codigo = item.codigo;
      beneficio.servico = item.servico;
      beneficio.renovaEm = item.renovaEm;
      beneficio.quantidade =
       item.renovacaoEnum === RenovacaoBeneficio.Ilimitada ? '-' : item.quantidadeDisponivel + " de " + item.quantidadeTotal;
      beneficio.permiteTransferencia = item.permiteTransferencia;
      beneficios.push(beneficio);
    });

    setBeneficios(beneficios);
    formatParcerias(response.data.beneficioCliente.parcerias);
  }

  function handleBuscar(value: string) {
    if (!validateCpf(value)) {
      setCpfIsValid(true);
      return;
    }

    clearData();
    clearMessages();
    getBeneficios();
  }

  const clearData = () => {
    setCpf("");
    setNome("");
    setBeneficios([]);
    formatParcerias([]);
    setSelectedBeneficios(undefined);
  };

  const clearMessages = () => {
    setCpfIsValid(false);
    setCpfNotFound(false);
  };

  const clearAllData = () => {
    clearData();
    clearMessages();
    setIsCpfState("");
  };

  const columns = [
    {
      field: "id",
      header: "Código",
      style: { width: "5.3rem", textAlign: "center" },
    },
    {
      field: "servico",
      header: "Serviços farmacêuticos",
    },
    {
      field: "renovaEm",
      header: "Renova em",
      style: { width: "8rem", textAlign: "center" },
    },
    {
      field: "quantidade",
      header: "Qtd.",
      style: { width: "6rem", textAlign: "center" },
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => ActionButtonModalTransferir(rowData),
      style: { width: "7rem", textAlign: "center" },
    },
  ];

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        style={col.style}
        body={col.body}
      />
    );
  });

  return (
    <>
      <S.Container>
        <Form onSubmit={() => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <div className="formRow">
            <p>
              Para verificar as informações de um CPF, basta digitar o número
              correspondente abaixo e clicar em consultar.
            </p>
          </div>
          <div className="formRow">
            <div className="inputCpf">
              <div>
                <h5>Consultar CPF</h5>
                <div className="p-fluid p-formgrid p-grid">
                  <div className="p-field p-col-12 p-md-4 p-pl-2 p-pt-0 p-pb-0">
                    <InputText
                      style={{ marginLeft: ".2rem" }}
                      id="withoutgrouping"
                      placeholder="Ex.: 000.000.000-00"
                      value={isCpfState}
                      maxLength={14}
                      onBlur={onFocusOutCPF}
                      onChange={(event) => handleMaskCpf(event)}
                    />
                    {cpfNotFound && MessageError("CPF não encontrado", "256px")}
                    {cpfIsValid && MessageError("CPF inválido", "256px")}
                  </div>
                  <div className="p-field p-col-12 p-md-8 p-pl-0 p-pt-0 p-pb-0">
                    <div className="p-formgroup-inline">
                      <div className="p-field">
                        <Button
                          style={{
                            maxWidth: "unset",
                            height: "42px",
                            padding: "0",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          disabled={cpfIsValid}
                          onClick={() => handleBuscar(isCpfState)}
                          inverted={isLoading}
                        >
                          {isLoading && <>Carregando...</>}
                          {!isLoading && <>Consultar</>}
                        </Button>
                      </div>
                      <div className="p-field">
                        <Button
                          style={{
                            maxWidth: "unset",
                            height: "42px",
                            padding: "0",
                            fontSize: "14px",
                            fontWeight: "normal",
                          }}
                          disabled={isLoading}
                          onClick={() => clearAllData()}
                          inverted
                        >
                          Limpar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formRow">
            <div className="cpfNome">
              <div
                style={{
                  width: "125px",
                }}
              >
                <label>CPF</label>
                <span>{cpf ? cpf : "-"}</span>
              </div>
              <div>
                <label>Nome</label>
                <span>{nome ? nome : "-"}</span>
              </div>
            </div>
          </div>
          <div className="formRow">
            <div className="labels-grid-beneficios">
              <label
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#2A333E",
                }}
              >
                Benefícios
              </label>
              <div
                style={{
                  width: "80%",
                  textAlign: "right",
                }}
              >
                <span
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    textDecorationLine: "underline",
                    color: "#0054A6",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenModalTransferencia(true)}
                >
                  Histórico de transferências
                </span>
                <span
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    textDecorationLine: "underline",
                    color: "#0054A6",
                    cursor: "pointer",
                    marginLeft: "1%",
                  }}
                  onClick={() => setOpenModalHistorico(true)}
                >
                  Histórico de consumo
                </span>
              </div>
            </div>
            <DataTable
              dataKey="id"
              value={beneficios}
              className="p-datatable-responsive-demo p-datatable-sm benefits-table"
              emptyMessage="Nenhuma informação para ser exibida"
              resizableColumns
              showGridlines
              scrollable
              scrollHeight="400px"
              selection={selectedbeneficios}
              onSelectionChange={(e) => setSelectedBeneficios(e.value)}
            >
              {dynamicColumns}
            </DataTable>
          </div>
          <div className="formRow">
            <div className="parceriaTable">
              <DataTable
                value={parcerias}
                className="p-datatable-responsive-demo p-datatable-sm partnerships-table"
                emptyMessage="Nenhuma informação para ser exibida"
                showGridlines
                scrollable
                scrollHeight="400px"
                selection={selectedbeneficios}
                onSelectionChange={(e) => setSelectedBeneficios(e.value)}
              >
                <Column field="parceria" header="Parceria" />
                <Column
                  field="tipo"
                  header="Tipo"
                  style={{ width: "9rem", textAlign: "center" }}
                />
              </DataTable>
            </div>
          </div>
        </Form>
      </S.Container>

      {openModalHistorico && (
        <ModalHistoricoConsulta
          isOpen={openModalHistorico}
          onRequestClose={() => setOpenModalHistorico(false)}
          cpf={isCpfState}
        />
      )}
      {openModalTransferencia && (
        <ModalHistoricoTransferencia
          isOpen={openModalTransferencia}
          onRequestClose={() => setOpenModalTransferencia(false)}
          cpf={isCpfState}
        />
      )}
      {openModalTransferenciaBeneficio && (
        <ModalTransferenciaBeneficio
          isOpen={openModalTransferenciaBeneficio}
          onSuccessTranfer={() => handleBuscar(isCpfState)}
          onRequestClose={() => {
            setSelectedBeneficios(undefined);
            setOpenModalTransferenciaBeneficio(false);
          }}
          infoLine={selectedbeneficios}
          cpfRef={cpf}
          formDataClientTransfBenef={cadastroClienteTransfBeneficioFormData}
        />
      )}
    </>
  );
}
