import './optInModal.scss'
import logo from '../../../images/logoBrc.png'
import { useState } from 'react';
import { usePartnerContext } from 'contexts/partnerContextProvider';
import { useRouter } from 'hooks/useRouter';
import { partnerLoginService } from 'services/partnerLoginService';
import { toast } from 'react-toastify';
import { usePartnerAuth } from 'hooks/partnerAuth';

export default function OptInModal({ open }) {
    const { isLoading, setIsLoading, wipeOutPartnerData } = usePartnerContext()
    const { partnerEmail, handlePartnerSignout } = usePartnerAuth()
    const [needScrolling, setNeedScrolling] = useState(true)
    const [agreed, setAgreed] = useState(false)
    const router = useRouter()

    const handleScroll = (e) => {
        const { target } = e
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setNeedScrolling(false)
        }
    }

    const handleAgreement = async () => {
        setIsLoading(true)
        try {
            const partnerId = await partnerLoginService.acquirePartnerId(partnerEmail)
            if (partnerId == null || partnerId < 0) {
                wipeOutPartnerData()
                handlePartnerSignout()
            }
    
            const updateAgreement = await partnerLoginService.registerOptInAgreement(partnerId)
    
            if (updateAgreement === "") {
                setAgreed(true)
                setTimeout(() => { router.replace('/portaladmin/partner/home') }, 500)
            }           
        } catch (error) {
            //to do : renderização da página no erro do aceite
            toast.error("Ocorreu um erro ao registrar o aceite dos termos, tente novamente mais tarde.")
            setIsLoading(false)
        }
    }

    return (
        <>
            {open && (
                <div className="optInModalOverlay">
                    <div className="optInModal">
                        <div className="optInModal__header">
                            <span>Termos de Uso</span>
                            <img src={logo} alt="PagueMenos logo" />
                        </div>
                        <div id="agreementText" className="optInModal__content" onScroll={handleScroll}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit elit, placerat vitae tincidunt vitae, hendrerit non nunc. Suspendisse sapien arcu, fermentum iaculis eleifend eget, tempor et diam. Vivamus lorem tortor, mollis eu odio at, iaculis ornare enim. Praesent egestas, magna sit amet pretium vestibulum, tellus dolor scelerisque dolor, quis semper sem dui in dui. In dolor mauris, mollis vel quam et, mattis consectetur lectus. Praesent in leo et eros vehicula euismod. In sit amet imperdiet magna. Cras ac nibh finibus diam sodales faucibus. Quisque pharetra justo in sodales rutrum. Mauris in turpis vehicula, accumsan velit quis, consequat metus. Ut consectetur turpis eget auctor consectetur.</p>

                            <p>Etiam aliquam finibus posuere. Curabitur tempor sem eget facilisis rhoncus. Praesent dictum, nulla sed egestas venenatis, libero mauris porta enim, eget rutrum quam nunc id nibh. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer at placerat magna. Donec sit amet elit consequat mauris imperdiet tempor. Nullam auctor, nulla vitae cursus pretium, dui sapien fringilla sem, sit amet interdum urna ligula at augue. Sed finibus felis vitae ultricies scelerisque. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent in dapibus felis.</p>

                            <p>Maecenas euismod mi eu nunc euismod, et viverra erat finibus. Morbi quis nisi convallis, ultrices nulla sollicitudin, vestibulum enim. Aliquam malesuada fermentum rutrum. Sed luctus tempus sagittis. Nunc in leo quis leo volutpat consequat euismod in lorem. Sed tincidunt sollicitudin imperdiet. Pellentesque faucibus congue luctus. Quisque quis libero vitae ipsum rutrum molestie. Aliquam blandit vel nulla eu commodo. Quisque malesuada justo pellentesque fringilla rhoncus. Nam malesuada pharetra eros, at facilisis felis congue nec.</p>

                            <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean nec justo risus. Sed in sapien augue. Suspendisse pellentesque pellentesque metus, sed pellentesque dolor bibendum id. Praesent pharetra orci nunc. Aliquam non scelerisque leo, quis aliquam orci. Duis convallis augue ac congue mattis. Etiam ut risus pharetra, pellentesque tortor a, aliquet sapien. Suspendisse faucibus mi sollicitudin orci consectetur tempor. Nullam commodo dignissim leo sit amet consectetur. Morbi ultrices, est sed tristique imperdiet, ex sapien pulvinar diam, a pulvinar velit ante vel nulla. Maecenas semper malesuada nulla eget pharetra.</p>

                            <p>Sed finibus sapien et magna vulputate commodo. Maecenas id elit sagittis, vulputate velit non, pharetra ipsum. Etiam ac facilisis lorem, ac imperdiet odio. Proin sed turpis placerat, placerat mauris convallis, finibus quam. Vivamus eu lacus a lorem pulvinar accumsan vitae eu magna. Nulla vitae velit neque. Nulla libero risus, hendrerit at neque vel, volutpat lacinia odio. Sed rutrum mauris nec est gravida, ut maximus enim convallis. Aenean eu dui rutrum, laoreet est ac, aliquet tellus. Aliquam sit amet tempor nibh.</p>

                            <p>Maecenas euismod mi eu nunc euismod, et viverra erat finibus. Morbi quis nisi convallis, ultrices nulla sollicitudin, vestibulum enim. Aliquam malesuada fermentum rutrum. Sed luctus tempus sagittis. Nunc in leo quis leo volutpat consequat euismod in lorem. Sed tincidunt sollicitudin imperdiet. Pellentesque faucibus congue luctus. Quisque quis libero vitae ipsum rutrum molestie. Aliquam blandit vel nulla eu commodo. Quisque malesuada justo pellentesque fringilla rhoncus. Nam malesuada pharetra eros, at facilisis felis congue nec.</p>

                            <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean nec justo risus. Sed in sapien augue. Suspendisse pellentesque pellentesque metus, sed pellentesque dolor bibendum id. Praesent pharetra orci nunc. Aliquam non scelerisque leo, quis aliquam orci. Duis convallis augue ac congue mattis. Etiam ut risus pharetra, pellentesque tortor a, aliquet sapien. Suspendisse faucibus mi sollicitudin orci consectetur tempor. Nullam commodo dignissim leo sit amet consectetur. Morbi ultrices, est sed tristique imperdiet, ex sapien pulvinar diam, a pulvinar velit ante vel nulla. Maecenas semper malesuada nulla eget pharetra.</p>

                            <p>Sed finibus sapien et magna vulputate commodo. Maecenas id elit sagittis, vulputate velit non, pharetra ipsum. Etiam ac facilisis lorem, ac imperdiet odio. Proin sed turpis placerat, placerat mauris convallis, finibus quam. Vivamus eu lacus a lorem pulvinar accumsan vitae eu magna. Nulla vitae velit neque. Nulla libero risus, hendrerit at neque vel, volutpat lacinia odio. Sed rutrum mauris nec est gravida, ut maximus enim convallis. Aenean eu dui rutrum, laoreet est ac, aliquet tellus. Aliquam sit amet tempor nibh.</p>
                        </div>

                        <div className="optInModal__footer">
                            <button className="closeModalBtn" onClick={() => handleAgreement()} disabled={(needScrolling && !agreed) || isLoading}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
