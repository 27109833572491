import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  height: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid ${colors.gray.blueLight};
  border-radius: 4px;

  padding: 8px 16px;

  font-family: "Source Sans Pro", sans-serif;

  button {
    width: 24px;
    height: 24px;
    background: transparent;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 43px;
    height: 32px !important;

    text-align: center;
    font-size: 18px;
    color: ${colors.dark};
  }
`;
