import styled from "styled-components";
import colors from "../../tokens/colors";

export const Container = styled.div`
  max-height: calc(100vh - 175px);;
  margin: 32px 0;
  overflow-y: auto;
  overflow-x: hidden;

  font-family: 'Source Sans Pro', sans-serif;

  p {
    font-weight: normal;
    font-size: 14px;
    color: #404F61;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  .formRow {
     & + .formRow {
       margin-top: 24px;
     }
    .inputNomeCpf {
      display: flex;
      flex-direction: row;
    }
    
    .grid-loaded {
      padding-right: 3rem;
    }

    .initial-grid-package {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-right: 3rem !important;

      & div.force-update {
        background: ${colors.gray.light};
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid ${colors.gray.medium};
        padding: .75rem;

        button {
          background: ${colors.blue};
          border: 0;
          font-size: 14px;
          font-weight: normal;
        }
      }

      table thead th {
        border-radius: 0;
      }

      & div:nth-child(2) {
        display: flex;
        width: 100%;
  
        & div:first-child {
          width: 100%;
          display: flex;
          flex-direction: column;

          & div {
            flex-direction: row;

            & :first-child > div {
              justify-content: center;
              display:none;
            }
          }
        }
      }

      & table {
        display: table;
      }
    }
  }  
  .mt-n-8 {
    margin-top: -13rem;

    .spinner-border {
      border: 0.25em solid ${colors.blue};
      border-right-color: transparent;
    }
  }
`;