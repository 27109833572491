import styled from "styled-components";
import { Form as Unform } from "@unform/web";

export const Container = styled.div`
  min-width: 925px;
  height: 100%;
  margin-left: 20px;
  margin-right: 50px;

  p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2a333e;
  }

  label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #2a333e;

    &:after {
      content: "*";
      color: red;
    }
  }
`;

export const Form = styled(Unform)`
  display: flex;
  flex-direction: column;
  border: 1px solid #d7e2ed;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const FormRow = styled.div`
  display: flex;
  margin: 32px;
  margin-bottom: 0;

  & + div {
    margin-top: 24px;
  }

  > div {
    width: 100%;

    & + div {
      margin-left: 24px;
    }

    #divIcon {
      padding: 7px 16px;
      justify-content: space-between;
    }
  }
`;

export const Codigo = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;

  label {
    width: 140px;
    font-size: 16px;
    line-height: 26px;
  }

  input {
    background: #ffffff;
    align-items: center;
    min-width: 140px;
    max-width: 140px;
    width: 100%;
    height: 40px;

    border: 1px solid #aec0d2;

    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;

    :disabled {
      cursor: not-allowed;
      background: #d7e2ed;
    }
  }
`;

export const Nome = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 697px;
    width: 100%;
    height: 40px;

    background: #ffffff;

    color: #7b8fa5;

    :disabled {
      cursor: not-allowed;
      background: #d7e2ed;
    }
  }
`;

export const Icone = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  div#divIcon {
    max-width: 140px;
    min-width: 140px;
    width: 100%;
    height: 40px;
    background: #ffffff;

    color: #7b8fa5;
    display: flex;
    align-items: center;

    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    img#iconSelected {
      width: 20px;
      height: 20px;
    }
  }

  label {
    &:after {
      content: "*";
      color: red;
    }
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 50;
`;
export const SelectIcons = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 51;

  min-width: 243px;
  max-width: 243px;
  width: 100%;
  min-height: 50px;

  border: 1px solid #aec0d2;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 0 19px 19px 0;
  position: absolute;
  top: 72px;

  img {
    width: 30px;
    height: 30px;
    margin-top: 16px;
    margin-left: 19px;

    & + img {
    }
  }
`;

export const Abreviacao = styled.div`
  display: flex;
  flex-direction: column;

  input {
    min-width: 253px;
    max-width: 253px;
    width: 100%;
    height: 40px;
    background: #ffffff;

    color: #7b8fa5;

    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const Subtitulo = styled.div`
  display: flex;
  flex-direction: column;

  input {
    max-width: 418px;
    min-width: 418px;
    width: 100%;
    height: 40px;
    background: #ffffff;

    color: #7b8fa5;

    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const Categoria = styled.div`
  display: flex;
  flex-direction: column;

  input {
    max-width: 417px;
    min-width: 417px;
    width: 100%;
    height: 40px;
    background: #ffffff;

    color: #7b8fa5;

    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 140px;
  width: 100%;

  div {
    max-width: 140px;
    height: 40px;
    line-height: 40px;
  }

  select {
    max-width: 140px;
    width: 100%;
    height: 40px;

    color: #7b8fa5;
    background: #ffffff;

    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const TempoExecucao = styled.div`
  max-width: 140px;
  max-height: 126px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
`;

export const Resultado = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    min-width: 418px;
    max-width: 418px;
    width: 100%;
    height: 96px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
    resize: none;
  }
`;

export const Preparo = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    min-width: 418px;
    max-width: 418px;
    width: 100%;
    height: 96px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
    resize: none;
  }
`;

export const Coleta = styled.div`
  display: flex;
  flex-direction: column;

  input {
    min-width: 407px;
    max-width: 407px;
    width: 100%;
    height: 40px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
  }
`;

export const PreRequisito = styled.div`
  display: flex;
  flex-direction: column;

  label {;

    &:after {
      content: "";
    }
  }

  textarea {
    min-width: 860px;
    min-width: 860px;
    width: 100%;
    height: 203px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
    resize: none;
  }
`;

export const Descricao = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 418px;
  max-width: 418px;

  textarea {
    min-width: 418px;
    max-width: 418px;
    width: 100%;
    height: 97px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
    resize: none;
  }
`;

export const Recomendacao = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    min-width: 417px;
    width: 100%;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    color: #7b8fa5;
    resize: none;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 32px 32px 0;
  font-family: "Source Sans Pro", sans-serif;

  button + button {
    margin-left: 24px;
  }
`;

export const ButtonCancel = styled.button`
  width: 123px;
  height: 48px;

  color: #0054a6;
  text-align: center;

  background: #ffffff;
  border: 1px solid #0054a6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ButtonSave = styled.button`
  width: 123px;
  height: 48px;

  color: #ffffff;
  text-align: center;

  background: #0054a6;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;

  :disabled {
    color: #404f61;
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;
