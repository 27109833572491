import "./weekView.scss";
import WeekDay from "./WeekDay/weekDay";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import { titleCase } from "../../../utils";
import { useState } from "react";
import PartnerTimePickerModal from "../PartnerTimePickerModal/partnerTimePickerModal";
import { toast } from "react-toastify";
import { partnerScheduleControlService } from "../../../services/partnerScheduleControlService";
import Swal from "sweetalert2";
import { usePartnerContext } from "../../../contexts/partnerContextProvider";

export default function WeekView({ scheduleData }) {
  const { setIsLoading } = usePartnerContext();
  const { configuracaoAgendaDiaSemana, partnerId, idLojaSala, cdLoja, ativo } =
    scheduleData;
  const [open, setOpen] = useState(false);
  const [nomeDiaSemana, setNomeDiaSemana] = useState("");
  const [startHourOpen, setStartHourOpen] = useState(false);
  const [endHourOpen, setEndHourOpen] = useState(false);
  const [weekDayCode, setWeekDayCode] = useState(0);
  const [selectedStartHour, setSelectedStartHour] = useState("");
  const [selectedEndHour, setSelectedEndHour] = useState("");

  const handleWeekDayInfo = (dayNumber) => {
    const weekDayPeriods = configuracaoAgendaDiaSemana.filter(
      (weekDay) => weekDay.diaSemana == dayNumber
    )[0];

    return {
      ...weekDayPeriods,
      IdLojaSala: idLojaSala,
      DiaDaSemana: dayNumber,
      IdParceiro: partnerId,
      CodigoLoja: cdLoja,
      ativo,
    };
  };

  const handleSetOpen = (e) => {
    setWeekDayCode(e.DiaDaSemana);
    setNomeDiaSemana(e.nomediaSemana);
    setOpen(true);
  };

  const handleSaveNewPeriod = async () => {
    setIsLoading(true);
    if (selectedStartHour === "") {
      toast.warning("Selecione a hora inicial!");
      setIsLoading(false);
      return null;
    }

    if (selectedEndHour === "") {
      toast.warning("Selecione a hora final!");
      setIsLoading(false);
      return null;
    }

    const sendObj = {
      HoraInicio: selectedStartHour,
      HoraFim: selectedEndHour,
      IdLojaSala: idLojaSala,
      CodigoLoja: cdLoja,
      DiaDaSemana: weekDayCode,
      IdParceiro: partnerId,
    };

    const insert = await partnerScheduleControlService.insertNewSchedulePeriod(
      sendObj
    );
    const { items } = insert;

    switch (items.status) {
      case false: {
        setOpen(false);
        Swal.fire({
          title: "Ops...",
          text: `${items.message}`,
          icon: "error",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        }).then(() => setOpen(true));
        setIsLoading(false);
        return null;
      }

      case true: {
        setIsLoading(false);
        toast.success("Período inserido com sucesso!");
        setOpen(false);
        setTimeout(() => {
          setIsLoading(true);
          window.location.href =
            partnerId !== 0
              ? `/portaladmin/partner/scheduleControl?pl=${partnerId}|${cdLoja}`
              : `/portaladmin/agenda?l=${cdLoja}`;
        }, 2500);
        return null;
      }
    }
  };

  const saveButtonOptions = {
    icon: "save",
    text: "Incluir",
    disabled: selectedStartHour === "" || selectedEndHour === "",
    onClick: () => handleSaveNewPeriod(),
  };

  const closeButtonOptions = {
    text: "Cancelar",
    onClick: () => {
      setSelectedStartHour("");
      setSelectedEndHour("");
      setOpen(false);
    },
  };

  return (
    <>
      <div className="weekView">
        <WeekDay weekDayInfo={handleWeekDayInfo(0)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(1)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(2)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(3)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(4)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(5)} setOpen={handleSetOpen} />

        <WeekDay weekDayInfo={handleWeekDayInfo(6)} setOpen={handleSetOpen} />
      </div>

      <Popup
        style={{ zIndex: "1060 !important" }}
        visible={open}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        showCloseButton={false}
        title={`Incluir Período - ${titleCase(nomeDiaSemana)}`}
        width={320}
        height={200}
      >
        <Position at="center" my="center" />

        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={closeButtonOptions}
        />

        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={saveButtonOptions}
        />

        <div className="timeSelection">
          <button
            className="timeSelection__startTime"
            onClick={() => setStartHourOpen(true)}
          >
            {selectedStartHour === "" ? "Selecione" : selectedStartHour}
          </button>

          <span>às</span>

          <button
            className="timeSelection__endTime"
            onClick={() => setEndHourOpen(true)}
          >
            {selectedEndHour === "" ? "Selecione" : selectedEndHour}
          </button>
        </div>
      </Popup>

      <PartnerTimePickerModal
        open={startHourOpen}
        setOpen={setStartHourOpen}
        selectedHour={selectedStartHour.split(":")}
        setSelectedHour={setSelectedStartHour}
        modalName="Hora Inicial"
      />

      <PartnerTimePickerModal
        open={endHourOpen}
        setOpen={setEndHourOpen}
        selectedHour={selectedEndHour.split(":")}
        setSelectedHour={setSelectedEndHour}
        modalName="Hora Final"
      />
    </>
  );
}
