import "./scheduleDetailModal.scss";

export default function ScheduleDetailModal({
  open,
  setOpen,
  details,
  ids,
  callReschedule,
  callCancel,
  callSetDone,
}) {
  const [serviceIds, storeId, scheduleId] = ids;
  const { id, data, hora, origemAgendamento, status, cliente, servicos } =
    details;

  const overlayHandler = (e: any) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  return (
    <>
      {open && (
        <div
          className="scheduleDetailsModalOverlay"
          onClick={(e) => overlayHandler(e)}
        >
          <div className="scheduleDetailsModal">
            <div className="scheduleDetailsModal__header">
              Detalhes do Agendamento <span>#{id}</span>
            </div>

            <div className="scheduleDetailsModal__content">
              <div className="scheduleDetailsModal__content__actionButtons">
                <button
                  className="actionBtn"
                  onClick={() => {
                    setTimeout(() => {
                      setOpen(false);
                      callSetDone(scheduleId);
                    }, 300);
                  }}
                  disabled={[2, 3, 6, 7].includes(status)}
                >
                  Executar Serviço
                </button>

                <button
                  disabled={[2, 3, 4].includes(status)}
                  className="actionBtn"
                  onClick={() => {
                    setTimeout(() => {
                      setOpen(false);
                      callReschedule(storeId, serviceIds, scheduleId);
                    }, 300);
                  }}
                >
                  Reagendar
                </button>

                <button
                  className="cancelBtn"
                  disabled={[2, 3].includes(status)}
                  onClick={() => {
                    setTimeout(() => {
                      setOpen(false);
                      callCancel(scheduleId);
                    }, 300);
                  }}
                >
                  Cancelar Agendamento
                </button>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>Cliente</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{cliente?.nome}</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>Telefone</th>
                    <th>Email</th>
                    <th>CPF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{cliente?.telefone}</td>
                    <td>{cliente?.email}</td>
                    <td>{cliente?.cpf}</td>
                  </tr>
                </tbody>
              </table>

              <table>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Horário </th>
                    <th>Rastreio de entrada</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {Intl.DateTimeFormat("pt-BR", {
                        timeZone: "UTC",
                      }).format(data ? new Date(String(data)) : new Date())}
                    </td>
                    <td>{hora}</td>
                    <td>{origemAgendamento}</td>
                  </tr>
                </tbody>
              </table>

              <fieldset className="scheduleDetailsModal__content__services">
                <legend>Serviços</legend>
                <table>
                  <thead>
                    <tr>
                      <th>Cód.</th>
                      <th>Serviço</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {servicos?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.codProduto}</td>
                          <td>{item.nomeAbreviado}</td>
                          <td>R$ {item.preco.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </fieldset>

              <div className="scheduleDetailsModal__content__totalValue">
                <span>Total</span>
                <div>
                  R${" "}
                  {servicos
                    ?.reduce((a, b) => a + (b["preco"] || 0), 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>

            <button className="closeModalBtn" onClick={() => setOpen(false)}>
              Confirmar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
