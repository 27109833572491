import { useEffect, useState } from "react";
import { ModalBase } from "../ModalBase";

import iconBlock from "images/icons/icon-block.svg";
import iconWarning from "images/icons/icon-alert2.svg";

import { Container, Description, ButtonCancel, ButtonContinue } from "./styles";
import axios from "axios";
import { addMinutes } from "utils";

type MotivoBloqueioLiberacaoData = {
  motivoSelecionado: number;
  descricaoMotivo: string;
};

type MotivoData = {
  id: number;
  descricao: string;
};

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (data: MotivoBloqueioLiberacaoData) => void;
  dateBlock: string[];
  hourBlock: string[];
  type: "block" | "release";
}

export function MotivoBloqueioLiberacao({
  isOpen,
  onRequestClose,
  onContinue,
  dateBlock,
  hourBlock,
  type,
}: ModalProps) {
  const [motivoSelecionado, setMotivoSelecionado] = useState(-1);
    const [descricaoMotivo, setDescricaoMotivo] = useState("");
    const [loadCount, setLoadCount] = useState(0);

  const [motivos, setMotivos] = useState<MotivoData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        console.log(`Buscando motivos ${loadCount}x`);
        const response = await axios.get(
          `/AgendaData/${
            type === "block" ? "GetBloqueioMotivos" : "GetLiberacaoMotivos"
          }`
        );
          if (response.data.result === "Error") {
              if (response.data.code === "001" && loadCount < 3) {
                  let count = loadCount + 1;
                  setLoadCount(count);
              }
          } else {
              setMotivos(response.data.items);
          }

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [type, loadCount]);

  useEffect(() => {
    setDescricaoMotivo("");
  }, [motivoSelecionado]);

  function renderHour() {
    const splitHourInitial = hourBlock[0].split(":");
    const splitHourFinal = addMinutes(hourBlock[1], 29).split(":");

    const hourInitial =
      splitHourInitial[0].padStart(2, "0") +
      ":" +
      splitHourInitial[1].padStart(2, "0");
    const hourFinal =
      splitHourFinal[0].padStart(2, "0") +
      ":" +
      splitHourFinal[1].padStart(2, "0");
    return `${hourInitial} às ${hourFinal}`;
  }

  function renderDate() {
    if (new Date(dateBlock[0]).getDate() === new Date(dateBlock[1]).getDate()) {
      return `${new Intl.DateTimeFormat("pt-BR", {
        day: "numeric",
        month: "long",
        timeZone: "UTC",
      }).format(new Date(dateBlock[0]))}`;
    }

    return `${new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      timeZone: "UTC",
    }).format(new Date(dateBlock[0]))} até ${new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      timeZone: "UTC",
    }).format(new Date(dateBlock[1]))}`;
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type={"medium"}>
      <Container>
        <Description>
          <div>
            <img
              src={type === "block" ? iconBlock : iconWarning}
              alt={type === "block" ? "Ícone de bloqueio" : "Ícone de atenção"}
            />
          </div>
          <h2>{type === "block" ? "Bloquear agenda" : "Liberar agenda"}</h2>
          <p className={motivoSelecionado !== 0 ? "increase-margin" : ""}>
            Informe abaixo qual o motivo para{" "}
            {type === "block" ? "o bloqueio" : "a liberação"} <br />
            da agenda no período da{" "}
            <strong>
              {renderHour()} do dia {renderDate()}
            </strong>
            ?
          </p>
        </Description>

        <div className="motivosContainer">
          {loading && <p>Carregando...</p>}
          {!loading &&
            motivos?.map((motivo) => (
              <span key={motivo.id}>
                <input
                  type="radio"
                  name="motivo"
                  id={"motivo-" + motivo.id}
                  className={motivoSelecionado === motivo.id ? "checked" : ""}
                  onClick={() => setMotivoSelecionado(motivo.id)}
                />
                <label htmlFor={"motivo-" + motivo.id}>
                  {motivo.descricao}
                </label>
              </span>
            ))}
          {!loading && (
            <span>
              <input
                type="radio"
                name="motivo"
                className={motivoSelecionado === 0 ? "checked" : ""}
                id="motivo-outro"
                onClick={() => setMotivoSelecionado(0)}
              />
              <label htmlFor="motivo-outro">Outro</label>
            </span>
          )}
        </div>

        {motivoSelecionado === 0 && (
          <span className="descricaoContainer">
            <label htmlFor="motivo-descricao">Qual o motivo?</label>
            <input
              name="motivo-descricao"
              id="motivo-descricao"
              placeholder="Limite de 40 caracteres"
              maxLength={40}
              value={descricaoMotivo}
              onChange={(event) => setDescricaoMotivo(event.target.value)}
            />
          </span>
        )}

        <div className="buttons">
          <ButtonCancel onClick={onRequestClose}>Cancelar</ButtonCancel>
          <ButtonContinue
            disabled={
              motivoSelecionado === -1 ||
              (motivoSelecionado === 0 && !descricaoMotivo)
            }
            onClick={() => {
              onContinue({ motivoSelecionado, descricaoMotivo });
            }}
          >
            Continuar
          </ButtonContinue>
        </div>
      </Container>
    </ModalBase>
  );
}
