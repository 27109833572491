import { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { Container } from "./styles";

interface Props {
  name: string;
  label?: string;
}

type TextAreaProps = JSX.IntrinsicElements["textarea"] & Props;

export function TextArea({ name, label, ...rest }: TextAreaProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <textarea
        id={fieldName}
        ref={textAreaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
}
