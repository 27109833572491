import { useState } from "react";
import AzureAuthenticationContext from "./azure-authentication-context";
import { AccountInfo } from "@azure/msal-browser";
import { useHistory } from "react-router";
import { useAuth } from "hooks/auth";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

interface AzureAuthenticationButtonProps {
  onAuthenticated?: (accountInfo: AccountInfo | undefined) => void;
  buttonText?: string;
}

// Log In, Log Out button
const AzureAuthenticationButton = ({ onAuthenticated, buttonText = "Login colaborador" }: AzureAuthenticationButtonProps): JSX.Element => {
  // Azure client context
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();

  const [authenticated, setAuthenticated] = useState<Boolean>(false);
  const [user, setUser] = useState<AccountInfo>();

  const { isLoading } = useAuth();

  const history = useHistory();

  const logIn = (method: string): any => {
    const typeName = "loginPopup";
    const logInType = isIE ? "loginRedirect" : typeName;

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };
  const logOut = (): any => {
    if (user) {
      if (onAuthenticated) onAuthenticated(undefined);
      // Azure Logout
      authenticationModule.logout(user);
    }
  };

  const returnedAccountInfo = async (user: AccountInfo) => {
    // set state
    setAuthenticated(user?.name ? true : false);
    if (onAuthenticated) await onAuthenticated(user);
    setUser(user);
  };

  const showLogInButton = (): any => {
    return (
      <button id="authenticationButton" onClick={() => logIn("loginPopup")}>
        {isLoading ? "Carregando..." : buttonText}
      </button>
    );
  };

  const showLogOutButton = (): any => {
    return (
      <div id="authenticationButtonDiv">
        <div id="authentication">
          <button id="authenticationButton" onClick={() => logOut()}>
            {isLoading || history.location.pathname === "/"
              ? "Carregando..."
              : "Sair"}
          </button>
        </div>
      </div>
    );
  };

  const showButton = (): any => {
    return authenticated ? showLogOutButton() : showLogInButton();
  };

  return (
    <div id="authentication">
      {authenticationModule.isAuthenticationConfigured ? (
        showButton()
      ) : (
        <div>Authentication Client ID is not configured.</div>
      )}
    </div>
  );
};

export default AzureAuthenticationButton;
