import React, { Ref, useEffect, useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Card } from 'primereact/card';
import { classNames } from "primereact/utils";
import { useHistory } from "react-router";
import { isAfter } from "date-fns";
import * as Yup from "yup";
import axios from "axios";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import { useLoader } from "hooks/loader";

import { Input } from "components/Input";
import Button from "components/Button";
import { Button as ButtonPrime } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputCalendar } from "components/InputCalendar";
import SelectDropdown from "components/SelectDropdown";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import errorImg from "images/iconError.png";
import { convert } from "utils";
import ImageInput from "components/ImageInput/imageInput";
import { TextArea } from "./styles";

interface HistoricoEdicoesData {
  editadoEm: string;
  editadoPor: string;
  matriculaEditor: string;
}

interface BeneficiosVinculados {
  id?: number;
  key?: number;
  idParceria?: number;
  quantidade: number | null;
  idServico: number;
  codigoServico: string;
  nomeServico: string;
  renovacao: number;
}

interface IFiles {
  data_url?: string;
}

interface Autenticacao {
  id?: number; 
  idParceria?: number;
  url?: string;
  tipoAutenticacao: string;
  token?: string;
  headerRecebeToken?: string;
  metodo?: string;
  usuario?: string;
  senha?: string;
  propriedadeRetornaToken?: string;
  body?: string;
  myHeadersAuth?: MyHeaderProps[];
}

interface CadastroParceriaAbaParceriaFormData {
  id: number;
  nome: string;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipo: number;
  idTipoParceria: number;
  urlBaseExterna: string;
  ativo: boolean;
  beneficios: BeneficiosVinculados[];
  historicoEdicoes: HistoricoEdicoesData[];
  utilizarMascara?: boolean;
  landingPageCadastro: boolean;
  exibirPainel: boolean;
  myHeaders?: MyHeaderProps[];
  logo?: string;  
  autenticacao?: Autenticacao;
}

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface TipoParceriaData {
  id: number;
  nome: string;
}

interface CadastroParceriaAbaParceriaProps {
  formDataParceria: CadastroParceriaAbaParceriaFormData;
  formRef: Ref<FormHandles>;
  onSubmitForm: (data: CadastroParceriaAbaParceriaFormData) => void;
  handleGoForward: () => void;
  handleValidateForm?: (isValid: boolean) => void;
}

interface MyHeaderProps {
  index?: number;
  chave: string;
  valor: string;
}

export function CadastroParceriaAbaParceria({
  formDataParceria,
  formRef,
  onSubmitForm,
  handleGoForward,
  handleValidateForm,
}: CadastroParceriaAbaParceriaProps) {
  const { handleSetIsLoader } = useLoader();
  const history = useHistory();

  const [nome, setNome] = useState(formDataParceria.nome || "");
  const [inicio, setInicio] = useState(
    formDataParceria.vigenciaInicio
      ? new Date(
        convert({
          dateString: formDataParceria.vigenciaInicio,
          pattern: "yyyy-mm-dd",
        })
      )
      : undefined
  );
  const [termino, setTermino] = useState(
    formDataParceria.vigenciaFim
      ? new Date(
        convert({
          dateString: formDataParceria.vigenciaFim,
          pattern: "yyyy-mm-dd",
        })
      )
      : undefined
  );
  const [tipoBeneficio, setTipoBeneficio] = useState(
    formDataParceria.idTipoParceria || 0
  );
  const [tipoCadastro, setTipoCadastro] = useState(formDataParceria.tipo || 0);
  const [urlConsulta, setUrlConsulta] = useState(
    formDataParceria.urlBaseExterna || ""
  );
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [tiposParceria, setTiposParceria] = useState<TipoParceriaData[]>([]);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [headers, setHeaders] = useState<MyHeaderProps[]>(
    formDataParceria.myHeaders || []
  );
  const [counterHeader, setCounterHeader] = React.useState(0);
  const [utilizarMascara, setUtilizarMascara] = React.useState(
    formDataParceria.utilizarMascara ?? true
  );
  const [landingPageCadastro, setLandingPageCadastro] = React.useState(false);
  const [exibirPainel, setExibirPainel] = React.useState(false);
  const [logo, setLogo] = useState<IFiles[]>([]);
  const [logoError, setLogoError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [searchImageLogo, setSearchImageLogo] = useState(false);
  const preventEnterEvent = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      return false;
    }
  };
  const [tipoAutenticacao, setTipoAutenticacao] = useState(
    "NENHUMA"
  );
  const [token, setToken] = useState(formDataParceria.autenticacao?.token || null);
  const [headerRecebeToken, setHeaderRecebeToken] = useState(formDataParceria.autenticacao?.headerRecebeToken || null);
  const [url, setUrl] = useState(formDataParceria.autenticacao?.url || null);
  const [metodo, setMetodo] = useState(formDataParceria.autenticacao?.metodo || null);
  const [usuario, setUsuario] = useState(formDataParceria.autenticacao?.usuario || null);
  const [senha, setSenha] = useState(formDataParceria.autenticacao?.senha || null);
  const [propriedadeRetornaToken, setPropriedadeRetornaToken] = useState(formDataParceria.autenticacao?.propriedadeRetornaToken || null);
  const [body, setBody] = useState(formDataParceria.autenticacao?.body || null);
  const [counterHeaderAuth, setCounterHeaderAuth] = React.useState(0);
  const [headersAuth, setHeadersAuth] = useState<MyHeaderProps[]>(
    formDataParceria.myHeaders || []
  ); 

  useEffect(() => {
    if (!logo?.[0]?.data_url) {
      setExibirPainel(false);
    }
  }, [logo]);

  useEffect(() => {
    window.addEventListener("keydown", preventEnterEvent);

    (async () => {
      const responseTipos = await axios.get("/beneficioData/GetAllTipo");

      if (responseTipos.data.result === "Success") {
        setTiposParceria(responseTipos.data.items);
      }
    })();

    return () => {
      window.removeEventListener("keydown", preventEnterEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (tipoCadastro === 1) {
        setUrlConsulta("");
        if (headers?.length >= 1) setHeaders([]);
      }


      await validateForm({
        nome,
        inicio,
        termino,
        tipoBeneficio,
        tipoCadastro,
        urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
        exibirPainel,
        logo,
        tipoAutenticacao,
      });
    })();
  }, [
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
    exibirPainel,
    logo,
    tipoAutenticacao,
  ]);

  const handleGoBack = () => history.goBack();

  async function validateForm({
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    logo,
    landingPageCadastro,
    exibirPainel,
    tipoAutenticacao,
  }: any) {

    setEnableNextButton(false);
    if (handleValidateForm) handleValidateForm(false);

    if (isAfter(inicio, termino)) return;

    const schema = Yup.object().shape({
      nome: Yup.string().required(),
      vigenciaInicio: Yup.string().required(),
      vigenciaFim: Yup.string().required(),
      tipoBeneficio: Yup.number().required().min(1),
      tipoCadastro: Yup.number().required().min(1),
      urlBaseExterna: Yup.string().when("tipoCadastro", {
        is: (tipoCadastro: number) => tipoCadastro === 2,
        then: Yup.string().required(),
      }),
      utilizarMascara: Yup.bool().required(),
      landingPageCadastro: Yup.bool().required(),
      exibirPainel: Yup.bool(),
      myHeaders: Yup.array().of(
        Yup.object().shape({
          chave: Yup.string().required(),
          valor: Yup.string().required(),
        })
      ),
      logo: Yup.string().when("exibirPainel", {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      tipoAutenticacao: Yup.string().required(),
    });

    await schema.validate(
      {
        nome,
        vigenciaInicio: inicio,
        vigenciaFim: termino,
        tipoBeneficio,
        tipoCadastro,
        urlBaseExterna: urlConsulta,
        myHeaders: headers,
        utilizarMascara,
        landingPageCadastro,
        exibirPainel,
        logo: logo?.[0]?.data_url,
        tipoAutenticacao,
      },
      {
        abortEarly: false,
      }
    );
    if (handleValidateForm) handleValidateForm(true);
    setEnableNextButton(true);
  }

  const removeHeader = (index: number) => {
    setHeaders((prevIndexes) => [
      ...prevIndexes.filter((item) => item.index !== index),
    ]);
    setCounterHeader((prevCounterHeader) => prevCounterHeader - 1);
  };

  const removeHeaderAuth = (index: number) => {
    setHeadersAuth((prevIndexes) => [
      ...prevIndexes.filter((item) => item.index !== index),
    ]);
    setCounterHeaderAuth((prevCounterHeader) => prevCounterHeader - 1);
  };

  const changeText = (index: number, chave: boolean, text: string) => {
    let newArr = [...headers];

    if (chave) newArr[index].chave = text;
    else newArr[index].valor = text;
    setHeaders(newArr);
  };

  async function limparCamposAutenticacao() {
    setUrl(null);
    setMetodo(null);
    setUsuario(null);
    setSenha(null);
    setPropriedadeRetornaToken(null);
    setBody(null);
    setHeaderRecebeToken(null);
  }

  return (
    <div className="form">
      <Form
        ref={formRef}
        onSubmit={(data: CadastroParceriaAbaParceriaFormData) => {

          //nome, início, térino, tipo, tipo cadastro, máscara, landingpage
          data.myHeaders = headers;
          data.utilizarMascara = utilizarMascara;
          data.landingPageCadastro = landingPageCadastro;
          data.exibirPainel = exibirPainel;
          data.logo = logo?.[0]?.data_url;

          data.autenticacao = {
            tipoAutenticacao: tipoAutenticacao,
            headerRecebeToken: headerRecebeToken || undefined,
            token: token || undefined,
            body: body || undefined,
            metodo: metodo || undefined,
            propriedadeRetornaToken: propriedadeRetornaToken || undefined,
            url: url || undefined,
            usuario: usuario || undefined,
            senha: senha || undefined,
            myHeadersAuth: headersAuth
          };

          handleGoForward();
          onSubmitForm(data);

          setSearchImageLogo(false);
        }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>          
        <Card subTitle="Dados básicos" className="md:w-25rem" style={{border: "1px solid #dedede", borderRadius: "05px"}}>
          <div className="p-fluid p-formgrid p-grid">        
            <div id="nome" className="p-field p-col-12 required">          
              <label htmlFor="nome">Nome</label>
              <Input
                name="nome"
                value={nome}
                onChange={(event) => setNome(event.target.value)}
                maxLength={40}
                placeholder="Insira o nome com limite de 40 caracteres"
              />
            </div>
            <div id="inicio" className="p-field p-col required">
              <label htmlFor="Inicio">Início</label>
              <InputCalendar
                name="vigenciaInicio"
                placeholder="00/00/0000"
                isRange={false}
                defaultDateSelected={inicio}
                handleDateChange={(event) => {
                  const date = event as Date;
                  if (termino && isAfter(date, termino)) {
                    setIsModalErrorOpen(true);
                  }
                  setInicio(date as Date);
                }}
              />
            </div>
            <div id="termino" className="p-field p-col required">
              <label htmlFor="termino">Término</label>
              <InputCalendar
                name="vigenciaFim"
                defaultDateSelected={termino}
                placeholder="00/00/0000"
                isRange={false}
                handleDateChange={(event) => {
                  const date = event as Date;
                  if (inicio && isAfter(inicio, date)) {
                    setIsModalErrorOpen(true);
                  }
                  setTermino(date as Date);
                }}
              />
            </div>
            <div id="tipoBeneficio" className="p-field p-col required">
              <label htmlFor="tipoBeneficio">Tipo</label>
              <SelectDropdown
                name="idTipoParceria"
                placeholder="Tipo do benefício"
                newValue={{
                  value: tipoBeneficio,
                  label:
                    tiposParceria.find((tipo) => tipo.id === tipoBeneficio)
                      ?.nome || "Tipo do benefício",
                }}
                options={tiposParceria.map((tipo) => ({
                  value: tipo.id,
                  label: tipo.nome,
                }))}
                onChange={(event: any) => setTipoBeneficio(event.value)}
              />
            </div>
            <div className="p-field p-col-12 p-m-0" style={{ opacity: 0 }}></div>
            <div id="tipoCadastro" className="p-field p-col-4 required">
              <label htmlFor="tipoCadastro">Tipo de Cadastro</label>
              <SelectDropdown
                name="tipo"
                placeholder="Selecione"
                newValue={{
                  value: tipoCadastro,
                  label:
                    [
                      {
                        value: 1,
                        label: "Manual",
                      },
                      {
                        value: 2,
                        label: "Automático",
                      },
                    ].find((tipo) => tipo.value === tipoCadastro)?.label ||
                    "Selecione",
                }}
                options={[
                  {
                    value: 1,
                    label: "Manual",
                  },
                  {
                    value: 2,
                    label: "Automático",
                  },
                ]}
                onChange={(event: any) => {
                  setTipoCadastro(event.value);
                  setHeaders([]);
                  setCounterHeader(0);
                }}
              />
            </div>
            <div id="utilizarMascara" className="p-field p-col-4 required">
              <label htmlFor="utilizarMascara">Utilizar Máscara?</label>
              <div className="p-formgroup-inline">
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="utilizarMascaraSim"
                    name="utilizarMascara"
                    value={utilizarMascara}
                    onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                    checked={utilizarMascara}
                  />
                  <label htmlFor="utilizarMascaraSim">Sim</label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="utilizarMascaraNao"
                    name="utilizarMascara"
                    value={utilizarMascara}
                    onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                    checked={!utilizarMascara}
                  />
                  <label htmlFor="utilizarMascaraNao">Não</label>
                </div>
              </div>
            </div>

            <div id="landingPageCadastro" className="p-field p-col-4 required">
              <label htmlFor="landingPageCadastro">
                Utilizar landing page de cadastro de clientes?
              </label>
              <div className="p-formgroup-inline">
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="landingPageCadastroSim"
                    name="landingPageCadastro"
                    value={landingPageCadastro}
                    onChange={(e) => setLandingPageCadastro(!landingPageCadastro)}
                    checked={landingPageCadastro}
                  />
                  <label htmlFor="landingPageCadastroSim">Sim</label>
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="landingPageCadastroNao"
                    name="landingPageCadastro"
                    value={landingPageCadastro}
                    onChange={(e) => setLandingPageCadastro(!landingPageCadastro)}
                    checked={!landingPageCadastro}
                  />
                  <label htmlFor="landingPageCadastroNao">Não</label>
                </div>
              </div>
            </div>           
            <div
              id="imageInputs"
              className="configLandingPageModal__content__imageInputs p-col-4"
              onClick={() => {
                setSearchImageLogo(true);
              }}
            >
              <ImageInput
                labelText={"Insira a imagem do logo"}
                multiple={false}
                maxWidth={220}
                maxHeight={80}
                image={logo}
                setImage={setLogo}
                errorMessage={logoError}
                existentImage={formDataParceria?.logo}
                setHasChanges={setHasChanges}
                readOnly={false}
              />

            </div>
            <div id="exibirPainel" className="p-field p-col-4 required">
              <label htmlFor="exibirPainel">
                Exibir logo no painel?
              </label>
              <div className="p-formgroup-inline" >
                <div className="p-field-radiobutton" >
                  <RadioButton
                    inputId="exibirPainelSim"
                    name="exibirPainel"
                    value={exibirPainel}
                    onChange={(e) => setExibirPainel(!exibirPainel)}
                    checked={exibirPainel}

                  />
                  <label htmlFor="exibirPainelSim">Sim</label >
                </div>
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="exibirPainelNao"
                    name="exibirPainel"
                    value={exibirPainel}
                    onChange={(e) => setExibirPainel(!exibirPainel)}
                    checked={!exibirPainel}
                  />
                  <label htmlFor="exibirPainelNao">Não</label>
                </div>
              </div>
            </div>
            <div id="urlConsulta" className="p-field p-col-12">
              <label htmlFor="urlConsulta">URL de consulta</label>
              <Input
                name="urlBaseExterna"
                value={urlConsulta}
                onChange={(event) => setUrlConsulta(event.target.value)}
                placeholder="Insira a url de consulta"
                disabled={tipoCadastro !== 2}
              />
            </div>
            <div
              className={classNames({
                "p-field p-col-12 p-m-0": true,
                "p-d-none": tipoCadastro !== 2,
              })}
              style={{ opacity: 0 }}
            ></div>
            {headers?.map((header) => {
              if (header)
                return (
                  <React.Fragment key={header.index}>
                    <div
                      id="myHeaders"
                      className={classNames({
                        "p-field p-col-4 required": true,
                        "p-d-none": tipoCadastro !== 2,
                      })}
                    >
                      <label htmlFor={`chave${header.index}`}>Chave</label>
                      <InputText
                        name={`chave${header.index}`}
                        value={header.chave}
                        onChange={(e) =>
                          changeText(header.index ?? -1, true, e.target.value)
                        }
                        placeholder="Insira a chave da requisição"
                        disabled={tipoCadastro !== 2}
                      />
                    </div>
                    <div
                      className={classNames({
                        "p-field p-col required": true,
                        "p-d-none": tipoCadastro !== 2,
                      })}
                    >
                      <label htmlFor={`valor${header.index}`}>Valor</label>
                      <div className="p-formgroup-inline">
                        <div className="p-field flex-1">
                          <InputText
                            name={`valor${header.index}`}
                            value={header.valor}
                            onChange={(e) =>
                              changeText(
                                header.index ?? -1,
                                false,
                                e.target.value
                              )
                            }
                            placeholder="Insira o valor da chave da requisição"
                            disabled={tipoCadastro !== 2}
                          />
                        </div>
                        <ButtonPrime
                          type="button"
                          icon="pi pi-trash"
                          className="custom-btn-remove p-button-danger"
                          onClick={() => removeHeader(header.index ?? -1)}
                        />
                      </div>
                    </div>
                    <div
                      className={classNames({
                        "p-field p-col-12 p-m-0": true,
                        "p-d-none": tipoCadastro !== 2,
                      })}
                      style={{ opacity: 0 }}
                    ></div>
                  </React.Fragment>
                );
            })}
            <div
              className={classNames({
                "p-field p-col-3": true,
                "p-d-none": tipoCadastro !== 2,
              })}
              id="tipoCadastro"
            >
              <ButtonPrime
                type="button"
                label="Adicionar Header"
                icon="pi pi-check"
                disabled={counterHeader >= 3}
                className="backgroud-color-default"
                onClick={(_) => {
                  _.preventDefault();
                  setHeaders((values) => [
                    ...values,
                    { index: counterHeader, chave: "", valor: "" },
                  ]);
                  setCounterHeader((prevCounterHeader) => prevCounterHeader + 1);
                }}
              />
            </div>
          </div>
        </Card>
        <br/>
        <div hidden={tipoCadastro !== 2}>
        <Card subTitle="Dados da autenticação" className="md:w-25rem" style={{border: "1px solid #dedede", borderRadius: "05px"}}>
          <div id="tipoAutenticacao" className="p-field p-col-4 required">
            <label htmlFor="tipoAutenticacao">Tipo de autenticacao</label>
            <div className="p-formgroup">
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="tipoAutenticacaoNenhuma"
                  name="tipoAutenticacao"
                  value={tipoAutenticacao === 'NENHUMA' ? true : false}
                  onChange={(e) => {
                    setTipoAutenticacao("NENHUMA");
                    limparCamposAutenticacao();
                  }}
                  checked={tipoAutenticacao === 'NENHUMA' ? true : false}
                />
                <label htmlFor="tipoAutenticacaoNenhuma">Nenhuma</label>
              </div>
              {/* <div className="p-field-radiobutton">
                <RadioButton
                  inputId="tipoAutenticacaoChaveApi"
                  name="tipoAutenticacao"
                  value={tipoAutenticacao === 'CHAVEAPI' ? true : false}
                  onChange={(e) => setTipoAutenticacao("CHAVEAPI")}
                  checked={tipoAutenticacao === 'CHAVEAPI' ? true : false}
                />
                <label htmlFor="tipoAutenticacaoChaveApi">Chave da API</label>
              </div> */}
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="tipoAutenticacaoBasic"
                  name="tipoAutenticacao"
                  value={tipoAutenticacao === 'BASICA' ? true : false}
                  onChange={(e) => setTipoAutenticacao("BASICA")}
                  checked={tipoAutenticacao === 'BASICA' ? true : false}
                />
                <label htmlFor="tipoAutenticacaoBasic">Autenticação básica</label>
              </div>
              {/* <div className="p-field-radiobutton">
                <RadioButton
                  inputId="tipoAutenticacaoToken"
                  name="tipoAutenticacao"
                  value={tipoAutenticacao === 'TOKEN' ? true : false}
                  onChange={(e) => setTipoAutenticacao("TOKEN")}
                  checked={tipoAutenticacao === 'TOKEN' ? true : false}
                />
                <label htmlFor="tipoAutenticacaoToken">Token do portador</label>
              </div> */}
            </div>
          </div>
          {/* {tipoAutenticacao === "TOKEN" && (
             <div className="p-fluid p-formgrid p-grid">
             <div id="token" className="p-field p-col-6 required">          
               <label htmlFor="token">Token</label>
               <Input
                 name="token"
                 value={token}
                 onChange={(event) => setToken(event.target.value)}
                 maxLength={40}
                 placeholder="Insira o token fornecido pelo parceiro"
               />
             </div>
             <div id="headerRecebeToken" className="p-field p-col-6 required">          
               <label htmlFor="headerRecebeToken">Header que receberá o token</label>
               <Input
                 name="headerRecebeToken"
                 value={headerRecebeToken}
                 onChange={(event) => setHeaderRecebeToken(event.target.value)}
                 maxLength={40}
                 placeholder="Informe o Header que receberá o token"
               />
             </div>
           </div>
          )} */}
          {tipoAutenticacao === "BASICA" && (
            <div className="p-fluid p-formgrid p-grid">
             <div id="urlBasica" className="p-field p-col-12 required">          
               <label htmlFor="urlBasica">URL de autenticação</label>
               <Input
                 name="urlBasica"
                 value={url || ''}
                 onChange={(event) => setUrl(event.target.value)}
                 maxLength={80}
                 placeholder="Insira a url de autenticação"
               />
             </div>
             <div id="metodo" className="p-field p-col-4 required">          
               <label htmlFor="metodo">Método de requisição</label>
               <SelectDropdown
                name="metodo"
                placeholder="Selecione"
                newValue={{
                  value: metodo,
                  label:
                    [
                      {
                        value: 1,
                        label: "GET",
                      },
                      {
                        value: 2,
                        label: "POST",
                      },
                    ].find((metodoSelecionado) => metodoSelecionado.label === metodo)?.label ||
                    "Selecione",
                }}
                options={[
                  {
                    value: 1,
                    label: "GET",
                  },
                  {
                    value: 2,
                    label: "POST",
                  },
                ]}
                onChange={(event: any) => {
                  setMetodo(event.label);
                }}
              />
             </div>
             <div id="usuarioBasica" className="p-field p-col-4 required">          
               <label htmlFor="usuarioBasica">Usuário</label>
               <Input
                 name="usuarioBasica"
                 value={usuario || ''}
                 onChange={(event) => setUsuario(event.target.value)}
                 maxLength={40}
                 placeholder="Insira a url de auteticação"
               />
             </div>
             <div id="senhaBasica" className="p-field p-col-4 required">          
               <label htmlFor="senhaBasica">Senha</label>
               <Input
                 name="urlBasica"
                 value={senha || ''}
                 onChange={(event) => setSenha(event.target.value)}
                 maxLength={40}
                 placeholder="Insira a senha"
               />
             </div>
             <div id="propRetornaToken" className="p-field p-col-4 required">          
               <label htmlFor="propRetornaToken">Propriedade do retorno que tratá o token</label>
               <Input
                 name="propriedadeRetornaToken"
                 value={propriedadeRetornaToken || ''}
                 onChange={(event) => setPropriedadeRetornaToken(event.target.value)}
                 maxLength={40}
                 placeholder="Informe o nome da propriedade"
               />
             </div>
             <div id="headerRecebeToken" className="p-field p-col-4 required">          
               <label htmlFor="headerRecebeToken">Header que receberá o token</label>
               <Input
                 name="headerRecebeToken"
                 value={headerRecebeToken || ''}
                 onChange={(event) => setHeaderRecebeToken(event.target.value)}
                 maxLength={40}
                 placeholder="Informe o Header que receberá o token"
               />
             </div>
             <div id="bodyBasica" className="p-field p-col-12">          
               <label htmlFor="bodyBasica">Body</label>
               <TextArea
                 name="bodyBasica"
                 value={body || ''}
                 onChange={(event) => setBody(event.target.value)}
                 maxLength={400}
                 placeholder="Insira o body"
                 rows={5}
               />
             </div>
           </div>
          )}
          {tipoAutenticacao === "CHAVEAPI" && (
            <div className="p-fluid p-formgrid p-grid">
             <div id="urlChaveApi" className="p-field p-col-12 required">          
               <label htmlFor="urlChaveApi">URL de autenticação</label>
               <Input
                 name="urlChaveApi"
                 value={url || ''}
                 onChange={(event) => setUrl(event.target.value)}
                 maxLength={40}
                 placeholder="Insira a url de autenticação"
               />
             </div>
             <div id="metodoChaveApi" className="p-field p-col-4 required">          
               <label htmlFor="metodoChaveApi">Método de requisição</label>
               <Input
                 name="metodoChaveApi"
                 value={metodo || ''}
                 onChange={(event) => setMetodo(event.target.value)}
                 maxLength={40}
                 placeholder="Insira método de requisição"
               />
             </div>            
             <div id="propRetornaTokenChaveApi" className="p-field p-col-4 required">          
               <label htmlFor="propRetornaTokenChaveApi">Propriedade do retorno que tratá o token</label>
               <Input
                 name="propriedadeRetornaTokenChaveApi"
                 value={propriedadeRetornaToken || ''}
                 onChange={(event) => setPropriedadeRetornaToken(event.target.value)}
                 maxLength={40}
                 placeholder="Informe o nome da propriedade"
               />
             </div>
             <div id="headerRecebeTokenChaveApi" className="p-field p-col-4 required">          
               <label htmlFor="headerRecebeTokenChaveApi">Header que receberá o token</label>
               <Input
                 name="headerRecebeTokenChaveApi"
                 value={headerRecebeToken || ''}
                 onChange={(event) => setHeaderRecebeToken(event.target.value)}
                 maxLength={40}
                 placeholder="Informe o Header que receberá o token"
               />
             </div>
             {headersAuth?.map((headerAuth) => {
              if (headerAuth)
                return (
                  <React.Fragment key={headerAuth.index}>
                    <div
                      id="myHeadersAuth"
                      className={classNames({
                        "p-field p-col-4 required": true,
                        "p-d-none": tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI",
                      })}
                    >
                      <label htmlFor={`chave${headerAuth.index}`}>Chave</label>
                      <InputText
                        name={`chave${headerAuth.index}`}
                        value={headerAuth.chave}
                        onChange={(e) =>
                          changeText(headerAuth.index ?? -1, true, e.target.value)
                        }
                        placeholder="Insira a chave da requisição"
                        disabled={tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI"}
                      />
                    </div>
                    <div
                      className={classNames({
                        "p-field p-col required": true,
                        "p-d-none": tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI",
                      })}
                    >
                      <label htmlFor={`valor${headerAuth.index}`}>Valor</label>
                      <div className="p-formgroup-inline">
                        <div className="p-field flex-1">
                          <InputText
                            name={`valor${headerAuth.index}`}
                            value={headerAuth.valor}
                            onChange={(e) =>
                              changeText(
                                headerAuth.index ?? -1,
                                false,
                                e.target.value
                              )
                            }
                            placeholder="Insira o valor da chave da requisição"
                            disabled={tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI"}
                          />
                        </div>
                        <ButtonPrime
                          type="button"
                          icon="pi pi-trash"
                          className="custom-btn-remove p-button-danger"
                          onClick={() => removeHeader(headerAuth.index ?? -1)}
                        />
                      </div>
                    </div>
                    <div
                      className={classNames({
                        "p-field p-col-12 p-m-0": true,
                        "p-d-none": tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI",
                      })}
                      style={{ opacity: 0 }}
                    ></div>
                  </React.Fragment>
                );
            })}
             <div
              className={classNames({
                "p-field p-col-3": true,
                "p-d-none": tipoCadastro !== 2 && tipoAutenticacao === "CHAVEAPI",
              })}
              id="tipoAutenticacao"
            >
              <ButtonPrime
                type="button"
                label="Adicionar Header"
                icon="pi pi-check"
                disabled={counterHeaderAuth >= 3}
                className="backgroud-color-default"
                onClick={(_) => {
                  _.preventDefault();
                  setHeadersAuth((values) => [
                    ...values,
                    { index: counterHeaderAuth, chave: "", valor: "" },
                  ]);
                  setCounterHeaderAuth((prevCounterHeaderAuth) => prevCounterHeaderAuth + 1);
                }}
              />
            </div>
             {/* <div id="chaveChaveApi" className="p-field p-col-4 required">          
               <label htmlFor="chaveChaveApi">Chave</label>
               <Input
                 name="chaveChaveApi"
                 value={headerRecebeToken}
                 onChange={(event) => setHeaderRecebeToken(event.target.value)}
                 maxLength={40}
                 placeholder="Insira a chave"
               />
             </div>
             <div id="valorChaveApi" className="p-field p-col-4 required">          
               <label htmlFor="valorChaveApi">Valor</label>
               <Input
                 name="valorChaveApi"
                 value={headerRecebeToken}
                 onChange={(event) => setHeaderRecebeToken(event.target.value)}
                 maxLength={40}
                 placeholder="Insira o valor"
               />
             </div> */}
            </div>
          )}
        </Card>
        </div>
        <br></br>        
        <div className="contentFooter">
          <Button inverted onClick={handleGoBack}>
            Cancelar
          </Button>
          <Button disabled={!enableNextButton}>Avançar</Button>
        </div>
      </Form>

      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => setIsModalErrorOpen(false)}
        icon={errorImg}
        title="Erro"
        message="A data inicial deve ser menor que a data final."
        continueButtonText="Fechar"
      />
    </div>
  );
}
