import styled from "styled-components";
import colors from "../../../tokens/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    color: ${colors.dark};
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 8px;
    font-family: "Source Sans Pro", sans-serif;
    color: ${colors.blue};

    font-size: 18px;
  }

  span {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    color: #ED1D24;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 9px;

    &.canceled {
      cursor: default;
    }

    &.pre-sale {
      color: ${colors.blue};
    }

    &.execute-service {
      color: ${colors.blue};
    }

    &.reschedule {
      color: ${colors.yellowDark};
    }

    &:not(.canceled):hover {
      filter: brightness(0.8);
    }
  }

  button {
    font-family: "Source Sans Pro", sans-serif;
  }
`;

export const CloseModal = styled.div`
  img {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`;

export const ContainerTableOne = styled.div`
  margin-bottom: 27px;

  table {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    thead {
      tr {
        th {
          color: ${colors.dark};
          padding-bottom: 8px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
          padding-left: 0;
          padding-top: 0;
          color: ${colors.gray.text};
          width: 100%;
        }
      }
    }
  }
`;

export const ContainerTableThree = styled.div`
  margin-bottom: 27px;

  table {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    thead {
      tr {
        th {
          color: ${colors.dark};
          padding-bottom: 8px;
        }
      }
    }

    tbody {
      tr {
        td {
          color: ${colors.gray.text};
          width: 194px;
          overflow-wrap: anywhere;
          padding-right: 15px;
        }

        td:first-child {
          width: 109px;
        }

        td:last-child {
          width: 143px;
        }
      }
    }
  }
`;

export const ContainerTableThreeEnd = styled.div`
  margin-bottom: 27px;

  table {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    thead {
      tr {
        th {
          color: ${colors.dark};
          padding-bottom: 8px;
        }
      }
    }

    tbody {
      tr {
        td {
          color: ${colors.gray.text};
          width: 194px;
        }

        td:first-child {
          width: 109px;
        }

        td:last-child {
          width: 143px;
          text-align: end;
        }
      }
    }
  }
`;

export const ContainerTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  p {
    color: ${colors.dark};
    font-weight: 600;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 132px;
    height: 48px;
  }
`;
