import { createGlobalStyle } from "styled-components";
import colors from "../tokens/colors";
import iconArrowCalendar from "../images/icons/iconArrowCalendar.png";

export default createGlobalStyle`
  :root {
    --font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-size: 1rem;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Source Sans Pro", sans-serif;
  }

  *, button, input {
    border: 0;
    outline: 0;
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type=number] {
        -moz-appearance:textfield;
    }
  }

  button {
    cursor: pointer;
  }

  .backgroud-color-default {
    background-color: ${colors.blue};
  }

  .flex-1 {
    flex: 1
  }

  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${colors.white};
  }

  .swal2-container {
    z-index: 2103;
  }

  .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999;
  }

  .react-modal-content {
    /*top: -70px;*/
    top: -35px;
    width: 100%;
    max-width: 414px;
    background: ${colors.white};
    padding: 32px;
    border-radius: 4px;
    position: relative;
    z-index: 99999999999;
  }

  .react-modal-content-large {
    width: 100%;
    max-width: 525px;
    background: ${colors.white};
    padding: 32px;
    border-radius: 4px;
    position: relative;
    z-index: 99999999999;
  }

  .react-modal-content-large-width {
    width: 100%;
    max-height: 637px;
    max-width: 740px;
    background: ${colors.white};
    padding: 32px;
    border-radius: 4px;
    position: relative;
    z-index: 99999999999;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day--outside-month {
    color: #7B8FA5;
  }

  .react-datepicker__day-name {
    color: #7B8FA5;
  }

  .react-datepicker__day--keyboard-selected {
    color: #FFF;
    background: #0054A6;
  }

  .react-datepicker__navigation {
    top: 17px;
    padding: 12px;
  }

  .react-datepicker__navigation--previous {
    background: url(${iconArrowCalendar}) no-repeat;
    width: 15px;
    height: 15px;
    border: none;
    left: 20px;


    &:hover {
    }
  }

  .react-datepicker__navigation--next {
    background: url(${iconArrowCalendar}) no-repeat;
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
    border: none;
    right: 20px;

    &:hover {
    }
  }

  .react-datepicker__header {
    background: #FFFFFF;
    border-bottom: none;

    .react-datepicker__current-month {
      font-family: 'Open Sans', sans-serif;
      color: #404F61;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 0 17px;
      border-bottom: 1px solid #AEC0D2;
      margin-bottom: 25px;
    }
  }
    .react-calendar .react-calendar__month-view__weekdays__weekday {
        color: #7B8FA5 !important;
    }
    .react-calendar abbr {
      font-family: 'Open Sans', sans-serif !important;
    }

  .react-datepicker__month {
    margin-bottom: 20px;
  }

  .force-update-grid {
    background: ${colors.gray.light};
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${colors.gray.medium};
    padding: .75rem;

    button {
      background: ${colors.blue};
      border: 0;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .session-grid {
    th {
      border-radius: 0!important;
    }

    div:nth-last-child(2) {
      div {
        table ~ :last-child {
          margin-top: 2rem;
        }
      }
    }

    .force-update-grid ~ div {
      th {
        position: relative;
      }
    }
  }

  .gridSubtitle {
    font-family: "Source Sans Pro", sans-serif;
    color: ${colors.gray.text};
    display: flex;
    align-items: center;    
    margin-top: -3.5rem;
    padding-bottom: 3rem;

    strong {
      font-size: 12px;
    }

    span {
      display: flex;
      align-items: center;
      
      margin-left: 4px;
      
      p {
        font-size: 10px;
        margin: 0;
        margin-left: 0.15rem;
      }

      img {
        width: 12px;
        height: 12px;

        margin-left: 2px;
      }
    }
  }

  .p-datatable {
    .p-datatable-emptymessage {
      td {
        text-align: center !important;
      }
    }
    
    .p-datatable-header {
      /* padding: 0.5rem 0 0.5rem 0 !important;
      margin-bottom: 2rem;
      background: transparent;
      border: 0; */

      .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-family: "Source Sans Pro",sans-serif;          
          font-weight: 600;
          color: #2A333E;
        }
      }
    }

    .p-datatable-scrollable-header {
      background: rgb(215, 226, 237) !important;

      .p-datatable-thead > tr > th {
        background: rgb(215, 226, 237);
      }
    }

    .p-datatable-tbody {
      td {
        .p-inputwrapper-filled {
          width: 100%;
        }
      }
    }
  }

  .badgeActive {
    background: rgb(219, 248, 232);
    color: rgb(35, 123, 75);
  }

  .badgeInactive {
    background: rgb(218, 218, 218);
    color: rgb(123, 143, 165);
  }

  .p-dropdown-panel {
    .p-dropdown-items {
      button{
        &.p-button{
          width: 100%;
        }
      }
    }
  }

  .p-field {
    &.p-md-5, &.p-md-4, &.p-md-3, &.p-md-2 {
      padding: 0 0.5rem !important;
    }

    > .p-md-10 {
      padding: 0.5rem !important;
    }
  }

  .modal--history-transfer {
    .p-dialog-title	{
      color: ${colors.textDarkModal};
    }
  }
`;
