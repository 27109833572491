import styled from 'styled-components';


export const Card = styled.div`
  width: 30%;
  min-width: 135px;
  max-height: 80vh;
  position: absolute;
  top: 49px;
  right: 99px;
  background: #FFFFFF;
  border: 1px solid #D7E2ED;
  box-sizing: border-box;
  border-radius: 4px;

  header {
    display: flex;
    flex-direction: column;
    margin: 15px 15px;
    font-family: 'Source Sans Pro', sans-serif;

    p {
      margin: 0;
      font-weight: 700;
      font-size: 18px;
      color: #2A333E;
    }

    span {
      font-size: 12px;
      color: #404F61;
    }
  }

  main {
    font-family: 'Source Sans Pro', sans-serif;
    width: calc(100% - 8px);
    min-width: 135px;
    max-height: 50vh;
    margin-top: 15px;
    margin-left: 8px;
    overflow-y: scroll;
    overflow-x: clip;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #e8edf3;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #a7bed6;
      border-radius: 5px;
    }

    .item-notification {
      margin-top: 0;
    }

    .item-notification {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      width: 370px;
      height: 101px;
      margin-bottom: 3px;

      .row1 {
        display: flex;
        flex-direction: row;
        font-size: 14px;

        img {
          width: 24px;
          height: 24px;
          margin-left: 7px;
        }

        p {
          margin: 0;
          margin-left: 8px;
          font-weight: bold;
        }

        label {
          margin: 0;
          margin-left: 5px;
        }
      }

      span {
        font-size: 14px;
        margin-left: 39px;
      }

      .icons {
        position: relative;
        margin-left: 39px;

        img:first-child {
          margin-left: 0;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
        }
      }

      .row2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .tempo {
        font-size: 14px;
        color: #404F61;
      }

      .button-marcar-todos {
        display: flex;
        font-weight: 600;
        font-size: 16px;
        color: #0054A6;
        cursor: pointer;
        margin: 0;

        &:hover {
          text-decoration: underline;
          color: #0054A6;
        }
      }

      .linha {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 370px;
        min-height: 1px;
        background: #EFEFEF;
      }
    }

    .nenhuma-notificacao {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: justify;
      height: auto;
      font-size: 14px;
      color: #7B8FA5;
      margin-top: 14px;
      margin-bottom: 15px;
    }

    .notificacoes-lidas {
      width: 377px;
      height: 38px;
      display: flex;
      align-items: center;
      background: #D7E2ED;
      color: #0054A6;
      font-size: 14px;
      padding: 11px 14px;
    }

  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 78px;

    button {
      width: 220px;
      height: 48px;
      background: #0054A6;
      color: #FFFFFF;
      border: 1px solid #D7E2ED;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-family: 'Source Sans Pro', sans-serif;
    }
  }
`;