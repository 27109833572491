import { useEffect, useRef, useState } from "react";

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { Tab } from "components/Tab";
import { CadastroParceriaAbaParceria } from "components/CadastroParceriaAbaParceria";
import { CadastroParceriaAbaBeneficios } from "components/CadastroParceriaAbaBeneficios";
import { colors } from "tokens";
import { Card } from "primereact/card";

import * as S from "./styles";
import { FormHandles } from "@unform/core";

function PartnershipIcon() {
  return (
    <svg
      width="16.67"
      height="11.67"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41675 3.08317C9.41675 4.6915 8.10841 5.99984 6.50008 5.99984C4.89175 5.99984 3.58341 4.6915 3.58341 3.08317C3.58341 1.47484 4.89175 0.166504 6.50008 0.166504C8.10841 0.166504 9.41675 1.47484 9.41675 3.08317ZM7.75008 3.08317C7.75008 2.3915 7.19175 1.83317 6.50008 1.83317C5.80842 1.83317 5.25008 2.3915 5.25008 3.08317C5.25008 3.77484 5.80842 4.33317 6.50008 4.33317C7.19175 4.33317 7.75008 3.77484 7.75008 3.08317ZM6.50008 7.45817C4.55008 7.45817 0.666748 8.43317 0.666748 10.3748V10.9998C0.666748 11.4582 1.04175 11.8332 1.50008 11.8332H11.5001C11.9584 11.8332 12.3334 11.4582 12.3334 10.9998V10.3748C12.3334 8.43317 8.45008 7.45817 6.50008 7.45817ZM6.50007 9.12484C5.0084 9.12484 3.31673 9.68317 2.61673 10.1665H10.3834C9.6834 9.68317 7.99173 9.12484 6.50007 9.12484ZM12.3667 7.50816C13.3334 8.20816 14.0001 9.1415 14.0001 10.3748V11.8332H16.5001C16.9584 11.8332 17.3334 11.4582 17.3334 10.9998V10.3748C17.3334 8.6915 14.4167 7.73316 12.3667 7.50816ZM14.4167 3.08317C14.4167 4.6915 13.1084 5.99984 11.5001 5.99984C11.0501 5.99984 10.6334 5.8915 10.2501 5.70817C10.7751 4.9665 11.0834 4.05817 11.0834 3.08317C11.0834 2.10817 10.7751 1.19984 10.2501 0.458171C10.6334 0.274837 11.0501 0.166504 11.5001 0.166504C13.1084 0.166504 14.4167 1.47484 14.4167 3.08317Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

function BeneficioIcon() {
  return (
    <svg
      width="16.67"
      height="15.83"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3332 5.08337C17.3332 4.15837 16.5915 3.41671 15.6665 3.41671H12.3332V1.75004C12.3332 0.825041 11.5915 0.083374 10.6665 0.083374H7.33317C6.40817 0.083374 5.6665 0.825041 5.6665 1.75004V3.41671H2.33317C1.40817 3.41671 0.674837 4.15837 0.674837 5.08337L0.666504 14.25C0.666504 15.175 1.40817 15.9167 2.33317 15.9167H15.6665C16.5915 15.9167 17.3332 15.175 17.3332 14.25V5.08337ZM10.6665 3.41671V1.75004H7.33317V3.41671H10.6665ZM2.33317 5.91671V13.4167C2.33317 13.875 2.70817 14.25 3.1665 14.25H14.8332C15.2915 14.25 15.6665 13.875 15.6665 13.4167V5.91671C15.6665 5.45837 15.2915 5.08337 14.8332 5.08337H3.1665C2.70817 5.08337 2.33317 5.45837 2.33317 5.91671Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

interface HistoricoEdicoesData {
  editadoEm: string;
  editadoPor: string;
  matriculaEditor: string;
}

interface BeneficiosVinculados {
  id?: number;
  key?: number;
  idParceria?: number;
  quantidade: number | null;
  idServico: number;
  codigoServico: string;
  nomeServico: string;
  renovacao: number;
  nomeRenovacao?: string;
  periodicidade?: object;
}

interface Autenticacao {
  id?: number; 
  idParceria?: number;
  url?: string;
  tipoAutenticacao: string;
  token?: string;
  headerRecebeToken?: string;
  metodo?: string;
  usuario?: string;
  senha?: string;
  propriedadeRetornaToken?: string;
  body?: string;
  myHeadersAuth?: MyHeaderProps[];
}

interface CadastroParceriaAbaParceriaFormData {
  id: number;
  nome: string;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipo: number;
  idTipoParceria: number;
  urlBaseExterna: string;
  ativo: boolean;
  beneficios: BeneficiosVinculados[];
  historicoEdicoes: HistoricoEdicoesData[];
  utilizarMascara?: boolean;
  landingPageCadastro: boolean;
  exibirPainel: boolean;
  myHeaders?: MyHeaderProps[];
  logo?: string;
  autenticacao?: Autenticacao;
}

interface CadastroParceriaAbaBeneficiosFormData {
  idServico: number;
  quantidade: number;
  renovacao: number;
  nomeRenovacao: string;
}

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface MyHeaderProps {
  index?: number;
  chave: string;
  valor: string;
}

export function CadastroParceria() {
  const [activeTab, setActiveTab] = useState<"parceria" | "beneficios">(
    "parceria"
  );
  const [formIsValid, setFormIsValid] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const [
    cadastroParceriaAbaParceriaFormData,
    setCadastroParceriaAbaParceriaFormData,
  ] = useState({} as CadastroParceriaAbaParceriaFormData);

  const [
    cadastroParceriaAbaBeneficiosFormData,
    setCadastroParceriaAbaBeneficiosFormData,
  ] = useState({} as CadastroParceriaAbaBeneficiosFormData);

  function handleGoForward() {
    setActiveTab("beneficios");
  }

  return (
    <>
      <Header />
      <Main>
        <S.DashboardContainer>
          <DashboardUser />
        </S.DashboardContainer>
        <S.Content>
          <h2>CRIAR PARCERIA</h2>
          <Card className="contentBody">
            <div className="tabHeader">
              <Tab
                title="Parceria"
                selected={activeTab === "parceria"}
                icon={<PartnershipIcon />}
                onClick={() => setActiveTab("parceria")}
              />
              <Tab
                title="Benefícios"
                selected={activeTab === "beneficios"}
                icon={<BeneficioIcon />}
                onClick={() => {
                  if (!formIsValid) {
                    return;
                  }

                  formRef.current?.submitForm();
                  setActiveTab("beneficios");
                }}
              />
            </div>
            {activeTab === "parceria" ? (
              <CadastroParceriaAbaParceria
                formRef={formRef}
                formDataParceria={cadastroParceriaAbaParceriaFormData}
                onSubmitForm={(data: CadastroParceriaAbaParceriaFormData) => {
                  setCadastroParceriaAbaParceriaFormData({
                    ...data,
                    beneficios: !cadastroParceriaAbaParceriaFormData.beneficios
                      ? []
                      : cadastroParceriaAbaParceriaFormData.beneficios,
                  });
                }}
                handleGoForward={handleGoForward}
                handleValidateForm={(isValid) => setFormIsValid(isValid)}
              />
            ) : (
              <CadastroParceriaAbaBeneficios
                formDataParceriaParams={cadastroParceriaAbaParceriaFormData}
                formDataBeneficios={cadastroParceriaAbaBeneficiosFormData}
                onChangeFormData={(
                  data: CadastroParceriaAbaBeneficiosFormData
                ) => {
                  setCadastroParceriaAbaBeneficiosFormData(data);
                }}
                onLinkBenefit={(data: CadastroParceriaAbaParceriaFormData) => {
                  setCadastroParceriaAbaParceriaFormData(data);
                }}
              />
            )}
          </Card>
        </S.Content>
      </Main>
      <Footer />
    </>
  );
}
