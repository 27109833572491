import { useEffect, useState } from "react";
import { Form } from "@unform/web";
import axios from "axios";

import Button from "components/Button";
import closeModal from "images/icons/icon-close-modal.png";
import Select from "components/SelectDropdown";
import { ModalBase } from "../ModalBase";

import { manha, noite, tarde } from "data/horarios.json";

import {
  Container,
  CloseModal,
  SectionContent,
  ContainerButton,
  ContainerPeriodoHorario,
} from "./styles";

interface HomeFilterProps {
  onRequestClose: () => void;
  onContinue: (periodo: string, horario: string, servico: number) => void;
  defaultData: {
    filterHorario: string;
    filterPeriodo: string;
    filterServico: number;
  };
  isOpen: boolean;
}

interface Servico {
  id: number;
  codigo: string;
  servico: string;
  status: number;
}

export function HomeFilterModal({
  onRequestClose,
  onContinue,
  defaultData,
  isOpen,
}: HomeFilterProps) {
  const [servicos, setServicos] = useState<Servico[]>([]);

  const [periodo, setPeriodo] = useState(defaultData.filterPeriodo);
  const [horario, setHorario] = useState(defaultData.filterHorario);
  const [servico, setServico] = useState(defaultData.filterServico);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [dateSelec, setDateSelec] = useState("");

  useEffect(() => {
    axios.get<Servico[]>(`servicoData/`).then((response: any) => {
      if (response.data.result == "Success") {
        let servicos: Servico[] = [];
        response.data.items.map((item: any) => {
          let servico = {} as Servico;
          servico.id = item.id;
          servico.codigo = item.codProduto;
          servico.servico = item.nomeAbreviado;
          servicos.push(servico);
        });
        setServicos(servicos);
      }
    });
  }, []);

  function handleDateSelect(date: Date[] | undefined) {
    const today = new Date();

    let initialDate = date;

    if (!initialDate?.length) {
      initialDate = [
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ];
    }
    const formattedInitialDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[0]
    );
    const formattedFinalDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[1]
    );

    setDateSelec(formattedInitialDate + " até " + formattedFinalDate);
  }

  function obterHorariosPorPeriodo() {
    switch (periodo) {
      case "manha":
        return manha;
      case "tarde":
        return tarde;
      case "noite":
        return noite;
      default:
        return [...manha, ...tarde, ...noite];
    }
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <Container>
        <CloseModal onClick={onRequestClose}>
          <img src={closeModal} alt="Fechar Modal" />
        </CloseModal>

        <h2>Filtro</h2>

        <Form onSubmit={(data) => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <SectionContent>
            <ContainerPeriodoHorario>
              <div style={{ width: "100%" }}>
                <p>Período</p>
                <Select
                  name="periodo"
                  placeholder="Selecione o período"
                  newValue={{
                    label:
                      [
                        {
                          value: "manha",
                          label: "Manhã",
                        },
                        {
                          value: "tarde",
                          label: "Tarde",
                        },
                        {
                          value: "noite",
                          label: "Noite",
                        },
                      ].find((item) => item.value === periodo)?.label ||
                      "Selecione o período",
                    value: periodo ? periodo : "",
                  }}
                  options={[
                    {
                      value: "manha",
                      label: "Manhã",
                    },
                    {
                      value: "tarde",
                      label: "Tarde",
                    },
                    {
                      value: "noite",
                      label: "Noite",
                    },
                  ]}
                  onChange={(event: any) => { setPeriodo(event.value); setHorario('Selecione o horário') }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <p>Horário</p>
                <Select
                  name="horario"
                  placeholder="Selecione o horário"
                  newValue={{
                    label:
                      [
                        {
                          value: "todos",
                          label: "Todos os horários",
                        },
                        ...obterHorariosPorPeriodo(),
                      ].find((item) => item.label === horario)?.label ||
                      "Selecione o horário",
                    value: horario ? horario : "",
                  }}
                  options={[
                    {
                      value: "todos",
                      label: "Todos os horários",
                    },
                    ...obterHorariosPorPeriodo(),
                  ]}
                  onChange={(event: any) => setHorario(event.label)}
                />
              </div>
            </ContainerPeriodoHorario>
          </SectionContent>

          <SectionContent>
            <p>Serviços </p>
            <Select
              name="servicos"
              placeholder="Selecione o serviços"
              newValue={{
                label:
                  servicos.find((item) => item.id === servico)?.servico ||
                  "Selecione o serviços",
                value: servico ? servico : "",
              }}
              options={servicos.map((service) => ({
                value: service.id,
                label: service.servico,
              }))}
              onChange={(event: any) => setServico(event.value)}
            />
          </SectionContent>

          <ContainerButton>
            <Button onClick={onRequestClose} inverted>
              Cancelar
            </Button>
            <Button onClick={() => onContinue(periodo, horario, servico)}>
              Continuar
            </Button>
          </ContainerButton>
        </Form>
      </Container>
    </ModalBase>
  );
}
