import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header";
import Main from "../../components/Main";
import { Footer } from "../../components/Footer";
import DashboardUser from "../../components/DashboardUser";
import { Grid, StatusIconMapProps } from "../../components/Grid";
import { Badge } from "../../components/Badge";

import { Container, DashboardContainer, GridContainer } from "./styles";
import { NoResults } from "components/NoResults";

import { debounce } from "utils/debounce";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { useAuth } from "hooks/auth";
import { useLoader } from "hooks/loader";

import successImg from "images/arrowSuccess.png";
import warningImg from "images/arrowWarning.png";

interface Servico {
  id: number;
  codigo: string;
  servico: string;
  status: "active" | "inactive";
}

export function Servico() {
  const { permissoes } = useAuth();
  const { handleSetIsLoader } = useLoader();

  const [servicos, setServicos] = useState<Servico[]>([]);
  const [servicosFiltrados, setServicosFiltrados] = useState<Servico[]>([]);
  const [selectedServiceData, setSelectedServiceData] = useState({} as Servico);
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [
    isModalDeactivateServiceOpen,
    setIsModalDeactivateServiceOpen,
  ] = useState(false);
  const [isModalActivateServiceOpen, setIsModalActivateServiceOpen] = useState(
    false
  );
  const [
    isModalActivateDeactivateSuccessOpen,
    setIsModalActivateDeactivateSuccessOpen,
  ] = useState(false);

  const history = useHistory();

  const statusIconMap: StatusIconMapProps[] = [
    {
      status: "active",
      icon: <Badge type="active" text="Ativo" />,
    },
    {
      status: "inactive",
      icon: <Badge type="inactive" text="Inativo" />,
    },
  ];

  function loadServicos() {
    handleSetIsLoader(true);
    axios.get<Servico[]>(`servicoData/`).then((response: any) => {
      if (response.data.result === "Success") {
        let servicos: Servico[] = [];
        response.data.items.map((item: any) => {
          let servico = {} as Servico;
          servico.id = item.id;
          servico.codigo = item.codProduto;
          servico.servico = item.nomeAbreviado;
          servico.status = item.ativo ? "active" : "inactive";
          servicos.push(servico);
        });
        setServicos(servicos);
        handleSetIsLoader(false);
      }
      handleSetIsLoader(false);
    });
  }

  useEffect(() => {
    loadServicos();
  }, [setServicos]);

  useEffect(() => {
    if (filterText) {
      handleSetIsLoader(true);
      debounce(() => {
        const filterServicos = servicos.filter(
          (servico) =>
            servico.servico.toLowerCase().includes(filterText.toLowerCase()) ||
            String(servico.codigo).includes(filterText)
        );

        if (filterServicos) {
          setServicosFiltrados(filterServicos);
        }
      }, 500);
      handleSetIsLoader(false);
    } else {
      setServicosFiltrados([]);
    }
  }, [filterText, servicos]);

  function handleCreateNewService() {
    history.push("/portaladmin/register-service");
  }

  function ActionButtonModalContent({ data }: { data: Servico }) {
    return (
      <>
        <span>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.167 0.125H0.833333C0.44375 0.125 0.125 0.44375 0.125 0.833333V12.167C0.125 12.5562 0.44375 12.875 0.833333 12.875H12.167C12.5562 12.875 12.875 12.5562 12.875 12.167V0.833333C12.875 0.44375 12.5562 0.125 12.167 0.125ZM4.375 2.95833H2.95833V4.375H4.375V2.95833ZM9.33333 4.375H6.5C6.11042 4.375 5.79167 4.05625 5.79167 3.66667C5.79167 3.27708 6.11042 2.95833 6.5 2.95833H9.33333C9.72292 2.95833 10.0417 3.27708 10.0417 3.66667C10.0417 4.05625 9.72292 4.375 9.33333 4.375ZM9.33333 7.20833H6.5C6.11042 7.20833 5.79167 6.88958 5.79167 6.5C5.79167 6.11042 6.11042 5.79167 6.5 5.79167H9.33333C9.72292 5.79167 10.0417 6.11042 10.0417 6.5C10.0417 6.88958 9.72292 7.20833 9.33333 7.20833ZM6.5 10.0417H9.33333C9.72292 10.0417 10.0417 9.72292 10.0417 9.33333C10.0417 8.94375 9.72292 8.625 9.33333 8.625H6.5C6.11042 8.625 5.79167 8.94375 5.79167 9.33333C5.79167 9.72292 6.11042 10.0417 6.5 10.0417ZM2.95833 5.79167H4.375V7.20833H2.95833V5.79167ZM4.375 8.625H2.95833V10.0417H4.375V8.625ZM1.54167 11.4583H11.4583V1.54167H1.54167V11.4583Z"
              fill="#6B7C8E"
            />
          </svg>
          <button
            type="button"
            onClick={() =>
              history.push(`/portaladmin/detail-service/?id=${data?.id}`)
            }
          >
            Ver detalhes
          </button>
        </span>
        {data?.status === "inactive" && permissoes?.filter((item: any) => item.route[2] === "register-service")
          .length > 0 && (
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0003 1.66667C5.40033 1.66667 1.66699 5.40001 1.66699 10C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 10C18.3337 5.40001 14.6003 1.66667 10.0003 1.66667ZM10.0003 16.6667C6.32533 16.6667 3.33366 13.675 3.33366 10C3.33366 6.32501 6.32533 3.33334 10.0003 3.33334C13.6753 3.33334 16.667 6.32501 16.667 10C16.667 13.675 13.6753 16.6667 10.0003 16.6667ZM8.33366 11.8083L13.2337 6.90833C13.5587 6.58333 14.092 6.58333 14.417 6.90833C14.742 7.23333 14.742 7.75833 14.417 8.08333L8.92533 13.575C8.60033 13.9 8.07533 13.9 7.75033 13.575L5.59199 11.4167C5.26699 11.0917 5.26699 10.5667 5.59199 10.2417C5.74768 10.0856 5.95906 9.99792 6.17949 9.99792C6.39992 9.99792 6.6113 10.0856 6.76699 10.2417L8.33366 11.8083Z"
                  style={{
                    fill: "#237B4B",
                  }}
                />
              </svg>

              <button
                type="button"
                onClick={() => {
                  setSelectedServiceData(data);
                  setIsModalActivateServiceOpen(true);
                }}
                style={{
                  color: "#237B4B"
                }}
              >
                Ativar serviço
            </button>
            </span>
          )}
        {data?.status === "active" && permissoes?.filter((item: any) => item.route[2] === "register-service")
            .length > 0 && (
          <span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00008 0.666668C4.39175 0.666668 0.666748 4.39167 0.666748 9C0.666748 13.6083 4.39175 17.3333 9.00008 17.3333C13.6084 17.3333 17.3334 13.6083 17.3334 9C17.3334 4.39167 13.6084 0.666668 9.00008 0.666668ZM10.5751 6.25001L9.00008 7.82501L7.42508 6.25001C7.26939 6.09397 7.05801 6.00627 6.83758 6.00627C6.61715 6.00627 6.40577 6.09397 6.25008 6.25001C5.92508 6.57501 5.92508 7.10001 6.25008 7.42501L7.82508 9.00001L6.25008 10.575C5.92508 10.9 5.92508 11.425 6.25008 11.75C6.57508 12.075 7.10008 12.075 7.42508 11.75L9.00008 10.175L10.5751 11.75C10.9001 12.075 11.4251 12.075 11.7501 11.75C12.0751 11.425 12.0751 10.9 11.7501 10.575L10.1751 9.00001L11.7501 7.42501C12.0751 7.10001 12.0751 6.57501 11.7501 6.25001C11.4251 5.93334 10.8917 5.93334 10.5751 6.25001ZM2.33341 9C2.33341 12.675 5.32508 15.6667 9.00008 15.6667C12.6751 15.6667 15.6667 12.675 15.6667 9C15.6667 5.325 12.6751 2.33333 9.00008 2.33333C5.32508 2.33333 2.33341 5.325 2.33341 9Z"
                style={{
                  fill: "#F14A50"
                }}
              />
            </svg>
            <button
              type="button"
              onClick={() => {
                setSelectedServiceData(data);
                setIsModalDeactivateServiceOpen(true);
              }}
              style={{
                color: "#F14A50",
              }}
            >
              Desativar serviço
            </button>
          </span>
        )}
      </>
    );
  }

  function activeService(id: number) {
    handleSetIsLoader(true);
    fetch("/ServicoData/Active/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          handleSetIsLoader(false);
          alert(data.message);
        } else if (data.result === "Success") {
          handleSetIsLoader(false);
          setIsModalActivateServiceOpen(false);
          setIsModalActivateDeactivateSuccessOpen(true);

          loadServicos();
        } else {
          handleSetIsLoader(false)
          alert("Erro back");
        }
      })
      .catch((error) => {
        handleSetIsLoader(false)
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function desactiveService(id: number) {
    handleSetIsLoader(true);
    fetch("/ServicoData/Desactive/" + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          handleSetIsLoader(false);
          alert(data.message);
        } else if (data.result === "Success") {
          handleSetIsLoader(false);
          setIsModalDeactivateServiceOpen(false);
          setIsModalActivateDeactivateSuccessOpen(true);

          loadServicos();
        } else {
          handleSetIsLoader(false);
          alert("Erro back");
        }
      })
      .catch((error) => {
        handleSetIsLoader(false);
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  return (
    <>
      <Header />
      <Main>
        <Container>
          <DashboardContainer>
            <DashboardUser />
            {permissoes?.filter((item: any) => item.route[2] === "register-service")
              .length > 0 && (
                <button type="button" onClick={handleCreateNewService}>
                  Cadastrar novo serviço
                </button>
              )}
          </DashboardContainer>
          <GridContainer>
            <div className="gridHeader">
              <div>
                <h2>GERENCIAMENTO DE SERVIÇOS</h2>
                {/*<p>Último dia de atualização 13/01/2021</p>*/}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Busca por nome ou código"
                  onChange={(event) => setFilterText(event.target.value)}
                  value={filterText}
                />
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9624 11.4716H12.7629L17.0595 15.7316C17.4749 16.1416 17.4749 16.8116 17.0595 17.2216C16.644 17.6316 15.9651 17.6316 15.5496 17.2216L11.2429 12.9716V12.1816L10.9693 11.9016C9.55068 13.1016 7.61521 13.7216 5.55814 13.3816C2.74108 12.9116 0.491476 10.5916 0.146943 7.79157C-0.379991 3.56157 3.22748 0.00157288 7.51388 0.521573C10.3512 0.861573 12.7021 3.08157 13.1784 5.86157C13.5229 7.89157 12.8947 9.80157 11.6787 11.2016L11.9624 11.4716ZM2.12294 6.97157C2.12294 9.46157 4.15974 11.4716 6.68294 11.4716C9.20615 11.4716 11.2429 9.46157 11.2429 6.97157C11.2429 4.48157 9.20615 2.47157 6.68294 2.47157C4.15974 2.47157 2.12294 4.48157 2.12294 6.97157Z"
                    fill="#7B8FA5"
                  />
                </svg>
              </div>
            </div>
            {((!!servicos.length && !filterText) ||
              !!servicosFiltrados.length) &&
              (
                <>
                  <Grid
                    columns={["Codigo", "Serviço", "Status"]}
                    columnsWidth={[
                      { column: "Codigo", width: "82px" },
                      { column: "Serviço", width: "636px" },
                      { column: "Status", width: "90px" },
                      { column: "Action", width: "115px" },
                    ]}
                    omit={["id"]}
                    maxHeight="560px"
                    customStyle={{
                      width: "923px",
                    }}
                    data={filterText ? servicosFiltrados : servicos}
                    statusIconMap={statusIconMap}
                    actionButtonModalContent={
                      <ActionButtonModalContent data={{} as Servico} />
                    }
                    enableActionButton
                  />
                </>
              )}
            {
              (!servicosFiltrados.length && !!filterText) &&
              (
                <NoResults
                  title="Busca não encontrada!"
                  message="Verifique se o código esta correto ou nome do serviço desejado."
                  colorTitle="#2A333E"
                  colorMessage="#404F61"
                  sizeTitle="22px"
                  sizeMessage="14px"
                />
              )}
          </GridContainer>
        </Container>
      </Main>
      <Footer />

      <SimpleModal
        isOpen={isModalActivateServiceOpen}
        onRequestClose={() => setIsModalActivateServiceOpen(false)}
        confirm={() => {
          setIsModalActivateServiceOpen(false);
          activeService(selectedServiceData.id);
        }}
        showCancel
        icon={warningImg}
        title="Atenção"
        message={
          <>
            Deseja{" "}
            <strong>
              {selectedServiceData?.status === "inactive"
                ? "Ativar"
                : "Desativar"}
            </strong>{" "}
            o serviço de
            <br />
            <strong>
              {selectedServiceData?.codigo} - {selectedServiceData?.servico}”,
            </strong>
            <br />
            ficando assim{" "}
            {selectedServiceData?.status === "inactive"
              ? "ativo"
              : "desativado"}{" "}
            em todas lojas?
          </>
        }
      />

      <SimpleModal
        isOpen={isModalDeactivateServiceOpen}
        onRequestClose={() => setIsModalDeactivateServiceOpen(false)}
        confirm={() => {
          setIsModalDeactivateServiceOpen(false);
          desactiveService(selectedServiceData.id);
        }}
        showCancel
        icon={warningImg}
        title="Atenção"
        message={
          <>
            Deseja{" "}
            <strong>
              {selectedServiceData?.status === "inactive"
                ? "Ativar"
                : "Desativar"}
            </strong>{" "}
            o serviço de
            <br />
            <strong>
              {selectedServiceData?.codigo} - {selectedServiceData?.servico}”,
            </strong>
            <br />
            ficando assim{" "}
            {selectedServiceData?.status === "inactive"
              ? "ativo"
              : "desativado"}{" "}
            em todas lojas?
          </>
        }
      />

      <SimpleModal
        isOpen={isModalActivateDeactivateSuccessOpen}
        onRequestClose={() => {
          loadServicos();
          setIsModalActivateDeactivateSuccessOpen(false);
        }}
        confirm={() => {
          setIsModalActivateDeactivateSuccessOpen(false);
          loadServicos();
        }}
        icon={successImg}
        title="Sucesso"
        message={
          <>
            Operação realizada, o serviço
            <br />
            <strong>
              “{selectedServiceData.codigo} - {selectedServiceData.servico}”
            </strong>
            <br />
            foi{" "}
            <strong>
              {selectedServiceData.status === "inactive"
                ? "ativado"
                : "desativado"}
            </strong>{" "}
            em todas lojas.
          </>
        }
        continueButtonText="Fechar"
        buttonsMarginTop="12.54px"
      />
    </>
  );
}
