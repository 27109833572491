import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { Input } from "components/Input";
import Button from "components/Button";
import SelectDropdown from "components/SelectDropdown";
import { Tab } from "components/Tab";
import { useLoader } from "hooks/loader";

import { DashboardContainer, Content } from "./styles";

import IconUpdate from "images/icons/icon-update.svg";
import IconAtencao from "images/icons/icon-atencao.svg";
import IconSucess from "images/icons/icon-sucess.svg";
import IconError from "images/iconError.png";
import { cpfMask, validateCpf } from "components/ValidationCpf";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import loadingImg from "images/icons/loading-register.svg";
import { ClientesAbaConsultar } from "components/ClientesAbaConsultar";
import { colors } from "tokens";

function Vincular() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5002 10.0002C14.3418 10.0002 15.8335 8.5085 15.8335 6.66683C15.8335 4.82516 14.3418 3.3335 12.5002 3.3335C10.6585 3.3335 9.16683 4.82516 9.16683 6.66683C9.16683 8.5085 10.6585 10.0002 12.5002 10.0002ZM5.00016 8.3335V6.66683C5.00016 6.2085 4.62516 5.8335 4.16683 5.8335C3.7085 5.8335 3.3335 6.2085 3.3335 6.66683V8.3335H1.66683C1.2085 8.3335 0.833496 8.7085 0.833496 9.16683C0.833496 9.62516 1.2085 10.0002 1.66683 10.0002H3.3335V11.6668C3.3335 12.1252 3.7085 12.5002 4.16683 12.5002C4.62516 12.5002 5.00016 12.1252 5.00016 11.6668V10.0002H6.66683C7.12516 10.0002 7.50016 9.62516 7.50016 9.16683C7.50016 8.7085 7.12516 8.3335 6.66683 8.3335H5.00016ZM5.8335 15.0002C5.8335 12.7835 10.2752 11.6668 12.5002 11.6668C14.7252 11.6668 19.1668 12.7835 19.1668 15.0002V15.8335C19.1668 16.2918 18.7918 16.6668 18.3335 16.6668H6.66683C6.2085 16.6668 5.8335 16.2918 5.8335 15.8335V15.0002Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

function MessageError(message: string, width: string) {
  return (
    <div
      style={{
        width: width,
        height: "32px",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        background: "#FFE4E4",
        marginTop: "8px",
      }}
    >
      <img
        src={IconAtencao}
        alt="atencao"
        style={{
          width: "16px",
          height: "16px",
          marginRight: "4px",
        }}
      />
      <span
        style={{
          color: "#D14A4A",
          fontSize: "14px",
          fontFamily: "Source Sans Pro",
        }}
      >
        {message}
      </span>
    </div>
  );
}

function Consulta() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6553 12.0598H13.3137L16.847 15.6098C17.1887 15.9514 17.1887 16.5098 16.847 16.8514C16.5053 17.1931 15.947 17.1931 15.6053 16.8514L12.0637 13.3098V12.6514L11.8387 12.4181C10.672 13.4181 9.08034 13.9348 7.38867 13.6514C5.07201 13.2598 3.22201 11.3264 2.93867 8.9931C2.50534 5.4681 5.47201 2.50143 8.99701 2.93477C11.3303 3.2181 13.2637 5.0681 13.6553 7.38477C13.9387 9.07643 13.422 10.6681 12.422 11.8348L12.6553 12.0598ZM4.56367 8.30977C4.56367 10.3848 6.23867 12.0598 8.31367 12.0598C10.3887 12.0598 12.0637 10.3848 12.0637 8.30977C12.0637 6.23477 10.3887 4.55977 8.31367 4.55977C6.23867 4.55977 4.56367 6.23477 4.56367 8.30977Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

interface ParceiroProps {
  id: number;
  parcerias: string;
  classificacao: string;
  inicio: string;
  termino: string;
  status: "active" | "inactive";
}

interface ParceriaProps {
  value: string;
  label: string;
}

interface FormData {
  nome: string;
  sexo: string;
  dataNascimento: string;
  telefone: string;
  email: string;
  cpf: string;
  vincularParceria: number;
}

export function Clientes() {
  const { handleSetIsLoader } = useLoader();
  const [activeTab, setActiveTab] =
    useState<"vincular" | "consulta">("vincular");
  const [isEmpty, setIsEmpty] = useState("");
  const [cpfIsInvalid, setCpfIsInvalid] = useState(false);
  const [cpfNotFound, setCpfNotFound] = useState(false);
  const [openButtonUpdate, setOpenButtonUpdate] = useState(false);
  const [openButtonRegister, setOpenButtonRegister] = useState(false);
  const [openModalSucess, setOpenModalSucess] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  const [isNome, setIsNome] = useState(false);
  const [isGenero, setIsGenero] = useState(false);
  const [isDtNascimento, setIsDtNascimento] = useState(false);
  const [isTelefone, setIsTelefone] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const formRef = useRef<FormHandles>(null);

  function handleMaskCpf(e: React.ChangeEvent<HTMLInputElement>) {
    if (cpfIsInvalid || cpfNotFound) {
      setCpfIsInvalid(false);
      setCpfNotFound(false);
    }
    setIsEmpty(cpfMask(e.target.value));
    setOpenButtonRegister(false);
    setOpenButtonUpdate(false);
  }

  const [parcerias, setParcerias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enableLinkButton, setEnableLinkButton] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updated, setUpdated] = useState(true);

  const [servicoVincular, setServicoVincular] = useState("");

  useEffect(() => {
    loadParcerias();
  }, []);

  useEffect(() => {
    (async () => await validateForm(formRef.current?.getData() as FormData))();
  }, [servicoVincular]);

  async function loadParcerias() {
    try {
      const response = await axios.get("/BeneficioData/GetAllParceriaActive");
      setParcerias(response.data.items ?? []);
    } catch (error) {
      console.error(error);
    }
  }

  async function getClienteData() {
    try {
      setLoading(true);
      handleSetIsLoader(true);
      const cpf = formRef.current?.getFieldValue("cpf");
      setOpenButtonRegister(false);
      setOpenButtonUpdate(false);
      setIsNome(false);
      setIsGenero(false);
      setIsDtNascimento(false);
      setIsTelefone(false);
      setIsEmail(false);
      setCpfIsInvalid(false);
      setCpfNotFound(false);
      formRef.current?.reset();

      if (!validateCpf(cpf)) {
        setCpfIsInvalid(true);
        setOpenButtonRegister(true);
        return;
      }

      const response = await axios.get(`clienteData/GetCliente/cpf/${cpf}`);
      console.log(response.data);
      if (response.data.result === "Error") {

        if (response.data.code === "notFound") {
          setOpenButtonRegister(true);
          setCpfNotFound(true);
          return;
        } else {
          setIsModalErrorOpen(true);
        }
        return;
      }

      const { nome, sexo, dataNascimento, telefone, email } =
        response.data.clienteData;

      if (!nome) {
        setIsNome(true);
        setOpenButtonUpdate(true);
      }
      if (!sexo) {
        setIsGenero(true);
        setOpenButtonUpdate(true);
      }
      if (!dataNascimento) {
        setIsDtNascimento(true);
        setOpenButtonUpdate(true);
      }
      if (!telefone) {
        setIsTelefone(true);
        setOpenButtonUpdate(true);
      }
      if (!email) {
        setIsEmail(true);
        setOpenButtonUpdate(true);
      }

      const formData = { nome, sexo, dataNascimento, telefone, email };
      formRef.current?.setData(formData);

      setUpdated(true);
      setUpdating(false);

      // await validateForm(formData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      handleSetIsLoader(false);
    }
  }

  async function validateForm(data: FormData) {
    setEnableLinkButton(false);

    const schema = Yup.object().shape({
      nome: Yup.string().required(),
      sexo: Yup.string().required(),
      dataNascimento: Yup.string().required(),
      vincularParceria: Yup.number().required(),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    setEnableLinkButton(true);
  }

  async function handleSubmit(data: FormData) {
    try {
      handleSetIsLoader(true)
      console.log("data: ", data);

      const response = await axios.post("/BeneficioData/AddCliente", {
        idParceria: data.vincularParceria,
        cpf: data.cpf,
        nome: data.nome,
      });

      // Remove all previous errors
      formRef.current?.setErrors({});
      setIsEmail(false);
      await validateForm(data);
      setOpenModalSucess(true);
      formRef.current?.reset();
      setServicoVincular("");
      handleSetIsLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleEditData() {
    setUpdated(false);

    const { data } = await axios.get("/ClienteData/LinkDadosCliente");
    handleSetIsLoader(false);

    window.open(data.linkDadosCliente, "_blank");
  }

  return (
    <>
      <Header />
      <Main>
        <DashboardContainer>
          <DashboardUser />
        </DashboardContainer>
        <Content>
          <h2>CLIENTES</h2>
          <div className="contentBody">
            <div className="tabHeader">
              <Tab
                title="Vincular"
                selected={activeTab === "vincular"}
                icon={<Vincular />}
                onClick={() => setActiveTab("vincular")}
              />
              <Tab
                title="Consultar"
                selected={activeTab === "consulta"}
                icon={<Consulta />}
                onClick={() => setActiveTab("consulta")}
              />
            </div>
            {activeTab === "vincular" ? (
              <Form ref={formRef} onSubmit={(data) => handleSubmit(data)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className="form">
                  <div className="formRow">
                    <div id="required" className="cpf">
                      <div>
                        <Input
                          name="cpf"
                          label="CPF"
                          style={{
                            width: "253px",
                            height: "40px",
                            marginRight: "8px",
                            borderColor:
                              cpfIsInvalid || cpfNotFound ? "#D14A4A" : "",
                          }}
                          value={isEmpty}
                          placeholder="000.000.000-00"
                          onChange={(event) => {
                            handleMaskCpf(event);
                            formRef.current?.reset();
                          }}
                        />
                        {cpfIsInvalid && MessageError("CPF inválido", "256px")}
                        {cpfNotFound &&
                          MessageError("CPF não cadastrado", "256px")}
                      </div>
                      <Button
                        type="button"
                        inverted
                        width="125px"
                        style={{
                          fontFamily: "Source Sans Pro",
                          fontSize: "14px",
                          height: "40px",
                          padding: "0",
                          alignItems: "center",
                          marginTop: "32px",
                        }}
                        onClick={() => getClienteData()}
                      >
                        {loading ? "Carregando..." : "Buscar"}
                      </Button>
                    </div>
                  </div>
                  {!updated && (
                    <span
                      className="atualizarDados"
                      onClick={() => {
                        setUpdating(true);
                        getClienteData();
                      }}
                    >
                      <img src={IconUpdate} alt="update" />
                      <strong>Atualizar dados</strong>
                    </span>
                  )}
                  <div id="required" className="formRow">
                    <div>
                      <Input
                        name="nome"
                        label="Nome"
                        placeholder="Nome"
                        style={{
                          width: "419px",
                          marginRight: "24px",
                          background: "#D7E2ED",
                          borderColor: isNome ? "#D14A4A" : "",
                        }}
                        disabled
                      />
                      {isNome && MessageError("Nome não encontrado", "419px")}
                    </div>

                    <div>
                      <Input
                        name="sexo"
                        label="Gênero"
                        placeholder="-"
                        style={{
                          width: "418px",
                          background: "#D7E2ED",
                          borderColor: isGenero ? "#D14A4A" : "",
                        }}
                        disabled
                      />
                      {isGenero &&
                        MessageError("Gênero não encontrado", "418px")}
                    </div>
                  </div>
                  <div className="formRow">
                    <div id="required">
                      <Input
                        name="dataNascimento"
                        label="Data de nascimento"
                        placeholder="00/00/0000"
                        style={{
                          width: "419px",
                          marginRight: "24px",
                          background: "#D7E2ED",
                          borderColor: isDtNascimento ? "#D14A4A" : "",
                        }}
                        disabled
                      />
                      {isDtNascimento &&
                        MessageError(
                          "Data de nascimento não encontrado",
                          "419px"
                        )}
                    </div>
                    <div>
                      <Input
                        name="telefone"
                        label="Telefone"
                        placeholder="(00)00000-0000"
                        style={{
                          width: "418px",
                          background: "#D7E2ED",
                          borderColor: isTelefone ? "#D14A4A" : "",
                        }}
                        disabled
                      />
                      {isTelefone &&
                        MessageError("Telefone não encontrado", "418px")}
                    </div>
                  </div>
                  <div className="formRow">
                    <div>
                      <Input
                        name="email"
                        label="Email"
                        placeholder="-"
                        style={{
                          width: "419px",
                          marginRight: "24px",
                          background: "#D7E2ED",
                          borderColor: isEmail ? "#D14A4A" : "",
                        }}
                        disabled
                      />
                      {isEmail && MessageError("Email não encontrado", "419px")}
                    </div>
                    <div id="required" className="vincularParceria">
                      <label>Vincular a parceria</label>
                      <SelectDropdown
                        name="vincularParceria"
                        placeholder="Selecione"
                        isSearchable
                        newValue={{
                          value: servicoVincular,
                          label:
                            parcerias
                              ?.map((item: any) => {
                                return {
                                  value: item.id,
                                  label: item.nome,
                                };
                              })
                              .find(
                                (parceria) => parceria.value === servicoVincular
                              )?.label || "Selecione",
                        }}
                        options={parcerias?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.nome,
                          };
                        })}
                        style={{
                          width: "418px",
                          background: "#D7E2ED",
                        }}
                        onChange={(event: any) =>
                          setServicoVincular(event.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="contentFooter">
                    {openButtonUpdate && (
                      <Button
                        inverted
                        onClick={handleEditData}
                        width="150px"
                        style={{
                          fontFamily: "Source Sans Pro",
                        }}
                      >
                        Editar dados
                      </Button>
                    )}
                    {openButtonRegister && (
                      <Button
                        inverted
                        onClick={handleEditData}
                        width="150px"
                        style={{
                          fontFamily: "Source Sans Pro",
                        }}
                      >
                        Cadastrar
                      </Button>
                    )}
                    <Button
                      disabled={!enableLinkButton}
                      type="submit"
                      width="150px"
                      style={{
                        fontFamily: "Source Sans Pro",
                      }}
                    >
                      Vincular
                    </Button>
                  </div>
                  {openButtonUpdate && (
                    <div className="errorMessage">
                      <span>
                        Para prosseguir é preciso ter todos os campos
                        preenchidos, clique em editar dados para completar o
                        cadastro do cliente.
                      </span>
                    </div>
                  )}
                  {openButtonRegister && (
                    <div className="errorMessage">
                      <span>Para prosseguir, cadastre o cpf informado.</span>
                    </div>
                  )}
                </div>
              </Form>
            ) : (
              <div>
                <ClientesAbaConsultar />
              </div>
            )}
          </div>
        </Content>
      </Main>
      <Footer />

      <SimpleModal
        isOpen={updating}
        title="Atualizando página..."
        message=""
        hideButtons
        icon={loadingImg}
        onRequestClose={() => { }}
        confirm={() => { }}
      />

      <SimpleModal
        isOpen={openModalSucess}
        onRequestClose={() => setOpenModalSucess(false)}
        title="Sucesso"
        message={`O CPF ${isEmpty} foi vinculado com sucesso.`}
        icon={IconSucess}
        confirm={() => {
          setOpenModalSucess(false);
          setIsEmpty("");
        }}
        buttonsMarginTop="21px"
        marginTitle="16px"
        continueButtonText="Fechar"
        widthButtonContinue="110px"
      />

      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        title="Erro"
        message="Erro ao pesquisar cliente."
        icon={IconError}
        confirm={() => {
          setIsModalErrorOpen(false);
        }}
        continueButtonText="Fechar"
      />
    </>
  );
}
