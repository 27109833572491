import styled, { css } from "styled-components";
import { colors } from "tokens";

interface ContainerProps {
  numberOfColumns: number;
  minHeight: string;
}

export const Container = styled.div<ContainerProps>`
  min-height: ${({ minHeight }) => minHeight};
  overflow-x: auto;
`;

interface TableContainerProps {
  maxHeight: string;
  minHeight: string;
}

export const TableContainer = styled.div<TableContainerProps>`
  min-height: ${({ minHeight }) => minHeight};
  width: fit-content;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;
  display: contents;
  overflow-x: clip;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }
`;

export const Table = styled.table`
  // display: contents;
  position: relative;
  width: 100%;
  border-right: 1px solid #aec0d2;
  border-left: 1px solid #aec0d2;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }

  thead, thead tr th {
    background: ${colors.gray.light};
  }
`;

export const TableRowHeader = styled.tr`
  width: 100%;

  th {
    height: 40px;
    padding: 0 12px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.dark};
    position: sticky;
    top: 0;
    z-index: 9;
    white-space: nowrap;
    vertical-align: middle;

    svg {
      margin-left: 8px;

      &:hover {
        cursor: pointer;

        path {
          fill: ${colors.blue};
        }
      }
    }

    > button {
      width: fit-content;
      height: 28px;
      background: transparent;
      border: 1px solid ${colors.blue};
      border-radius: 4px;
      padding: 4px 22.5px;

      display: flex;
      align-items: center;

      p {
        color: ${colors.blue} !important;
        margin: 0 !important;
      }

      img {
        margin-left: 8px;
      }
    }

    &:first-child {
      border-radius: 4px 0 0 0;
    }

    &:last-child {
      border-radius: 0 4px 0 0;
    }
  }
`;

interface TableRowProps {
  disableRow: boolean;
  emptyRow: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
  position: relative;

  td {
    position: relative;
    height: 45px;
    max-width: 331px;
    padding: 0 12px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: ${colors.dark};
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    border-left: 1px solid #aec0d2;
    border-bottom: 1px solid #aec0d2;
    ${({ disableRow }) =>
      disableRow &&
      css`
        background: #e1e1e1;
        color: #c4c4c4;
      `}

    &.actionButtonColumn {
      background: ${colors.white};
    }

    p {
      margin: 0;
    }

    div {
      position: relative;

      > button {
        width: 75px;
        height: 28px;
        background: transparent;
        border: 1px solid ${colors.blue};
        border-radius: 4px;
        color: ${colors.blue};
        padding: 4px 8px;

        display: flex;
        align-items: center;

        p {
          margin: 0;
        }

        img {
          margin-left: 4px;
        }
      }
    }

    span {
      &:hover {
        svg path {
          fill: ${colors.blue};
        }

        button {
          color: ${colors.blue};
          font-weight: 600;
        }
      }

      svg {
        margin-right: 8px;
      }

      button {
        background: ${colors.white};
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        color: #6b7c8e;
      }

      & + span {
        margin-top: 8px;
      }
    }
  }

  ${({ emptyRow }) =>
    emptyRow &&
    css`
      td {
        color: ${colors.gray.blue};
      }
    `}
`;

export const ActionButtonModal = styled.div`
  z-index: 15;
  position: absolute !important;
  min-width: 200px;
  left: -115px;
  top: 32px;

  border: 1px solid ${colors.gray.light};
  border-radius: 4px;
  background: ${colors.white};

  padding: 16px 12px 14px;

  display: flex;
  flex-direction: column;

  > span {
    &:hover {
      svg path {
        fill: ${colors.blue};
      }

      button {
        color: ${colors.blue};
        font-weight: 600;
      }
    }

    svg {
      margin-right: 8px;
    }

    button {
      background: ${colors.white};
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      color: #6b7c8e;
    }

    & + span {
      margin-top: 8px;
    }
  }
`;

export const ActionButtonHeaderModal = styled.div`
  z-index: 1;
  position: absolute !important;
  min-width: 200px;
  left: -80px;
  top: 36px;

  border: 1px solid ${colors.gray.light};
  border-radius: 4px;
  background: ${colors.white};

  padding: 16px 12px 14px;

  display: flex;
  flex-direction: column;

  > span {
    &:hover {
      button {
        color: ${colors.blue};
        font-weight: 600;
      }
    }

    img {
      margin-right: 8px;
    }

    button {
      background: ${colors.white};
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      color: #6b7c8e;
    }

    & + span {
      margin-top: 8px;
    }
  }
`;

export const Subtitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 14px;
  align-items: flex-start;
  min-height: 32px;

  div {
    display: flex;

    p {
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: ${colors.gray.text};
    }

    span {
      display: flex;
      align-items: flex-end;
      margin-left: 5px;

      p {
        margin: 0;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
        font-size: 10px;
        color: ${colors.gray.text};
      }

      img {
        margin-left: 2.5px;
        width: 12px;
        height: 12px;
        align-self: center;
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%); */
`;
