import { Divider } from 'primereact/divider'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { partnerService } from 'services/partnerService'
import { Stats } from '../Stats'

const Resume = forwardRef((props: any, ref: any) => {
  const childNavRef = useRef()
  const [loading, setLoading] = useState(false)
  var dataFirst = props.state?.form?.dadosapi?.[0] || props.state?.infoPartner
  var dataStores = props.state?.form?.lojas
  var dataStoresUnlink = props.state?.form?.lojasDesvinculadas
  var dataServices = props.state?.form?.servicos
  var dataServicesUnlink = props.state?.form?.servicosDesvinculados
  var datafilled = props.state?.form?.dadospreenchidos

  const handleSave = () => {
    setLoading(true)

    const data = {
      razaoSocial: dataFirst.razaoSocial,
      nomeFantasia: dataFirst.nomeFantasia,
      cnpj: dataFirst.cnpj,
      status: datafilled.status === 'Ativo',
      nomeResponsavel: datafilled.nomeResponsavel,
      emailResponsavel: props.state?.form?.email,
      telefoneResponsavel: datafilled.telefoneResponsavel,
      emailSac: datafilled.emailSac,
      telefoneSac: datafilled.telefoneSac,
      idTipoParceiro: Number(datafilled.tipoParceiro.key),
      lojas: dataStores.map((item: any) => ({
        idLojaSala: item.code.split('|')[1]
      })),
      lojasDesvinculadas: dataStoresUnlink.map((item: any) => ({
        idLojaSala: item.code.split('|')[1]
      })),
      servicos: dataServices.map((item: any) => ({ id: item.code })),
      servicosDesvinculados: dataServicesUnlink.map((item: any) => ({
        id: item.code
      }))
    }

    partnerService
      .save(data, props.state?.infoPartner?.idParceiro)
      .then(data => {
        props.setHeaderDialog('Confirmação')
        props.setMessage(
          `Parceiro ${
            props.state?.infoPartner?.idParceiro ? 'atualizado' : 'cadastrado'
          } com sucesso.`
        )
      })
      .catch(error => {
        console.error(error)
        props.setMessage(
          error.response?.data['message'] || error.response?.data?.message
        )
      })
      .finally(() => setLoading(false))
  }

  useImperativeHandle(ref, () => ({
    handleNextStepNav() {
      props.nextStep()
    }
  }))
  return (
    <div className="resume-step">
      <Divider align="center">
        <h3>Resumo do cadastro do parceiro</h3>
      </Divider>
      <div className="p-fluid">
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Email principal</label>
          </div>
          <div className="p-col-12">
            <label>{props.state?.form?.email}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>CNPJ</label>
          </div>
          <div className="p-col-12">
            <label>{dataFirst?.cnpj}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Razão Social</label>
          </div>
          <div className="p-col-12">
            <label>{dataFirst?.razaoSocial}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Nome Fantasia</label>
          </div>
          <div className="p-col-12">
            <label>{dataFirst?.nomeFantasia}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Tipo do parceiro</label>
          </div>
          <div className="p-col-12">
            <label>
              {datafilled?.tipoParceiro?.value ||
                dataFirst?.tipoParceiro?.value}
            </label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Status do parceiro</label>
          </div>
          <div className="p-col-12">
            <label>{datafilled?.status}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Nome do Responsável</label>
          </div>
          <div className="p-col-12">
            <label>{datafilled?.nomeResponsavel}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Email do Responsável</label>
          </div>
          <div className="p-col-12">
            <label>{props.state?.form?.email}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Telefone do Responsável</label>
          </div>
          <div className="p-col-12">
            <label>{datafilled?.telefoneResponsavel}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Email do SAC</label>
          </div>
          <div className="p-col-12">
            <label>{datafilled?.emailSac}</label>
          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-col-12 content-label">
            <label>Telefone do SAC</label>
          </div>
          <div className="p-col-12">
            <label>{datafilled?.telefoneSac}</label>
          </div>
        </div>
        <div className="content-loja">
          <Divider align="center">
            <h3>Lojas</h3>
          </Divider>
          <div className="p-field p-grid grid-resumo">
            {dataStores?.map((item: any) => (
              <div className="p-col-12 p-md-6">
                <div className="p-col-12 content-label">
                  <label>Nome:</label>
                </div>
                <div className="p-col-12">
                  <label>{item?.name}</label>
                </div>
              </div>
            ))}
          </div>
          <Divider align="center">
            <h3>Lojas desvinculadas</h3>
          </Divider>
          <div className="p-field p-grid grid-resumo">
            {dataStoresUnlink?.length == 0 ? (
              <div className="p-col-12 p-md-12">
                <p className="p-text-center">Nenhuma loja foi desvinculada</p>
              </div>
            ) : (
              dataStoresUnlink?.map((item: any) => (
                <div className="p-col-12 p-md-6">
                  <div className="p-col-12 content-label">
                    <label>Nome:</label>
                  </div>
                  <div className="p-col-12">
                    <label>{item.name}</label>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="content-loja">
          <Divider align="center">
            <h3>Serviços</h3>
          </Divider>
          <div className="p-field p-grid grid-resumo">
            {dataServices?.map((item: any) => (
              <div className="p-col-12 p-md-6">
                <div className="p-col-12 content-label">
                  <label>Nome:</label>
                </div>
                <div className="p-col-12">
                  <label>{item.name}</label>
                </div>
              </div>
            ))}
          </div>
          <Divider align="center">
            <h3>Serviços desvinculados</h3>
          </Divider>
          <div className="p-field p-grid grid-resumo">
            {dataServicesUnlink?.length == 0 ? (
              <div className="p-col-12 p-md-12">
                <p className="p-text-center">Nenhum serviço foi desvinculado</p>
              </div>
            ) : (
              dataServicesUnlink?.map((item: any) => (
                <div className="p-col-12 p-md-6">
                  <div className="p-col-12 content-label">
                    <label>Nome:</label>
                  </div>
                  <div className="p-col-12">
                    <label>{item.name}</label>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Stats
        step={props.currentStep}
        {...props}
        handleSave={handleSave}
        loading={loading}
        ref={childNavRef}
      />
    </div>
  )
})

export default Resume
