import "./schedulePeriod.scss";
import { ReactComponent as RemoveIcon } from "../../../../../images/icons/delete-sign.svg";
import Swal from "sweetalert2";
import { partnerScheduleControlService } from "services/partnerScheduleControlService";
import { toast } from "react-toastify";
import { usePartnerContext } from "contexts/partnerContextProvider";

export default function SchedulePeriod({ periodName, dayPeriod }) {
  const {
    podeAlterar,
    horaInicio,
    horaFim,
    idAgendaLojaSalaConfig,
    IdParceiro,
    CodigoLoja,
  } = dayPeriod;
  const { setIsLoading } = usePartnerContext();

  const handleDeletePeriod = () => {
    Swal.fire({
      title: `Remover período ${horaInicio} às ${horaFim}`,
      text: "Deseja realmente remover o período selecionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Remover",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const deletePeriod =
          await partnerScheduleControlService.deleteSchedulePeriod(
            IdParceiro,
            idAgendaLojaSalaConfig
          );
        const { items } = deletePeriod;
        switch (items.status) {
          case false: {
            toast.error(items.message);
            break;
          }

          case true: {
            toast.success(items.message);
            setTimeout(() => {
              setIsLoading(true);
              window.location.href =
                IdParceiro !== 0
                  ? `/portaladmin/partner/scheduleControl?pl=${IdParceiro}|${CodigoLoja}`
                  : `/portaladmin/agenda?l=${CodigoLoja}`;
            }, 2500);

            break;
          }
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <div className={`schedulePeriod ${!podeAlterar ? "cantRemove" : ""}`}>
      <header className="schedulePeriod__header">
        <div className="schedulePeriod__header__periodName">{periodName}</div>

        {podeAlterar ? (
          <button
            className="schedulePeriod__header__removeBtn"
            title="Remover horário"
          >
            <RemoveIcon onClick={() => handleDeletePeriod()} />
          </button>
        ) : (
          <button
            className="schedulePeriod__header__cantRemoveBtn"
            title="Remover horário"
          >
            <RemoveIcon />
          </button>
        )}
      </header>

      <div className="schedulePeriod__timeSelection">
        <button
          className="schedulePeriod__timeSelection__startTime"
          disabled={!podeAlterar}
        >
          {horaInicio}
        </button>

        <span>às</span>

        <button
          className="schedulePeriod__timeSelection__endTime"
          disabled={!podeAlterar}
        >
          {horaFim}
        </button>
      </div>
    </div>
  );
}
