import { useEffect, useState } from "react";

import { max, min } from "utils";

import arrowUp from "images/icons/arrow-up.svg";
import arrowDown from "images/icons/arrow-down.svg";

import * as Styled from "./styles";

interface HourProps {
  hour: number;
  minute: number;
}

interface SingleHourBoxProps {
  hora: string;
  setExecutionTime: (executionTime: string) => void;
}

export function SingleHourBox({ hora, setExecutionTime }: SingleHourBoxProps) {
  const [hour, setHour] = useState<HourProps>(() => {
    const horaInicioSplit = hora.split(":");

    if (!hora) {
      return {
        hour: 0,
        minute: 10,
      };
    }

    return {
      hour: Number(horaInicioSplit[0]),
      minute: Number(horaInicioSplit[1]),
    };
  });

  useEffect(() => {
    setExecutionTime(
      `${hourFormatted(hour.hour)}:${minuteFormatted(hour.minute)}`
    );
  }, [hour, setExecutionTime]);

  const hourFormatted = (hour: number) => {
    return hour?.toString().padStart(2, "0");
  };

  const minuteFormatted = (minute: number) => {
    return minute?.toString().padStart(2, "0");
  };

  function handleIncrementTime(type: "hour" | "minute") {
    if (type === "hour") {
      if (hour.hour === 23) {
        return;
      }

      setHour({
        ...hour,
        hour: hour.hour + 1,
      });
    } else {
      if (hour.minute === 59) {
        return;
      }

      setHour({
        ...hour,
        minute: hour.minute + 1,
      });
    }
  }

  function handleDecrementTime(type: "hour" | "minute") {
    if (type === "hour") {
      if (hour.hour === 0) {
        return;
      }

      setHour({
        ...hour,
        hour: hour.hour - 1,
      });
    } else {
      if (hour.minute === 0) {
        return;
      }

      setHour({
        ...hour,
        minute: hour.minute - 1,
      });
    }
  }

  return (
    <Styled.Container>
      <Styled.HourInterval>
        <Styled.Hour>
          <button type="button" onClick={() => handleIncrementTime("hour")}>
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={hourFormatted(hour.hour).padStart(2, "0")}
            onChange={(event) => {
              setHour({
                ...hour,
                hour: min(0, max(23, Number(event.target.value))),
              });
            }}
          />
          <button type="button" onClick={() => handleDecrementTime("hour")}>
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Hour>
        <span>:</span>
        <Styled.Minute>
          <button type="button" onClick={() => handleIncrementTime("minute")}>
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={minuteFormatted(hour.minute).padStart(2, "0")}
            onChange={(event) => {
              setHour({
                ...hour,
                minute: min(0, max(60, Number(event.target.value))),
              });
            }}
          />
          <button type="button" onClick={() => handleDecrementTime("minute")}>
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Minute>
      </Styled.HourInterval>
    </Styled.Container>
  );
}
