import { createContext, useContext, ReactNode, useState, useEffect } from "react";
import axios from 'axios';
import { useStore } from '../hooks/store';
import { useLoader } from 'hooks/loader';


interface TotalNotificationContextData {
  totalNotification: number;
  handleTotalNotification: () => void;
}

interface TotalNotificationProviderProps {
  children: ReactNode;
}

const TotalNotificationContext = createContext({} as TotalNotificationContextData);

function TotalNotificationProvider({ children }:TotalNotificationProviderProps) {
  const { selectedStores } = useStore();
  const { isLoader } = useLoader();
  const [totalNotification, setTotalNotification] = useState(0);
  const [verify, setVerify] = useState(false);

  function handleTotalNotification() {
    setVerify(prevState => !prevState)
  }

  // TODO: NOTIFICAÇÃO
  // useEffect(() => {
  //   if(selectedStores.length === 1){
  //   axios.get(`/AgendamentoData/GetTotalNotificacoes?codigoLoja=${selectedStores[0].codigo}`).then((response) => {
  //       if(response.data.result === "Success"){
  //         if(response.data.total > 0){
  //           setTotalNotification(response.data.total);
  //         } else {
  //           setTotalNotification(0);
  //         }
  //       }
  //   })}
  //   else
  //     setTotalNotification(0);
      
  // }, [setTimeout(() => {}, 300000), isLoader, verify])
  // TODO: NOTIFICAÇÃO
  
  return (
    <TotalNotificationContext.Provider
      value={{
        totalNotification,
        handleTotalNotification
      }}
    >
      {children}
    </TotalNotificationContext.Provider>
  );
}

function useTotalNotification(): TotalNotificationContextData {
  const context = useContext(TotalNotificationContext);

  return context;
}

export { TotalNotificationProvider, useTotalNotification };
