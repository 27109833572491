import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  .formRow {
    > div button {
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .gridContainer {
    margin-top: 16px;
  }

  .custom-header {
    background: ${colors.gray.light} !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    color: ${colors.dark} !important;
  }

  .p-datatable-scrollable-header {
    background: ${colors.gray.light};
  }

  .part-grid {
    div:first-child {
      width: auto;
    }

    .p-datatable-emptymessage {
      td {
        text-align: center;
      }
    }
  }
  
  #vincular{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
  }
`;

export const BotaoDesvincular = styled.span`
  display: flex;
  align-items: center;

  &:hover {
    svg path {
      fill: #f14a50 !important;
    }
  }

  a {
    color: #f14a50;
    text-decoration: underline;
    font-size: 14px;
  }
`;
