import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";

import { usePartnerAuth } from "../hooks/partnerAuth";
import { useStore } from "../hooks/store";

const PartnerRoute = ({
    isPrivate = false,
    component: Component,
    ...rest
}) => {
    const { isLoading, access_token, handlePartnerSession } = usePartnerAuth();
    const { setLoad } = useStore();

    useEffect(() => {
        if (!access_token) {
            console.log("PERDEU SESSAO")
            handlePartnerSession();
            if (access_token) setLoad(true);
        }
    }, [access_token]);

    if (isLoading) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={({ location }) => {

                if (!access_token) {
                    toast.info("Sessão inativa, por favor, faça login novamente!")
                }

                return isPrivate === !!access_token ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/portaladmin/login",
                            state: { from: location },
                        }}
                    />
                );
            }}
        />
    );
};

export default PartnerRoute;
