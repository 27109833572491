const text = "#212529";
const blue = "#0054A6";
const blueLight = "#007DC5";
const white = "#FFFFFF";
const dark = "#2A333E";
const textDarkModal = "#111417";
const gray = {
  default: "#444C56",
  light: "#D7E2ED",
  medium: "#B4B4B4",
  dark: "#666666",
  blue: "#7B8FA5",
  blueLight: "#AEC0D2",
  text: "#404F61",
};
const green = "#09B2AB";
const greenLight = "#DBF8E8";
const success = "#237B4B";
const error = "#C91C17";
const yellowDark = "#F5AA07";

const colors = {
  text,
  blue,
  blueLight,
  gray,
  dark,
  textDarkModal,
  white,
  green,
  greenLight,
  success,
  error,
  yellowDark
};

export default colors;
