import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import 'react-toastify/dist/ReactToastify.css';
import "primeflex/primeflex.css"
import 'devextreme/dist/css/dx.light.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import GlobalStyle from "./styles/global";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ToastContainer />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
