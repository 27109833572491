import Button from "components/Button";
import { ModalBase } from "components/ModalGroup/ModalBase";
import { ReactNode } from "react";
import IconClose from "images/icons/icon-close-modal.png";

import { Container } from "./styles";
import { CSSProperties } from "styled-components";
import { useState } from "react";
import { useEffect } from "react";

interface SimpleModalProps {
  onRequestClose: () => void;
  confirm: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  showCancel?: boolean;
  hideButtons?: boolean;
  icon: string;
  title: string | ReactNode;
  message: string | ReactNode;
  messageFontSize?: string;
  messageFontFamily?: string;
  continueButtonText?: string;
  cancelButtonText?: string;
  buttonsMarginTop?: string;
  widthButtonContinue?: string;
  widthButtonClose?: string;
  heightButton?: string;
  marginTitle?: string;
  marginTitleBottom?: string;
  marginButton?: string;
  isIconClose?: boolean;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
  loading?: boolean;
  isAnimation?: boolean;
  buttonFontFamily?: string;
}

export function SimpleModal({
  onRequestClose,
  confirm,
  isOpen,
  showCancel = false,
  hideButtons = false,
  icon,
  title,
  message,
  messageFontSize = '16px',
  messageFontFamily = "Source Sans Pro",
  continueButtonText = "Continuar",
  cancelButtonText = "Cancelar",
  buttonsMarginTop = "32px",
  widthButtonClose = "123px",
  widthButtonContinue = "132px",
  heightButton = "48px",
  marginTitle = "24px",
  marginTitleBottom = "",
  marginButton,
  onCancel,
  isIconClose,
  containerStyle = {},
  imgStyle = {},
  loading = false,
  isAnimation = false,
  buttonFontFamily = 'Source Sans Pro'
}: SimpleModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setIsLoading(loading), [loading]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container style={containerStyle} showCancel={showCancel} buttonsMarginTop={buttonsMarginTop} isAnimation={isAnimation}>
        {isIconClose && (
          <img
            src={IconClose}
            alt="close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              width: "24px",
              height: "24px",
              cursor: "pointer"
            }}
            onClick={onRequestClose}
          />
        )}
        <img src={icon} alt={String(title)} style={imgStyle} />
        <h2 style={{ marginTop: marginTitle, marginBottom: marginTitleBottom }}>
          {title}
        </h2>
        <p style={{
          fontSize: messageFontSize,
          fontFamily: messageFontFamily,
        }}>{message}</p>
        <div className={marginButton}>
          {showCancel && !hideButtons && (
            <Button
              onClick={onCancel ? onCancel : onRequestClose}
              width={widthButtonClose}
              inverted
              disabled={isLoading}
              style={{
                fontFamily: buttonFontFamily
              }}
            >
              <i className="pi pi-times" style={{'marginRight': '0.5rem'}}></i>
              {cancelButtonText}
            </Button>
          )}
          {!hideButtons && (
            <Button
              onClick={confirm}
              width={widthButtonContinue}
              height={heightButton}
              disabled={isLoading}
              style={{
                fontFamily: buttonFontFamily
              }}
            >              
              <i className={"pi " + (isLoading ? "pi-spin pi-spinner" : "pi-check")} style={{'marginRight': '0.5rem'}}></i>              
              {continueButtonText}
            </Button>
          )}
        </div>
      </Container>
    </ModalBase>
  );
}
