import "./partnerStoresSelect.scss";
import { SelectBox as Select } from "devextreme-react/select-box";
import { usePartnerContext } from "../../../../contexts/partnerContextProvider";
import { useEffect, useState } from "react";
import { partnerScheduleControlService } from "../../../../services/partnerScheduleControlService";
import { toast } from "react-toastify";
import { useRouter } from "hooks/useRouter";

export default function PartnerStoresSelect({ setScheduleData }) {
  const { isLoading, setIsLoading } = usePartnerContext();
  const [partnerStates, setPartnerStates] = useState<any[]>([]);
  const [selectedState] = useState("");
  const [partnerStores, setPartnerStores] = useState([]);
  const router = useRouter();
  const { pl } = router?.query as any;
  const partnerId = parseInt(pl.split("|")[0]);
  let storeId = parseInt(pl.split("|")[1]);

  const handlePartnerStoreSchedules = async () => {
    setIsLoading(true);

    const partnerStoresData =
      await partnerScheduleControlService.acquirePartnerStoreSchedules(
        partnerId,
        storeId
      );

    const { items: schedules } = partnerStoresData;

    const items = schedules.map((s) => {
      return {
        ...s,
        partnerId,
      };
    });

    if (items !== null) {
      setScheduleData(items);
      storeId = parseInt(`${items.cdLoja}`);
    } else {
      toast.error("Ocorreu um erro ao buscar as informações da agenda.");
    }

    setIsLoading(false);
  };

  async function handlePartnerStates() {
    setIsLoading(true);

    const partnerStatesData =
      await partnerScheduleControlService.acquirePartnerStates(partnerId);

    const { items } = partnerStatesData;

    if (items && items.length) {
      setPartnerStates(items);
      setIsLoading(false);
    } else {
      toast.error("Ocorreu um erro ao buscar os estados.");
      setIsLoading(false);
    }
  }

  async function handlePartnerStores(stateUF = null) {
    setIsLoading(true);

    const partnerStoresData =
      await partnerScheduleControlService.acquirePartnerStores(
        partnerId,
        stateUF
      );

    const { items } = partnerStoresData;

    if (items && items.length) {
      setPartnerStores(items);

      if (storeId > 0) {
        handlePartnerStoreSchedules();
      } else {
        setIsLoading(false);
      }
    } else {
      toast.error("Ocorreu um erro ao buscar as lojas.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (partnerId > 0) {
      handlePartnerStates();
      handlePartnerStores();
    }
  }, []);

  const onValueChanged = (e) => {
    if (e.value > 0) {
      setIsLoading(true);
      window.location.href = `/portaladmin/partner/scheduleControl?pl=${partnerId}|${e.value}`;
    }
  };

  const onStateSelected = (e) => {
    handlePartnerStores(e.value);
  };

  const handleDisplayName = (e) => {
    if (e === null) return "";

    return `${e?.cdLoja} - ${e?.nomeLoja}`;
  };

  return (
    <div className="partnerStoresSelect">
      <div className="partnerStoresSelect__item">
        <span className="partnerStoresSelect__item__label">
          Filtrar lojas por estado
        </span>

        <div title="Estado">
          <Select
            disabled={partnerStates.length === 1 || isLoading}
            id="partnerStateSelectBox"
            noDataText="Nada para exibir"
            placeholder={
              partnerStates.length === 1
                ? partnerStates[0].sigla
                : "Selecione o estado"
            }
            dataSource={partnerStates}
            defaultValue={selectedState}
            displayExpr="descricao"
            valueExpr={"sigla"}
            onValueChanged={onStateSelected}
            searchEnabled={true}
            searchMode="contains"
            searchExpr={"descricao"}
            searchTimeout={500}
            showDataBeforeSearch={false}
          />
        </div>
      </div>

      <div className="partnerStoresSelect__item">
        <span className="partnerStoresSelect__item__label">Lojas</span>

        <div title="Lojas">
          <Select
            id="partnerStoresSelectBox"
            disabled={isLoading}
            noDataText="Nada para exibir"
            placeholder="Selecione a loja"
            dataSource={partnerStores}
            defaultValue={storeId}
            displayExpr={(e) => handleDisplayName(e)}
            valueExpr={"cdLoja"}
            onValueChanged={onValueChanged}
            searchEnabled={true}
            searchMode="contains"
            searchExpr={"nomeLoja"}
            searchTimeout={500}
            showDataBeforeSearch={false}
          />
        </div>
      </div>

      <button onClick={() => router.replace("home")} className="backBtn">
        Voltar
      </button>
    </div>
  );
}
