import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { useEffect, useState, useRef } from "react";
import { useLoader } from "hooks/loader";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import validator from "validar-telefone";
import Swal from "sweetalert2";

import colors from "../../../tokens/colors";
import { Input } from "components/Input";
import { cpfMask, validateCpf } from "../../ValidationCpf";

import { Container } from "./styles";

import { transferenciaService } from "services/transferenciaService";
import IconAtencao from "images/icons/icon-atencao.svg";
import { clientService } from "services/clientService";
import { formatISO } from "date-fns";

interface CadastroClienteTransfBeneficioFormData {
  nome: string;
  genero: string;
  dataNasc: string;
  celular: string;
  email: string;
}

interface BeneficioProps {
  id: number;
  codigo: string;
  servico: string;
  renovaEm: string;
  quantidade: string;
  permiteTransferencia: boolean;
}

interface ModalTransferenciaProps {
  formDataClientTransfBenef: CadastroClienteTransfBeneficioFormData;
  isOpen: boolean;
  onRequestClose: () => void;
  cpfRef: string;
  infoLine: BeneficioProps | undefined;
  onSuccessTranfer: () => void;
}

interface UserDate {
  nome: string;
  cpf: string;
}

export function ModalTransferenciaBeneficio({
  isOpen,
  onRequestClose,
  infoLine,
  cpfRef,
  formDataClientTransfBenef,
  onSuccessTranfer,
}: ModalTransferenciaProps) {
  useEffect(() => {
    onClear();
  }, []);

  const [nome, setNome] = useState(formDataClientTransfBenef.nome || "");
  const [email, setEmail] = useState(formDataClientTransfBenef.email || "");
  const [celular, setCelular] = useState(
    formDataClientTransfBenef.celular || ""
  );
  const [dataNasc, setDataNasc] = useState<string | undefined>(undefined);
  const [genero, setGenero] = useState(formDataClientTransfBenef.genero || "");

  const [isEmpty, setIsEmpty] = useState("");
  const [nomeIsValid, setNomeIsValid] = useState(false);
  const [cpfNotFound, setCpfNotFound] = useState(false);
  const [isCpf, setIsCpf] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const [cpfIsValid, setCpfIsValid] = useState(false);
  // MODAL DE SUCESSO
  const [isSucess, setIsSucess] = useState(false);

  const [registeredSucess, setRegisteredSucess] = useState(false);

  const [customerData, setCustomerData] = useState<UserDate>({} as UserDate);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorName, setIsErrorName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorCelular, setIsErrorCelular] = useState(false);
  const [isErrorData, setIsErrorData] = useState(false);
  const [isErrorGenero, setIsErrorGenero] = useState(false);

  const onClear = () => {
    setIsEmpty("");
    setIsCpf(false);
    setRegisteredSucess(false);
    setCpfIsValid(false);
    setNomeIsValid(false);
    setNome("");
    setEmail("");
    setDataNasc(undefined);
    setCelular("");
    setGenero("");
    setCustomerData({} as UserDate);
    setIsSucess(false);
  };

  const handleMaskCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cpfIsValid || cpfNotFound) {
      setCpfIsValid(false);
      setCpfNotFound(false);
    }

    let checkValue = getOnlyNumber(e.target.value);
    setIsCpf(checkIsCpf(checkValue));

    if (isCpf) setIsEmpty(cpfMask(e.target.value));
    else setIsEmpty(e.target.value);
  };

  const getOnlyNumber = (value: string) =>
    value.replace(/\D/g, "").replace(/[\.]/, "");

  const checkIsCpf = (value: string) => !!Number(getOnlyNumber(value));

  const validateName = (text: string) => {
    let partes = text.split(" ");
    partes = partes.filter((term) => term);

    setIsEmpty(partes.join(" "));
    return partes.length >= 2;
  };

  const handleBuscar = (value: string) => {
    if (isCpf && !validateCpf(value)) {
      setCpfIsValid(true);
      return;
    } else if (!isCpf && !validateName(value)) {
      setNomeIsValid(true);
      return;
    }
    getClients(value);
  };

  const getClients = async (cpf: string) => {
    setIsLoading(true);

    try {
      const customer = await clientService.getMinimumData(cpf);
      setIsSucess(true);

      if (customer && customer.items) {
        setRegisteredSucess(true);
        setCustomerData(customer.items);
      } else {
        setRegisteredSucess(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const MessageError = (message: string, width: string = "100%") => {
    return (
      <div
        style={{
          width: width,
          height: "auto",
          display: "flex",
          padding: "8px",
          alignItems: "center",
          background: "#FFE4E4",
          marginTop: "8px",
        }}
      >
        <img
          src={IconAtencao}
          alt="atencao"
          style={{
            width: "16px",
            height: "16px",
            marginRight: "4px",
          }}
        />
        <span
          style={{
            color: "#D14A4A",
            fontSize: "14px",
          }}
        >
          {message}
        </span>
      </div>
    );
  };

  const handleRegister = async () => {
    let _nome =
      nome?.trim()?.split(" ").length === 1 ||
      nome?.trim()?.split(" ")[1].trim() === "";
    let _email = validateEmail(email);
    let _celular = validator(celular);
    let _genero = !!genero;
    let _nasc = validaData(dataNasc ?? "");
    setIsErrorName(_nome);
    setIsErrorEmail(!_email);
    setIsErrorCelular(!_celular);
    setIsErrorGenero(!_genero);
    setIsErrorData(!_nasc[0]);

    if (!_nome && _email && _celular && _genero && _nasc[0]) {
      setIsLoading(true);
      const hash = localStorage.getItem("@portalAdmin:hash");
      let payload = {
        clienteReferencia: {
          cpf: cpfRef,
        },
        clienteDestino: {
          cpf: isEmpty,
          nome: nome?.trim(),
          dataNascimento: formatISO(new Date(_nasc[1])),
          genero: genero.trim(),
          email: email.trim(),
          telefone: celular.trim(),
        },
        beneficio: {
          idServico: infoLine?.id,
        },
      };

      transferenciaService
        .transfererBenefit(payload, { pm_hash: hash })
        .then((_) => {
          Swal.fire({
            text: "Transferência de serviço realizada com sucesso.",
            icon: "success",
            confirmButtonColor: colors.blue,
          }).then((_) => {
            onRequestClose();
            onSuccessTranfer();
          });
        })
        .catch((error: any) => {
          let _erro =
            error.response.data?.message ??
            error.response.data["message"] ??
            "Error";
          Swal.fire({
            title: "Oops...",
            text: _erro,
            icon: "error",
            confirmButtonColor: colors.blue,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleUpdate = () => {
    const hash = localStorage.getItem("@portalAdmin:hash");
    setIsLoading(true);

    let payload = {
      clienteReferencia: {
        cpf: cpfRef,
      },
      clienteDestino: {
        cpf: isEmpty,
      },
      beneficio: {
        idServico: infoLine?.id,
      },
    };

    transferenciaService
      .transfererBenefit(payload, { pm_hash: hash })
      .then((_) => {
        Swal.fire({
          text: "Transferência de serviço realizada com sucesso.",
          icon: "success",
          confirmButtonColor: colors.blue,
        }).then((_) => {
          onRequestClose();
          onSuccessTranfer();
        });
      })
      .catch((error: any) => {
        let _erro =
          error.response.data?.message ??
          error.response.data["message"] ??
          "Error";
        Swal.fire({
          title: "Oops...",
          text: _erro,
          icon: "error",
          confirmButtonColor: colors.blue,
        });
      })
      .finally(() => setIsLoading(false));
  };

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const validaData = (dataNasc: string): [boolean, string] => {
    if (!dataNasc) return [false, ""];

    let data = dataNasc;
    data = data.replace(/\//g, "-");
    let data_array = data.split("-");

    if (data_array[0].length !== 4) {
      data =
        data_array[2] +
        "-" +
        data_array[1] +
        "-" +
        data_array[0] +
        "T06:00:00.000Z";
    }

    let hoje = new Date();
    let nasc = new Date(data);
    let idade = hoje.getFullYear() - nasc.getFullYear();
    let m = hoje.getMonth() - nasc.getMonth();
    if (m < 0 || (m === 0 && hoje.getDate() < nasc.getDate())) idade--;

    if (idade < 12) {
      alert("Pessoas menores de 12 não podem se cadastrar.");
      return [false, ""];
    }

    if (idade >= 12 && idade <= 100) {
      return [true, data];
    }
    return [false, ""];
  };

  const customClient = () => {
    if (!cpfIsValid && isSucess) {
      if (registeredSucess) {
        return (
          <Container>
            <div>
              <div className="p-fluid p-formgrid p-grid">
                <div id="nome" className="p-field p-col-12 required">
                  <label htmlFor="nome">CPF</label>
                  <span className="fakeIpunt">{customerData.cpf}</span>
                </div>
                <div className="p-field p-col required">
                  <label htmlFor="Inicio">Nome</label>
                  <span className="fakeIpunt">{customerData.nome}</span>
                </div>
              </div>
              <Button
                style={{
                  marginTop: "1rem",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                onClick={() => handleUpdate()}
                label={isLoading ? "Carregando..." : "Transferir"}
                disabled={isLoading}
                icon="pi pi-save"
                loading={isLoading}
              />
            </div>
          </Container>
        );
      } else {
        return (
          <Container>
            <div>
              <div className="p-fluid p-formgrid p-grid">
                <div id="nome" className="p-field p-col-12 required">
                  <label
                    htmlFor="nome"
                    className={`${isErrorName && "is-danger-text"}`}
                  >
                    Nome
                  </label>
                  <Input
                    name="nome"
                    value={nome}
                    onChange={(event) => setNome(event.target.value)}
                    maxLength={40}
                    disabled={isLoading}
                    placeholder="Insira o nome e sobrenome"
                    className={`${isErrorName && "is-danger"}`}
                  />
                </div>
                <div id="email" className="p-field p-col-12 required">
                  <label
                    htmlFor="email"
                    className={`${isErrorEmail && "is-danger-text"}`}
                  >
                    E-mail
                  </label>
                  <Input
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    maxLength={40}
                    disabled={isLoading}
                    placeholder="Insira um email válido"
                    className={`${isErrorEmail && "is-danger"}`}
                  />
                </div>
                <div id="dataNasc" className="p-field p-col-6 required">
                  <label
                    htmlFor="Inicio"
                    className={`${isErrorData && "is-danger-text"}`}
                  >
                    Data Nascimento
                  </label>
                  <InputMask
                    value={dataNasc}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setDataNasc(event.target.value);
                      } else {
                        setDataNasc(undefined);
                      }
                    }}
                    disabled={isLoading}
                    id="birthDate"
                    name="birthDate"
                    mask="99/99/9999"
                    placeholder="DD/MM/AAAA"
                    className={`p-inputtext p-component ${isErrorData && "is-danger p-invalid"
                      }`}
                  />
                </div>
                <div id="celular" className="p-field p-col-6 required">
                  <label
                    htmlFor="Inicio"
                    className={`${isErrorCelular ? "is-danger-text" : ""}`}
                  >
                    Celular :
                  </label>
                  <InputMask
                    name="celular"
                    value={celular}
                    placeholder="(00) 00000 - 0000"
                    onChange={(event) => setCelular(event.target.value)}
                    mask="(99) 99999-9999"
                    disabled={isLoading}
                    className={`p-inputtext p-component ${isErrorCelular === true ? "is-danger" : ""
                      }`}
                  />
                </div>
                <div
                  className="p-field p-col-12 p-m-0"
                  style={{ opacity: 0 }}
                ></div>
                <div id="genero" className="p-field p-col-4 required">
                  <label
                    htmlFor="genero"
                    className={`${isErrorGenero && "is-danger-text"}`}
                  >
                    Gênero
                  </label>
                  <Dropdown
                    name="genero"
                    value={genero}
                    placeholder="Selecione"
                    optionLabel="label"
                    optionValue="value"
                    options={[
                      { value: "M - Masculino", label: "MASCULINO" },
                      { value: "F - Feminino", label: "FEMININO" },
                      { value: "O - Não Informado", label: "NÃO INFORMAR" },
                    ]}
                    disabled={isLoading}
                    onChange={(event: any) => setGenero(event.value)}
                    className={isErrorGenero ? "is-danger" : ""}
                  />
                </div>
              </div>
              <Button
                style={{
                  marginTop: "1rem",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                onClick={() => handleRegister()}
                label={isLoading ? "Carregando..." : "Cadastrar"}
                disabled={isLoading}
                icon="pi pi-save"
                loading={isLoading}
              />
            </div>
          </Container>
        );
      }
    }

    return <></>;
  };

  return (
    <Dialog
      header="Transferir Serviço"
      visible={isOpen}
      style={{ width: "50vw" }}
      onHide={onRequestClose}
    >
      <Form onSubmit={() => console.log()} ref={formRef} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="formRow">
          <p>Para verificar se o cliente é cadastrado, digite o CPF abaixo:</p>
        </div>
        <div className="formRow">
          <div className="inputCpf">
            <div>
              <Input
                name="inputCpf"
                label="Consultar por CPF"
                placeholder="CPF do cliente"
                disabled={registeredSucess}
                style={{
                  width: "100%",
                  height: "40px",
                  borderColor: cpfIsValid || cpfNotFound ? "#D14A4A" : "",
                }}
                onChange={(event) => handleMaskCpf(event)}
                value={isEmpty}
              />

              {cpfIsValid && MessageError("CPF inválido")}
              {nomeIsValid && MessageError("Informe um CPF válido")}
            </div>

            <div className="mt-4">
              <Button
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
                icon="pi pi-search"
                onClick={() => handleBuscar(isEmpty)}
                label={isLoading ? "Carregando..." : "Consultar"}
                disabled={isLoading || cpfIsValid || nomeIsValid}
                loading={isLoading}
              />

              <Button
                label="Limpar"
                className="p-button-outlined"
                title="Limpar"
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  marginLeft: "0.5rem",
                }}
                icon="pi pi-times"
                onClick={() => onClear()}
                disabled={isLoading}
              />
            </div>

            {customClient()}
          </div>
        </div>
      </Form>
    </Dialog>
  );
}
