import { ReactNode } from "react";
import { Container } from "./styles";
interface MainProps {
  children: ReactNode;
  _paddingLeft?: string;
  _paddingRight?: string;
}

export default function Main({ children, _paddingLeft, _paddingRight }: MainProps) {
    return <Container style={{ paddingLeft: _paddingLeft, paddingRight: _paddingRight }}>{children}</Container>;
}
