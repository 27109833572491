import axiosConfig from "../config/axiosConfig";
import axios from "axios";
const axiosConfigCustom = Object.assign(axiosConfig, {
  validateStatus: (status: number) => status >= 200 && status < 300,
});

export const partnerService = {
  getById: async (partnerId: number) => {
    const req = await axios.get(`/ParceiroData/parceiro/${partnerId}`);
    const { data } = req;
    return data;
  },
  getAll: async () => {
    const req = await axios.get("/ParceiroData", axiosConfig);
    const { data } = req;
    return data;
  },
  getPartnerByEmail: async (email: string) => {
    const req = await axios.get(`/ParceiroData/${email}`, axiosConfigCustom);
    const { data } = req;
    return data;
  },
  getTypePartner: async () => {
    const req = await axios.get("/ParceiroData/tipos", axiosConfigCustom);
    const { data } = req;
    return data;
  },
  save: async (body: any, id?: number) => {
    if (id) {
      const req = await axios.put(`/ParceiroData/${id}`, {
        ...body,
        idParceiro: id,
      });
      const { data } = req;
      return data;
    }

    const req = await axios.post(`ParceiroData`, body);
    const { data } = req;
    return data;
  },
  getAllServiceLinkedByPartnerId: async (partnerId: number) => {
    const req = await axios.get(`/ParceiroData/parceiro/${partnerId}/servico`);
    const { data } = req;
    return data;
  },
  updateStatusServiceLinkedByPartnerId: async (
    partnerId: number,
    servicePartnerId: number,
    status: boolean
  ) => {
    const req = await axios.put(
      `/ParceiroData/parceiro/${partnerId}/parceiroservico/${servicePartnerId}`,
      {
        flAtivo: status,
      }
    );
    const { data } = req;
    return data;
  },
  getAllStore: async (partnerId: number) => {
    const req = await axios.get(`/ParceiroData/parceiro/${partnerId}/loja`);
    const { data } = req;
    return data;
  },
  getAllStoreRoomServices: async (partnerId: number, storeId: number, roomId: number) => {
    const req = await axios.get(
      `/ParceiroData/parceiro/${partnerId}/loja/${storeId}/sala/${roomId}/servico`
    );
    const { data } = req;
    return data;
  },
  //Não existe chamada para esse endpoint
  // getStoreRoomServicesById: async (
  //   partnerId: number,
  //   storeRoomServiceId: number
  // ) => {
  //   const { BaseAddress, ApiKey } = envConfig.apiURL("Parceiro");
  //   const req = await axios.get(
  //     `${BaseAddress}/${partnerId}/lojasalaservico/${storeRoomServiceId}`
  //   );
  //   const { data } = req;
  //   return data;
  // },
  manageStoreRoomServicesById: async (
    partnerId: number,
    info: any,
    storeId?: number,
    roomId?: number
  ) => {
    let req: any;
    if (info.idParceiroLojaSalaServico) {
      req = await axios.put(
        `/ParceiroData/parceiro/${partnerId}/lojasalaservico/${info.idParceiroLojaSalaServico}`,
        {
          flAtivo: info.flAtivo,
          preco: info.preco,
        }
      );
    } else {
      req = await axios.post(
        `/ParceiroData/parceiro/${partnerId}/loja/${storeId}/sala/${roomId}/servico`,
        {
          idParceiroServico: info.idParceiroServico,
          flAtivo: info.flAtivo,
          preco: info.preco,
        }
      );
    }
    const { data } = req;
    return data;
  },
  updateStatus: async (partnerId: number, status: boolean) => {
    const req = await axios.put(`/ParceiroData/${partnerId}/alterar-status?status=${status}`);
    const { data } = req;
    return data;
  },
};
