import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 24px);
  padding: 32px 42px;
  padding-top: 92px;
  flex: 1;
`;
