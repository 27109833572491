import styled from "styled-components";
import colors from "../../tokens/colors";
import arrowImg from "../../images/icons/select-dropdown.svg";

interface LojaProps {
  displayStore: boolean;
}

export const Container = styled.div<LojaProps>`
  min-width: 324px;
  height: ${(props) => (props.displayStore ? "157px" : "77px")};
  border: 1px solid ${colors.gray.light};
  border-radius: 4px;
  padding: 14px;
`;

export const ContainerUser = styled.div<LojaProps>`
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  border-bottom: ${(props) =>
    props.displayStore ? `1px solid ${colors.gray.light}` : ""};
  padding-bottom: 11px;
  margin-bottom: 7px;

  div {
    margin-left: 11px;

    p {
      color: #2a333e;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 18px;
    }

    span {
      color: #2a333e;
      font-size: 14px;
    }
  }
`;

export const ContainerShop = styled.div`
  p {
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 4px;
  }

  select {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    background: transparent;
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;
    padding: 7px 16px;
    color: ${colors.dark};
    appearance: none;
    padding-right: 45px;
    background-image: url(${arrowImg});
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
  }
`;
