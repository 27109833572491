import axios from "axios";
import { toast } from "react-toastify";
import axiosConfig from "../config/axiosConfig";

export const partnershipLandingPageService = {
  getLandingpageData: async (id) => {
    const req = await axios.get(
      `/ParceriaLandingPageData/GetLandingPageData/${id}`,
      axiosConfig
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  updateLandingPageData: async (landingPageData) => {
    const req = await axios.put(
      `/ParceriaLandingPageData/UpdateLandingPageData`,
      landingPageData,
      axiosConfig
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  
  removeBannerImage: async (id) => {
    const req = await axios.put(
        `/ParceriaLandingPageData/RemoveLandingPageBanner/${id}`,
        null,
        axiosConfig
    );

    const {data, status} = req;

    if (status === 200) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  }
};
