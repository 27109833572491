import { CSSProperties } from "styled-components";
import { Container } from "./styles";

interface NoResultsProps {
  title: string;
  message: string;
  colorTitle?: string;
  colorMessage?: string;
  sizeTitle?: string;
  sizeMessage?: string;
  containerStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  messageStyle?: CSSProperties;
}

export function NoResults({
  title,
  message,
  colorTitle = "#a7bed6",
  colorMessage = "#a7bed6",
  sizeTitle = "14px",
  sizeMessage = "14px",
  containerStyle,
  titleStyle,
  messageStyle,
}: NoResultsProps) {
  return (
    <Container
      colorTitle={colorTitle}
      colorMessage={colorMessage}
      sizeTitle={sizeTitle}
      sizeMessage={sizeMessage}
      style={containerStyle}
    >
      <strong style={titleStyle}>{title}</strong>
      <p style={messageStyle}>{message}</p>
    </Container>
  );
}
