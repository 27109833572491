import { useEffect, useRef, useState } from "react";
import StepWizard from "react-step-wizard";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Nav } from "./Nav";
import First from "./StepOne";
import Second from "./StepTwo";
import Three from "./StepThree";
import Four from "./StepFour";
import Resume from "./StepResume";
import { useHistory } from "react-router-dom";
import { partnerService } from "services/partnerService";
import { Dialog } from "primereact/dialog";
import loadingImg from "../../images/icons/loading.gif";

interface stepChangeProps {
  activeStep: number;
  previousStep: number;
}

interface StepPartnerProps {
  editPartnerId?: number;
}

const StepPartner = (props: StepPartnerProps) => {
  const history = useHistory();
  const childRef = useRef();
  const [stepActive, setStepActive] = useState<number>(0);
  const [state, updateState] = useState<any>({
    form: {},
    infoPartner: {},
  });
  const [messageTitle, setMessageTitle] = useState<string | null>(null);
  const [headerDialog, setHeaderDialog] = useState<string>("Atenção");
  const [lazyLoading, setLazyLoading] = useState(false);

  const { editPartnerId } = props;

  useEffect(() => {
    if (editPartnerId && editPartnerId > 0) {
      setLazyLoading(true);
      handlerPartner(editPartnerId);
    }
  }, [editPartnerId]);

  const handlerPartner = (id: number) => {
    partnerService
      .getById(id)
      .then((item) => {
        updateState({
          ...state,
          infoPartner: { ...item },
        });
      })
      .finally(() => setLazyLoading(false));
  };

  const updateForm = (key: any, value: any) => {
    const { form } = state;
    form[key] = value;
    updateState({
      ...state,
      form,
    });
  };

  const onStepChange = (stateChangeStep: stepChangeProps) => {
    setStepActive(--stateChangeStep.activeStep);
  };

  const dialogHeader = (headerDialog: string) => {
    let result: string = "";
    switch (headerDialog) {
      case "Atenção":
        result = "Entendi";
        break;
      case "Confirmação":
        result = "Ok";
        break;
      case "Alerta":
        result = "Sim";
        break;
      default:
        break;
    }

    return result;
  };

  const footer = (
    <div>
      {headerDialog === "Alerta" && (
        <Button
          label="Não"
          icon="pi pi-times"
          onClick={() => setMessageTitle(null)}
          className="p-button-text"
        />
      )}
      <Button
        label={dialogHeader(headerDialog)}
        icon="pi pi-check"
        onClick={() => {
          setMessageTitle(null);
          if (headerDialog === "Confirmação") {
            setHeaderDialog("Atenção");
            history.push("/portaladmin/parceiros");
          }
          else if (headerDialog === "Alerta") {
            setHeaderDialog("Atenção");
            (childRef.current as any).handleNextStepNav();
          }
        }}
      />
    </div>
  );

  return (
    <div className="container">
      <StepWizard
        onStepChange={onStepChange}
        nav={<Nav stepActive={stepActive} state={state} />}
        initialStep={0}
        className="p-pb-3"
      >
        <First
          update={updateForm}
          stepName="First"
          state={state}
          setMessage={setMessageTitle}
        />
        <Second update={updateForm} stepName="Second" state={state} />
        <Three
          update={updateForm}
          stepName="Three"
          state={state}
          setMessage={setMessageTitle}
          setLazyLoading={setLazyLoading}
          lazyLoading={lazyLoading}
          setHeaderDialog={setHeaderDialog}
        />
        <Four
          update={updateForm}
          stepName="Four"
          state={state}
          setMessage={setMessageTitle}
          setLazyLoading={setLazyLoading}
          lazyLoading={lazyLoading}
          setHeaderDialog={setHeaderDialog}
        />
        <Resume
          stepName="Resume"
          state={state}
          setMessage={setMessageTitle}
          setHeaderDialog={setHeaderDialog}
          ref={childRef}
        />
      </StepWizard>

      <ConfirmDialog
        visible={!!messageTitle}
        onHide={() => setMessageTitle(null)}
        message={messageTitle}
        header={headerDialog}
        icon={`pi ${
          headerDialog === "Confirmação"
            ? "pi-check"
            : "pi-exclamation-triangle"
        }`}
        footer={footer}
      />

      {lazyLoading && (
        <Dialog
          showHeader={false}
          closable={false}
          closeOnEscape={false}
          visible={lazyLoading}
          style={{ width: "30vw", height: "45vh" }}
          footer={null}
          onHide={() => null}
          contentStyle={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: '"Source Sans Pro", sans-serif',
            textAlign: "center",
          }}
        >
          <div className="modal-loading">
            <img src={loadingImg} alt="Carregando informações" />
            <h2>Carregando informações</h2>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default StepPartner;
