import axios from 'axios';
import queryString from 'query-string';

const apiKeycloak = axios.create({
  baseURL: 'https://keyauth-stg.apipmenos.com/auth/realms/employees/protocol/openid-connect/token',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});

async function getAuthToken() {
  const response = await apiKeycloak.post('',
    queryString.stringify({
      grant_type: 'client_credentials',
      client_id: 'hub-saude-service',
      client_secret: 'OXP9d2I8ViZ74zfYDUil2tYG0zo5iybV',
      scope: 'openid profile'
    })
  );

  var token = 'Bearer ' + response.data.access_token;
  return token;
}

export { apiKeycloak, getAuthToken };