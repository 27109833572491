import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import Header from "../../components/Header";
import Main from "../../components/Main";
import { Footer } from "../../components/Footer";
import DashboardUser from "../../components/DashboardUser";
import { FormHandles } from "@unform/core";
import { cpfMask, validateCpf } from "../../components/ValidationCpf";
import { AgendamentoClienteConsultar } from "components/AgendamentoClienteConsultar";

import { DashboardContainer, Content } from "./styles";

import IconAtencao from "images/icons/icon-atencao.svg";

interface FormData {
    nome: string;
    cpf: string;
}

interface AgendamentoProps {
    id: number,
    dataAgendamento: string,
    horaAgendamento: string,
    codigoLoja: number,
    nomeLoja: string,
    testeCovidAntigeno: string,
    testeCovidAntigenoNasal: string,
    testeCovidPcrLamp: string,
    testeCovidAnticorpo: string,
    testeCovidRtPcr: string,
    vacinaGripe: string,
    statusAgendamento: string,
    dataAgenda: string,
    dataCadastro: string,
    horaCadastro: string
}

function MessageError(message: string, width: string) {
    return (
        <div
            style={{
                width: width,
                height: "32px",
                display: "flex",
                padding: "8px",
                alignItems: "center",
                background: "#FFE4E4",
                marginTop: "8px",
            }}
        >
            <img
                src={IconAtencao}
                alt="atencao"
                style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "4px",
                }}
            />
            <span
                style={{
                    color: "#D14A4A",
                    fontSize: "14px",
                }}
            >
                {message}
            </span>
        </div>
    );
}

export function AgendamentosCliente() {
    const [isEmpty, setIsEmpty] = useState("");
    const [cpfIsValid, setCpfIsValid] = useState(false);
    const [cpfNotFound, setCpfNotFound] = useState(false);
    const [agendamentosCliente, setAgendamentosCliente] = useState<AgendamentoProps[]>([]);
    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");

    const formRef = useRef<FormHandles>(null);
    const [isLoading, setIsLoading] = useState(false);

    async function validateForm(data: FormData) {
        console.log("validateForm => data: ",data);

        const schema = Yup.object().shape({
            nome: Yup.string().required(),
            sexo: Yup.string().required(),
            dataNascimento: Yup.string().required(),
            vincularParceria: Yup.number().required(),
        });

        await schema.validate(data, {
            abortEarly: false,
        });
    }

    useEffect(() => {
        (
            async () => await validateForm(formRef.current?.getData() as FormData)
        )();
    }, []);

    function handleMaskCpf(e: React.ChangeEvent<HTMLInputElement>) {
        if (cpfIsValid || cpfNotFound) {
            setCpfIsValid(false);
            setCpfNotFound(false);
        }
        setIsEmpty(cpfMask(e.target.value));
    }

    function onFocusOutCPF(event: any) {
        if (event.target.value === "") {
            setCpf("");
            setNome("");
        }
    }

    async function handleSubmit(data: FormData) {
        try {
            console.log("handleSubmit => data: ", data);

            //const response = await axios.post("/BeneficioData/AddCliente", {
            //    idParceria: data.vincularParceria,
            //    cpf: data.cpf,
            //    nome: data.nome,
            //});
            //console.log("handleSubmitResponse: ", response);

            // Remove all previous errors
            formRef.current?.setErrors({});
            await validateForm(data);
            //setOpenModalSucess(true);
            formRef.current?.reset();
        } catch (error) {
            console.log("handleSubmitError => data: ", error);
        }
    }

    async function getAgendamentosCliente() {
        const cpfClient = formRef.current?.getFieldValue("inputCpf");

        setIsLoading(true);
        setCpf("");
        setNome("");

        //TODO: REMOVER
        setTimeout(() => setIsLoading(false), 3000);

        let response = {
            data: {
                cliente: getAgendamentosMock(cpfClient)
            }
        };
        //TODO: REMOVER

        //const response = await axios.get(`/beneficioData/cpf/${cpfClient}`);
        //console.log(response.data);

        //if (response.data.result === "Error") {
        //    setCpfNotFound(true);
        //    return;
        //}
        //setIsLoading(false);

        const { nomeCliente, cpfCliente } = response.data.cliente;

        console.log(response.data.cliente);

        setCpf(cpfCliente);
        setNome(nomeCliente);

        let agendamentos: AgendamentoProps[] = [];
        response.data.cliente.agendamentos?.map((item: any) => {
            let agendamento = {} as AgendamentoProps;
            agendamento.id = item.id;
            agendamento.dataAgendamento = new Date(item.dataAgendamento).toLocaleDateString("pt-BR");
            agendamento.horaAgendamento = item.horaAgendamento;
            agendamento.codigoLoja = item.codigoLoja;
            agendamento.nomeLoja = item.nomeLoja;
            agendamento.testeCovidAntigeno = item.servicos.testeCovidAntigeno;
            agendamento.testeCovidAntigenoNasal = item.servicos.testeCovidAntigenoNasal;
            agendamento.testeCovidPcrLamp = item.servicos.testeCovidPcrLamp;
            agendamento.testeCovidAnticorpo = item.servicos.testeCovidAnticorpo;
            agendamento.testeCovidRtPcr = item.servicos.testeCovidRtPcr;
            agendamento.vacinaGripe = item.servicos.vacinaGripe;
            agendamento.statusAgendamento = item.statusAgendamento;
            agendamento.dataAgenda = new Date(item.dataAgenda).toLocaleDateString("pt-BR");
            agendamento.dataCadastro = new Date(item.dataCadastro).toLocaleDateString("pt-BR");
            agendamento.horaCadastro = item.horaCadastro;
            agendamentos.push(agendamento);
        });
        setAgendamentosCliente(agendamentos);
    }

    function getAgendamentosMock(cpf: string) {
        return {
            id: 68300,
            nomeCliente: 'Emerson Barbosa Godinho',
            cpfCliente: '022.212.863-16',
            codigoClienteVinculado: '07b928ca-b18d-11eb-82ac-02909912c653',
            email: 'emersongondinho@pmenos.com.br',
            telefone: '85987943307',
            dataNascimento: null,
            agendamentos: [{
                id: 1,
                dataAgendamento: new Date(2021, 4, 11),
                horaAgendamento: '11:30',
                codigoLoja: 1,
                nomeLoja: 'FOR01-Fortaleza-CE#',
                servicos: {
                    testeCovidAntigeno: false,
                    testeCovidAntigenoNasal: false,
                    testeCovidPcrLamp: false,
                    testeCovidAnticorpo: true,
                    testeCovidRtPcr: false,
                    vacinaGripe: false
                },
                statusAgendamento: 'AGENDADO',
                dataAgenda: new Date(2021, 4, 11),
                dataCadastro: new Date(2021, 4, 10),
                horaCadastro: '15:56'
            }, {
                id: 2,
                dataAgendamento: new Date(2021, 4, 12),
                horaAgendamento: '00:30',
                codigoLoja: 1,
                nomeLoja: 'FOR01-Fortaleza-CE#',
                servicos: {
                    testeCovidAntigeno: 1,
                    testeCovidAntigenoNasal: 0,
                    testeCovidPcrLamp: 0,
                    testeCovidAnticorpo: 0,
                    testeCovidRtPcr: 0,
                    vacinaGripe: 0
                },
                statusAgendamento: 'AGENDADO',
                dataAgenda: new Date(2021, 4, 12),
                dataCadastro: new Date(2021, 4, 11),
                horaCadastro: '15:38'
            }, {
                id: 3,
                dataAgendamento: new Date(2021, 4, 14),
                horaAgendamento: '00:30',
                codigoLoja: 1,
                nomeLoja: 'FOR01-Fortaleza-CE#',
                servicos: {
                    testeCovidAntigeno: 1,
                    testeCovidAntigenoNasal: 0,
                    testeCovidPcrLamp: 0,
                    testeCovidAnticorpo: 0,
                    testeCovidRtPcr: 0,
                    vacinaGripe: 0
                },
                statusAgendamento: 'AGENDADO',
                dataAgenda: new Date(2021, 4, 14),
                dataCadastro: new Date(2021, 4, 12),
                horaCadastro: '20:00'
            }]
        };
    }

    function handleBuscar(value: string) {
        if (!validateCpf(value)) {
            setCpfIsValid(true);
            return;
        }
        getAgendamentosCliente();
    }

    return (
        <>
            <Header />
            <Main>
                <DashboardContainer>
                    <DashboardUser />
                </DashboardContainer>
                <Content>
                    <h2>AGENDAMENTOS DO CLIENTE</h2>
					<AgendamentoClienteConsultar />
                </Content>
            </Main>
            <Footer />
        </>
    );
}