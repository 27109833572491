import './stateSelect.scss'
import {SelectBox as Select} from 'devextreme-react/select-box'
import SelectBox from "devextreme/ui/select_box";
import {usePartnerContext} from 'contexts/partnerContextProvider';
import {useEffect, useState} from 'react';
import {partnerDashboardService} from 'services/partnerDashboardService';
import {toast} from 'react-toastify';
import {partnerLoginService} from 'services/partnerLoginService';
import {usePartnerAuth} from 'hooks/partnerAuth';
import {partnerScheduleLockReleaseService} from "../../../../services/partnerScheduleLockReleaseService";

export const clearStateSelectBox = () => {
    const selectBox: any = document.querySelector('#stateSelectBox')
    let instance = SelectBox.getInstance(selectBox) as SelectBox;
    instance.reset()
}

export default function StateSelect({disabled, setFilterState}) {
    const {setIsLoading, wipeOutPartnerData} = usePartnerContext();
    const {partnerEmail, handlePartnerSignout} = usePartnerAuth();
    const [states, setStates] = useState([])

    useEffect(() => {
        handleStates();
    }, [])

    const handleStates = async () => {
        setIsLoading(true)

        return partnerLoginService.acquirePartnerId(partnerEmail)
            .then(async partnerId => {

                if (partnerId == null || partnerId < 0) {
                    wipeOutPartnerData()
                    handlePartnerSignout()
                };

                const statesData = await partnerScheduleLockReleaseService.acquirePartnerStates(partnerId);

                const {items} = statesData;

                if (items && items.length) setStates(items);
                else toast.error("Ocorreu um erro ao buscar os estados.");
            })
            .catch(_ => {
                wipeOutPartnerData()
                handlePartnerSignout()
            })
            .finally(() => setIsLoading(false));
    }

    const onValueChanged = (e: any) => {
        setFilterState(e.value)
    }

    return (
        <div className="stateSelect">
            <span className="stateSelect__label">Filtrar lojas por estado</span>

            <div title="Estado">
                <Select
                    id="stateSelectBox"
                    disabled={disabled}
                    noDataText="Nada para exibir"
                    placeholder="Selecione o estado"
                    dataSource={states}
                    displayExpr={'descricao'}
                    valueExpr={'sigla'}
                    onValueChanged={onValueChanged}
                    searchEnabled={true}
                    searchMode="contains"
                    searchExpr={'descricao'}
                    searchTimeout={500}
                    showDataBeforeSearch={false}
                />
            </div>
        </div>
    )
}
