import axiosConfig from '../config/axiosConfig'
import axios, { AxiosRequestConfig } from 'axios'
const axiosConfigCustom = Object.assign(axiosConfig, {
  validateStatus: (status: number) => status >= 200 && status < 300
})

export const storeService = {
  getAllStore: async () => {
    const req = await axios.get("/LojaSalaData");
    const { data } = req;
    return data;
},
  getAllStoreByFilter: async (
    uf?: string,
    cidade?: string,
    bairro?: string
  ) => {
    let params = {}
    if (uf) params = { ...params, ...{ uf: `${uf}` } }
    if (cidade) params = { ...params, ...{ cidade: `${cidade}` } }
    if (bairro) params = { ...params, ...{ bairro: `${bairro}` } }

    let axiosCustom = { ...axiosConfigCustom } as AxiosRequestConfig
    axiosCustom.params = params

    const req = await axios.get('/lojaData/GetLojas', axiosCustom)
    const { data } = req
    return data
  },
  // O enpoint não é chamado na aplicação
  // save: async (info: any) => {
  //   const { BaseAddress, ApiKey } = envConfig.apiURL('Parceiro')
  //   const req = await axios.post(`${BaseAddress}/AddLojaSala`, info)
  //   const { data, status } = req

  //   if (status === 200) {
  //     return data
  //   } else {
  //     toast.error(data.message)
  //     return null
  //   }
  // },
  getStoreWithActiveSchedulling: async (idParceiro: number) => {
    const req = await axios.get(`/ParceiroLojaSalaData/ParceiroLojaSala/${idParceiro}/GetSalasComAgendamentosAtivos`);
    const { data } = req;
    return data;
  }
}
