import { BrowserRouter } from "react-router-dom";
import Modal from "react-modal";
import Routes from "routes";
import { AppProvider } from "hooks";

Modal.setAppElement("#root");

export function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}
