import styled from "styled-components";

import { colors } from "tokens";

export const Container = styled.div`
  display: flex;

  .bulkActions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;

    span {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px 12px;
      cursor: pointer;
    }
  }
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 25px;
  }
`;

export const ActionAgenda = styled.div`
  position: absolute;
  width: 157px;
  height: 78px;
  z-index: 51;

  background: #ffffff;
  border: 1px solid #d7e2ed;
  box-sizing: border-box;
  border-radius: 4px;

  margin-top: -40px;
  margin-left: 170px;

  div {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-left: 10px;

    span {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #404f61;

      align-items: center;
      margin-top: 8px;
      margin-right: 16px;

      cursor: pointer;

      &:first-child {
        margin-top: 0;
        color: #f14a50;

        &:hover {
          filter: brightness(0.8);
        }
      }

      &:last-child {
        color: #237b4b;

        &:hover {
          filter: brightness(0.8);
        }
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
`;

export const CalendarContainer = styled.div`
  margin-top: 18px;

  h2 {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark};
    width: 100%;
  }

  p {
    font-family: "Source Sans Pro";
    font-size: 14px;
    color: ${colors.gray.text};
  }

  .header-calendar-action {
    display: flex;
  }

  img.action {
    width: 33px;
    height: 33px;
    cursor: pointer;
  }

  div.calendar {
    margin-top: -15px;
  }
`;

export const Content = styled.div`
  width: 924px;
  margin-left: 20px;
  padding-right: 50px;

  .titleContainer {
    display: flex;
    justify-content: space-between;

    div {
      > h2 {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 22px;
        font-weight: 600;
        color: ${colors.dark};
        margin: 0;
      }

      > p {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .periodContainer {
    display: flex;

    margin-top: 26px;
    margin-bottom: 16px;

    div + div {
      margin-left: 26px;
    }

    p {
      margin: 0;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .formRow {
      display: flex;

      & + .formRow {
        margin-top: 16px;
      }

      .selectContainer {
        min-width: 290px;
        width: 100%;
        display: flex;
        flex-direction: column;

        & + .selectContainer {
          margin-left: 28px;
        }

        label {
          font-family: "Source Sans Pro", sans-serif;
          color: ${colors.dark};
        }

        input {
          width: 100%;
        }
      }

      button {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background: ${colors.blue};

        font-family: "Source Sans Pro", sans-serif;
        color: ${colors.white};

        :disabled {
          background: #f2f2f2;
          color: ${colors.gray.text};
        }
      }
    }
  }

  .gridContainer {
    margin-top: 24px;

    p {
      margin: 0;
      margin-bottom: 8px;
    }
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 50;
`;
