import { AccountInfo } from "@azure/msal-browser";
import axios from "axios";

import AzureAuthenticationButton from "azure/azure-authentication-component";
import Button from "components/Button";
import { ModalBase } from "components/ModalGroup/ModalBase";
import { SimpleModal } from "../SimpleModal";
import { useAuth } from "hooks/auth";

import { Container } from "./styles";

import iconPeople from "images/icons/icon-people.svg";
import iconClose from "images/icons/icon-close-modal.png";
import iconError from "images/icons/icon-acess-denied.svg";
import { useState } from "react";

interface StoreData {
  codigo: number;
  nome: string;
}

interface ModalVerifyAccessProps {
  onRequestClose: () => void;
  confirm: () => void;
  lojaSelecionada: StoreData;
  isOpen: boolean;
}

export function ModalVerifyAccess({
  onRequestClose,
  confirm,
  lojaSelecionada,
  isOpen,
}: ModalVerifyAccessProps) {
  const { handleSignIn } = useAuth();

  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRequestAccess = async (
    userAccountInfo: AccountInfo | undefined
  ) => {
    setLoading(true);
    const matricula = userAccountInfo?.username.split("@")[0];

    try {
      if (matricula) {
        const hash = localStorage.getItem("@portalAdmin:hash");

        const response = await axios.get(
          `/Colaborador/SolicitarAcesso?codigoLoja=${lojaSelecionada.codigo}&matricula=${matricula}`,
          {
            headers: {
              pm_hash: hash,
            },
          }
        );
        if (response.data.result === "Error") {
          setModalErrorMessage(response.data.message);
          setIsModalErrorOpen(true);
          return;
        }
        const new_hash = response.data.hash;
        localStorage.setItem("@portalAdmin:hash", new_hash);
        confirm();
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBase isOpen={isOpen} onRequestClose={onRequestClose}>
        <Container>
          <img
            src={iconClose}
            alt="close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            onClick={onRequestClose}
          />
          <img src={iconPeople} alt="Ícone de pessoas" />
          <h2>Conceder autorização</h2>
          <p>
            Agora basta clicar em <strong>“Verificar”</strong> para conferir se
            o seu acesso foi liberado ou em <strong>“Autorizar”</strong> caso
            queira tentar novamente.
          </p>
          <div className="container__buttons">
            {loading && (
              <div>
                <Button width="125px">Carregando...</Button>
              </div>
            )}
            {!loading && (
              <AzureAuthenticationButton
                buttonText="Autorizar"
                onAuthenticated={handleRequestAccess}
              />
            )}
            <Button width="119px" onClick={confirm}>
              Verificar
            </Button>
          </div>
        </Container>
      </ModalBase>

      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => setIsModalErrorOpen(false)}
        icon={iconError}
        title="Erro"
        marginTitle="16px"
        message={modalErrorMessage}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="32px"
      />
    </>
  );
}
