import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
      cursor: pointer;
    }

    p {
      font-family:  'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px !important;
      color: #444C56;
      margin: 0 !important;
    }
  }

  div {
    margin-left: 19px;
    height: 40px;
    width: 304px;
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;

    display: flex;
    align-items: center;

    padding: 7px 8.11px;

    input {
      flex: 1;

      &::placeholder {
        color: ${colors.gray.blue};
      }
    }

    svg {
      margin: 8px 2.89px;
    }
  }
`;

export const P = styled.p`
  font-family: Source Sans Pro;
  font-style: bold !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #444C56 !important;
  margin: 0 !important;
  margin-left: 15px !important;
  margin-right: 5px !important;  
`;

export const Button = styled.button`
  margin-left: 15px;
  margin-right: 5px;
  justify-content: center;
  alignitems: center;
  background-color: transparent;
  height: 40px;
  width: 150px;
  color: green;
  font-family: Source Sans Pro;
  border-radius: 4px;
  font-style: bold !important;
  font-size: 14px !important;
`;
