import styled from 'styled-components';

import { colors } from "../../tokens";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 25px;
  }
`;