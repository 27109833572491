import axios from "axios";
import { useEffect, useState } from "react";

import { Grid } from "components/Grid";
import Button from "components/Button";
import { ModalBase } from "components/ModalGroup/ModalBase";

import {
  CloseModal,
  Container,
  ContainerTableDetails,
  ContainerTableDetailsCEP,
  ContainerButton,
} from "./styles";

import closeModalImg from "images/icons/icon-close-modal.png";

interface ServiceLinkStoreDetailsProps {
  onRequestClose: () => void;
  isOpen: boolean;
  codigoLoja: number;
}

interface Loja {
  codigo: number;
  nome: string;
  endereco: string;
  numero: string;
  bairro: string;
  cep: string;
  cidade: string;
  estado: string;
}
interface Servico {
  id: number;
  codigo: string;
  servico: string;
}
export function ServiceLinkStoreDetailsModal({
  onRequestClose,
  isOpen,
  codigoLoja,
}: ServiceLinkStoreDetailsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGrid, setIsLoadingGrid] = useState(false);
  const [services, setServices] = useState<Servico[]>([]);
  const [loja, setLoja] = useState({} as Loja);

  useEffect(() => {
    setServices([]);
    if (codigoLoja !== 0) {
      setIsLoading(true);
      axios.get(`/LojaData/GetLoja/${codigoLoja}`).then((response) => {
        if (response.data.result === "Success") {
          setLoja(response.data.loja);
        }
        setIsLoading(false);
      });
      setIsLoadingGrid(true);
      axios.get(`/LojaData/GetServicos/${codigoLoja}`).then((response) => {
        if (response.data.result === "Success") {
          let servicos: Servico[] = [];
          response.data.items.map((item: any) => {
            let servico = {} as Servico;
            servico.id = item.id;
            servico.codigo = item.codProduto;
            servico.servico = item.nomeAbreviado;
            servicos.push(servico);
          });
          setServices(servicos);
        }
        setIsLoadingGrid(false);
      });
    }
  }, [codigoLoja]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <CloseModal onClick={onRequestClose}>
        <img src={closeModalImg} alt="Fechar Modal" />
      </CloseModal>

      <Container>
        <h2>Detalhes da loja</h2>
        {isLoading && <p>Carregando...</p>}

        {!isLoading && (
          <>
            <ContainerTableDetails>
              <table>
                <thead>
                  <tr>
                    <th>Identificação da loja</th>
                    <th>Código</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{loja?.nome}</td>
                    <td>{loja?.codigo}</td>
                  </tr>
                </tbody>
              </table>
            </ContainerTableDetails>

            <ContainerTableDetails>
              <table>
                <thead>
                  <tr>
                    <th>Endereço</th>
                    <th>Bairro</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {loja?.endereco}, {loja?.numero}{" "}
                    </td>
                    <td>{loja?.bairro}</td>
                  </tr>
                </tbody>
              </table>
            </ContainerTableDetails>

            <ContainerTableDetailsCEP>
              <table>
                <thead>
                  <tr>
                    <th>Cidade</th>
                    <th>Estado</th>
                    <th>CEP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{loja?.cidade}</td>
                    <td>{loja?.estado}</td>
                    <td>{loja?.cep}</td>
                  </tr>
                </tbody>
              </table>
            </ContainerTableDetailsCEP>
          </>
        )}

        <p className="gridTitle">Serviços vinculados a esta loja</p>

        {isLoadingGrid && <p>Carregando...</p>}
        {!isLoadingGrid && (
            <Grid
              columns={["Codigo", "Serviços"]}
              columnsWidth={[
                {
                  column: "Codigo",
                  width: "97px",
                },
                {
                  column: "Serviços",
                  width: "346px",
                },
              ]}
              omit={["id", "status"]}
              data={services}
              maxHeight="220px"
              minHeight="220px"
              className="custom-display-grid"
            />
        )}

        <ContainerButton>
          <Button onClick={onRequestClose} inverted width="132px">
            Fechar
          </Button>
        </ContainerButton>
      </Container>
    </ModalBase>
  );
}
