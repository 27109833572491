import { Container } from "./styles";

interface BadgeProps {
  type: "active" | "inactive";
  text: string;
}

export function Badge({ type, text }: BadgeProps) {
  return (
    <Container type={type}>
      <p>{text}</p>
    </Container>
  );
}
