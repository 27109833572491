import { useHistory } from "react-router-dom";
import axios from "axios";

import SelectDropdown from "components/SelectDropdown";
import { SingleHourBox } from "components/SingleHourBox";
import { Input } from "components/Input";
import { TextArea } from "components/TextArea";
import { UploadImage } from "components/UploadImage";

import IconService1 from "../../assets/IconService1.svg";
import IconService2 from "../../assets/IconService2.svg";
import IconService3 from "../../assets/IconService3.svg";
import IconService4 from "../../assets/IconService4.svg";
import IconService5 from "../../assets/IconService5.svg";
import IconService6 from "../../assets/IconService6.svg";
import IconService7 from "../../assets/IconService7.svg";
import IconService8 from "../../assets/IconService8.svg";
import IconService9 from "../../assets/IconService9.svg";
import IconService10 from "../../assets/IconService10.svg";
import IconService11 from "../../assets/IconService11.svg";
import IconService12 from "../../assets/IconService12.svg";
import IconService13 from "../../assets/IconService13.svg";
import IconService14 from "../../assets/IconService14.svg";
import IconService15 from "../../assets/IconService15.svg";
import IconService16 from "../../assets/IconService16.svg";
import IconService17 from "../../assets/IconService17.svg";
import IconService18 from "../../assets/IconService18.svg";

import {
  Container,
  Form,
  FormRow,
  Codigo,
  Nome,
  Abreviacao,
  Subtitulo,
  Status,
  Categoria,
  Icone,
  TempoExecucao,
  Resultado,
  Preparo,
  Coleta,
  PreRequisito,
  Descricao,
  Recomendacao,
  ButtonsContainer,
  ButtonCancel,
  ButtonSave,
  SelectIcons,
  Overlay,
} from "./styles";

import SelectIcon from "images/icons/icon-select-option.svg";

import { useEffect, useRef, useState } from "react";

import { FormHandles } from "@unform/core";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import { useLoader } from "hooks/loader";

import successImg from "images/arrowSuccess.png";
import InputMultiSelect, { ItemData } from "components/InputMultiSelect";

interface IconProps {
  id: number;
  icon: string;
}

export function RegisterService() {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { handleSetIsLoader } = useLoader()

  const [icon, setIcon] = useState({} as IconProps);
  const [openSelectIcon, setOpenSelectIcon] = useState(false);

  const [id, setId] = useState(0);
  const [codProduto, setCodProduto] = useState("");
  const [nome, setNome] = useState("");
  const [nomeAbreviado, setNomeAbreviado] = useState("");
  const [horaExecucao, setHoraExecucao] = useState("");
  const [resultado, setResultado] = useState("");
  const [preparo, setPreparo] = useState("");
  const [preRequisito, setPrerequisito] = useState("");
  const [coleta, setColeta] = useState("");
  const [descricao, setDescricao] = useState("");
  const [recomendacao, setRecomendacao] = useState("");
  const [subtitulo, setSubtitulo] = useState("");
  const [status, setStatus] = useState("");
  const [categorias, setCategorias] = useState<ItemData[]>([]);
  const [subcategorias, setSubcategorias] = useState<ItemData[]>([]);
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState<ItemData[]>([]);
  const [subcategoriasSelecionadas, setSubcategoriasSelecionadas] = useState<ItemData[]>([]);
  const [urlImg, setUrlImg] = useState("");
  const [nomeImg, setNomeImg] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);

  useEffect(() => {
    handleSetIsLoader(true);
    setIsLoading(true);

    axios.get('/servicoData/GetAllCategoria').then(response => {
      setCategorias(response.data.items);
    });
    axios.get('/servicoData/GetAllSubCategoria').then(response => {
      setSubcategorias(response.data.items);
    });

    let searchParams = new URLSearchParams(history.location.search);
    let _id = searchParams.get("id");

    if (_id !== null) {
      axios.get(`/servicoData/${_id}`).then((response: any) => {
        if (response.data.result === "Success") {
          setId(response.data.servico.id);
          setCodProduto(response.data.servico.codProduto);
          setNome(response.data.servico.nome);
          setNomeAbreviado(response.data.servico.nomeAbreviado);
          setHoraExecucao(response.data.servico.horaExecucao);
          setPreparo(response.data.servico.preparo);
          setPrerequisito(response.data.servico.preRequisito);
          setDescricao(response.data.servico.descricao);
          setRecomendacao(response.data.servico.restricao);
          setStatus(response.data.servico.ativo ? "active" : "inactive");

          setSubtitulo(response.data.servico.subTitulo);
          setColeta(response.data.servico.coletaMaterial);
          setResultado(response.data.servico.tempoResultado);

          setCategoriasSelecionadas(response.data.servico.categorias.map(
            (categoria: number) => ({ id: categoria })
          ));
          setSubcategoriasSelecionadas(response.data.servico.subcategorias.map(
            (subcategoria: number) => ({ id: subcategoria })
          ));

          if (response.data.servico.icone !== null) {
            var _icone = icons.filter(function (x: any) {
              return x.id == response.data.servico.icone;
            });
            if (_icone.length > 0) {
              let icon = {} as IconProps;
              icon.id = _icone[0].id;
              icon.icon = _icone[0].icon;
              setIcon(icon);
            }
          }
          setUrlImg(response.data.servico.urlImg);
          setNomeImg(response.data.servico.nomeImg);
          handleSetIsLoader(false);
          setIsLoading(false);
        }
      });
    } else {
      handleSetIsLoader(false);
      setIsLoading(false);
    }
  }, []);

  function handleSelectIcons() {
    setOpenSelectIcon((preventState) => !preventState);
  }

  function handleCloseSelectIcons(icon: IconProps) {
    setOpenSelectIcon(false);
    setIcon(icon);
  }

  function handleSetHoraExecucao(executionTime: string) {
    setHoraExecucao(executionTime);
  }

  function handleSetResultado(resultado: string) {
    setResultado(resultado);
  }

  function onFocusOutCodProduto(event: any) {
    if (event.target.value === "") {
      setCodProduto("");
      setNome("");
      formRef?.current?.setFieldError("codProduto", "");
    }
    if (codProduto !== "") {
      handleSetIsLoader(true);
      axios
        .get(`servicoData/GetProduto/${codProduto}`)
        .then((response: any) => {
          if (response.data.result === "Success") {
            setCodProduto(response.data.produto.codProduto);
            setNome(response.data.produto.nome);
            formRef?.current?.setFieldError("codProduto", "");
          } else {
            setCodProduto("");
            setNome("");
            formRef?.current?.setFieldError("codProduto", "");

            if (response.data.code === "001") {
              formRef?.current?.setFieldError(
                "codProduto",
                "Erro desconhecido"
              );
              return;
            }

            formRef?.current?.setFieldError(
              "codProduto",
              response.data.message
            );
          }
        });
      handleSetIsLoader(false);
    }
  }

  function onChangeCodProduto(event: any) {
    setCodProduto(event.target.value);
    validateForm();
  }

  function onChangeSubtitulo(event: any) {
    setSubtitulo(event.target.value);
    validateForm();
  }

  function onChangeNomeAbreviado(event: any) {
    setNomeAbreviado(event.target.value);
    validateForm();
  }
  function onChangeResultado(event: any) {
    setResultado(event.target.value);
    validateForm();
  }
  function onChangePreparo(event: any) {
    setPreparo(event.target.value);
    validateForm();
  }
  function onChangePreRequisito(event: any) {
    setPrerequisito(event.target.value);
    validateForm();
  }

  function onChangeDescricao(event: any) {
    setDescricao(event.target.value);
    validateForm();
  }

  function onChangeRecomendacao(event: any) {
    setRecomendacao(event.target.value);
    validateForm();
  }

  function onChangeColeta(event: any) {
    setColeta(event.target.value);
    validateForm();
  }

  const icons = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
  ];

  function validateForm() {
    const validCodProd = codProduto?.length > 0;
    const validNome = nome?.length > 0;
    const validNomeAbreviado = nomeAbreviado?.length > 0;
    const validHoraExecucao = horaExecucao !== "00:00";
    const validResultado = resultado?.length > 0;
    const validStatus = status?.length > 0;
    const validPreparo = preparo?.length > 0;
    const validDescricao = descricao?.length > 0;
    const validRecomendacao = recomendacao?.length > 0;
    const validSubtitulo = subtitulo?.length > 0;
    const validColeta = coleta?.length > 0;

    if (
      validCodProd &&
      validNome &&
      validNomeAbreviado &&
      validHoraExecucao &&
      validResultado &&
      validStatus &&
      validPreparo &&
      validDescricao &&
      validRecomendacao &&
      validSubtitulo &&
      validColeta
    )
      return true;
    else return false;
  }

  function saveForm(data: any) {
    if (!validateForm()) return;
    handleSetIsLoader(true);

    const _data = {
      id: id,
      nome: data.nome,
      nomeAbreviado: data.nomeAbreviado,
      descricao: data.descricao,
      preparo: data.preparo,
      restricao: data.recomendacao,
      ativo: data.status === "active",
      codProduto: data.codProduto,
      horaServico: "00:00",
      coletaMaterial: data.coleta,
      horaExecucao: data.tempoExecucao,
      tempoResultado: data.resultado,
      preRequisito: data.preRequisito,
      iconeId: icon.id,
      subTitulo: data.subtitulo,
      categorias: categoriasSelecionadas.map(function (elem) { return elem.id; }),
      subcategorias: subcategoriasSelecionadas.map(function (elem) { return elem.id; }),
      urlImg: urlImg,
      nomeImg: nomeImg,
    };

    let url = "/ServicoData/" + (id !== 0 ? "" + id : "");
    fetch(url, {
      method: id === 0 ? "POST" : "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          handleSetIsLoader(false)
          alert(data.message);
        } else if (data.result === "Success") {
          handleSetIsLoader(false)
          setIsModalSuccessOpen(true);
        } else {
          handleSetIsLoader(false)
          alert("Erro back");
        }
      })
      .catch((error) => {
        handleSetIsLoader(false)
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  return (
    <Container>
      {isLoading && <p>Carregando...</p>}
      {!isLoading && (
        <>
          {!id && <p>CADASTRAR NOVO SERVIÇO</p>}
          {!!id && <p>EDITAR SERVIÇO</p>}
          <Form onSubmit={(data) => saveForm(data)} ref={formRef} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <FormRow>
              <Codigo>
                <label>Código do produto</label>
                <Input
                  name="codProduto"
                  placeholder="000000"
                  value={codProduto}
                  onChange={onChangeCodProduto}
                  onBlur={onFocusOutCodProduto}
                  disabled={!!id}
                />
              </Codigo>

              <Nome>
                <label>Nome</label>
                <Input
                  name="nome"
                  placeholder="Nome do serviço"
                  value={nome}
                  disabled
                />
              </Nome>
            </FormRow>

            <FormRow>
              <Icone>
                <label>Icone</label>
                <div onClick={() => handleSelectIcons()} id="divIcon">
                  {icon.icon ? (
                    <>
                      <img src={icon.icon} alt="Icon" id="iconSelected" />
                    </>
                  ) : (
                    <span>Selecione</span>
                  )}
                  <Input name="icon" value={icon.id || 0} hidden disabled />
                  <img src={SelectIcon} alt="select/option" id="selection" />
                </div>
                {openSelectIcon && (
                  <>
                    <Overlay onClick={() => setOpenSelectIcon(false)} />
                    <SelectIcons>
                      {icons.map((icon) => (
                        <img
                          key={icon.id}
                          src={icon.icon}
                          alt="icon"
                          onClick={() => handleCloseSelectIcons(icon)}
                        />
                      ))}
                    </SelectIcons>
                  </>
                )}
              </Icone>

              <Abreviacao>
                <label>Abreviação</label>
                <Input
                  name="nomeAbreviado"
                  placeholder="Abreviação completa 23."
                  onChange={onChangeNomeAbreviado}
                  value={nomeAbreviado}
                  maxLength={30}
                />
              </Abreviacao>

              <Subtitulo>
                <label>Subtítulo</label>
                <Input
                  name="subtitulo"
                  placeholder="Insira o texto"
                  onChange={onChangeSubtitulo}
                  value={subtitulo}
                  inputPadding="7px 15px 7px 15px"
                  maxLength={32}
                />
              </Subtitulo>
            </FormRow>

            <FormRow>
              <Categoria>
                <InputMultiSelect
                  itemsList={categorias}
                  itemsSelected={categoriasSelecionadas}
                  handleSelectItem={setCategoriasSelecionadas}
                  label="Categoria"
                  placeholder="Selecione uma categoria"
                />
              </Categoria>

              <Categoria>
                <InputMultiSelect
                  itemsList={subcategorias}
                  itemsSelected={subcategoriasSelecionadas}
                  handleSelectItem={setSubcategoriasSelecionadas}
                  label="Sub categoria"
                  placeholder="Selecione uma sub categoria"
                />
              </Categoria>
            </FormRow>

            <FormRow>
              <Descricao>
                <label>Descrição</label>
                <TextArea
                  name="descricao"
                  placeholder="Insira aqui a descrição"
                  onChange={onChangeDescricao}
                  value={descricao}
                  maxLength={120}
                />
              </Descricao>

              <TempoExecucao>
                <label>Tempo de execução</label>
                <SingleHourBox
                  hora={horaExecucao}
                  setExecutionTime={handleSetHoraExecucao}
                />
                <Input
                  name="tempoExecucao"
                  value={horaExecucao}
                  disabled
                  hidden
                />
              </TempoExecucao>
            </FormRow>

            <FormRow>
              <Resultado>
                <label>Resultado</label>
                <TextArea
                  name="resultado"
                  placeholder="Insira o texto"
                  onChange={onChangeResultado}
                  value={resultado}
                  maxLength={20}
                />
              </Resultado>

              <Preparo>
                <label>Preparo</label>
                <TextArea
                  name="preparo"
                  placeholder="Insira o texto"
                  onChange={onChangePreparo}
                  value={preparo}
                  maxLength={120}
                />
              </Preparo>
            </FormRow>

            <FormRow>
              <Coleta>
                <label>Coleta</label>
                <Input
                  name="coleta"
                  placeholder="Insira o texto"
                  onChange={onChangeColeta}
                  value={coleta}
                  maxLength={100}
                />
              </Coleta>

              <Recomendacao>
                <label>Recomendações</label>
                <Input
                  name="recomendacao"
                  placeholder="Limitado a 255 caracteres"
                  onChange={onChangeRecomendacao}
                  value={recomendacao}
                  maxLength={255}
                />
              </Recomendacao>
            </FormRow>

            <FormRow>
              <PreRequisito>
                <label>Pré-requisito</label>
                <TextArea
                  name="preRequisito"
                  placeholder="Pré-requisitos descritos em até 1500 caracteres"
                  onChange={onChangePreRequisito}
                  value={preRequisito}
                  maxLength={1500}
                />
              </PreRequisito>
            </FormRow>

            <FormRow style={{ justifyContent: 'flex-end' }}>
              <Status>
                <label>Status</label>
                <SelectDropdown
                  name="status"
                  placeholder="Selecione"
                  newValue={{
                    label: status === "active" ? "Ativado" : "Inativado",
                    value: status,
                  }}
                  onChange={(status: any) => setStatus(status.value)}
                  options={[
                    { value: "active", label: "Ativado" },
                    { value: "inactive", label: "Inativado" },
                  ]}
                />
              </Status>
            </FormRow>

            <FormRow>
              <UploadImage url={urlImg} nome={nomeImg}
                handleUrlImg={(url: any) => setUrlImg(url)}
                handleNomeImg={(nome: any) => setNomeImg(nome)} />
            </FormRow>

            <ButtonsContainer>
              <ButtonCancel
                type="button"
                onClick={() => history.push("/portaladmin/servico")}
              >
                Cancelar
              </ButtonCancel>
              <ButtonSave type="submit" disabled={!validateForm()}>
                Salvar
              </ButtonSave>
            </ButtonsContainer>
          </Form>

          <SimpleModal
            isOpen={isModalSuccessOpen}
            onRequestClose={() => setIsModalSuccessOpen(false)}
            confirm={() => {
              setIsModalSuccessOpen(false);
              history.push("/portaladmin/servico");
            }}
            icon={successImg}
            title="Sucesso"
            message={
              id
                ? "Todas as alterações foram salvas."
                : "Serviço cadastrado com sucesso."
            }
            continueButtonText="Continuar"
            buttonsMarginTop="17.34px"
          />
        </>
      )}
    </Container>
  );
}
