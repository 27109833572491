import styled from "styled-components";

import { colors } from "../../../tokens";

export const DashboardContainer = styled.div`
  margin-right: 20px;

  button {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  font-family: "Source Sans Pro";
  width: 100%;

  h2 {
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark};
    margin-bottom: 16px;
  }

  .contentBody {
    position: relative;
    border: 1px solid ${colors.gray.light};
    border-radius: 4px;
    margin-bottom: 2rem;

    .p-card-body {
      .p-card-content {
        padding: 0;
        overflow: hidden;
        height: max-content;
      }
    }

    .tabHeader {
      display: flex;
      border-bottom: 1px solid ${colors.gray.light};
    }

    .form {
      margin-top: 1.5rem;

      label {
        margin-bottom: 4px;
      }

      .required label:first-child::after {
        content: " (obrigatório)";
        color: ${colors.gray.blue};
      }

      input {
        &:disabled {
          cursor: not-allowed;
          background: #d7e2ed;
        }
      }

      .formRow {
        display: flex;
        height: fit-content;

        & + .formRow {
          margin-top: 24px;
        }

        > div + div {
          margin-left: 24px;
        }

        #nome {
          width: 861px;
        }

        #inicio,
        #termino,
        #tipoCadastro {
          width: 253px;
        }

        #tipoBeneficio {
          width: 307px;
        }

        #urlConsulta {
          width: 584px;
        }
      }
    }
  }

  .custom-btn-remove {
    padding: 0.438rem 0;
    border: 1px solid transparent;
  }

  .contentFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button:disabled {
      background: #f2f2f2;
      color: ${colors.gray.text};
      cursor: not-allowed;
    }

    button + button {
      margin-left: 24px;
    }
  }

  .p-field-radiobutton {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .my-steps {
    margin: 1rem auto 2rem auto;
  }

  .buttons-interation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    grid-gap: 30%;

    &.first-step {
      display: flex;
      width: 100%;
      flex: 1;
      grid-template-columns: unset;
      margin: 0;

      button {
        width: 100%;
      }
    }

    button {
      &.p-button-outlined {
        color: ${colors.blue};
      }
    }
  }

  .resume-step {
    h3 {
      margin-bottom: 0;
    }

    .grid-resumo {
      grid-row-gap: 7px;
    }
    
    .content-label {
      label {
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    .content-loja{
      padding: 1rem;
    }
  }
`;
