import "./storesSelect.scss";
import { SelectBox as Select } from "devextreme-react/select-box";
import { usePartnerContext } from "../../contexts/partnerContextProvider";
import { useEffect, useState } from "react";
import { partnerScheduleControlService } from "../../services/partnerScheduleControlService";
import { toast } from "react-toastify";
import { useRouter } from "hooks/useRouter";
import Swal from "sweetalert2";

export default function StoresSelect({ setScheduleData }) {
  const { isLoading, setIsLoading } = usePartnerContext();
  const [partnerStates, setPartnerStates] = useState<any[]>([]);
  const [selectedState] = useState("");
  const [partnerStores, setPartnerStores] = useState([]);
  const router = useRouter();
  const { l } = router.query as any;
  const partnerId = 0;
  let storeId = parseInt(`${l}`);

  const handlePartnerStoreSchedules = async () => {
    setIsLoading(true);

    const partnerStoresData =
      await partnerScheduleControlService.acquirePartnerStoreSchedules(
        partnerId,
        storeId
      );

    const { items: schedules } = partnerStoresData;

    const items = schedules.map((s) => {
      return {
        ...s,
        partnerId,
      };
    });

    if (items !== null) {
      setScheduleData(items);
      storeId = parseInt(`${items.cdLoja}`);
    } else {
      toast.error("Ocorreu um erro ao buscar as informações da agenda.");
    }

    setIsLoading(false);
  };

  async function handlePartnerStates() {
    setIsLoading(true);

    const partnerStatesData =
      await partnerScheduleControlService.acquirePartnerStates(partnerId, true);

    const { items } = partnerStatesData;

    if (items && items.length) {
      setPartnerStates(items);
      setIsLoading(false);
    } else {
      toast.error("Ocorreu um erro ao buscar os estados.");
      setIsLoading(false);
    }
  }

  async function handlePartnerStores(stateUF = null) {
    setIsLoading(true);

    const partnerStoresData =
      await partnerScheduleControlService.acquirePartnerStores(
        partnerId,
        stateUF,
        true
      );

    const { items } = partnerStoresData;

    if (items && items.length) {
      setPartnerStores(items);

      if (storeId > 0) {
        handlePartnerStoreSchedules();
      } else {
        setIsLoading(false);
      }
    } else {
      toast.error("Ocorreu um erro ao buscar as lojas.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handlePartnerStates();
    handlePartnerStores();
  }, []);

  const onValueChanged = (e) => {
    if (e.value > 0) {
      setIsLoading(true);
      window.location.href = `/portaladmin/agenda?l=${e.value}`;
    }
  };

  const onStateSelected = (e) => {
    handlePartnerStores(e.value);
  };

  const handleDisplayName = (e) => {
    if (e === null) return "";

    return `${e?.cdLoja} - ${e?.nomeLoja}`;
  };

  const handleInsertNewRoom = async () => {
    const handleText = async () => {
      const { value: text } = await Swal.fire({
        input: "text",
        inputLabel: "Inclusão de nova sala",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Abandonar",
        cancelButtonColor: "#ed1d24",
        inputPlaceholder: "Nome da nova sala...",
        inputAttributes: {
          "aria-label": "Nome da nova sala...",
        },
        reverseButtons: true,
        allowOutsideClick: false,
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value && value.trim().length) {
              resolve(null);
            } else {
              resolve("Por favor, informe o nome para a nova sala...");
            }
          });
        },
      });

      return text || null;
    };

    const roomName = await handleText();

    if (roomName === null) return;

    setIsLoading(true);
    const addRoom = await partnerScheduleControlService.insertNewRoom(
      roomName,
      storeId
    );

    const { id } = addRoom;

    if (id >= 1) {
      const linkRoom = await partnerScheduleControlService.linkStoreToRoom(
        id,
        storeId
      );

      setIsLoading(false);

      if (linkRoom) {
        Swal.fire({
          title: "Cadastro de Sala",
          text: "Nova sala incluída com sucesso.",
          icon: "success",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        }).then(() => {
          window.location.reload();
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <div className="partnerStoresSelect">
      <div className="partnerStoresSelect__item">
        <span className="partnerStoresSelect__item__label">
          Filtrar lojas por estado
        </span>

        <div title="Estado">
          <Select
            disabled={partnerStates.length === 1 || isLoading}
            id="partnerStateSelectBox"
            noDataText="Nada para exibir"
            placeholder={
              partnerStates.length === 1
                ? partnerStates[0].sigla
                : "Selecione o estado"
            }
            dataSource={partnerStates}
            defaultValue={selectedState}
            displayExpr="descricao"
            valueExpr={"sigla"}
            onValueChanged={onStateSelected}
            searchEnabled={true}
            searchMode="contains"
            searchExpr={"descricao"}
            searchTimeout={500}
            showDataBeforeSearch={false}
          />
        </div>
      </div>

      <div className="partnerStoresSelect__item">
        <span className="partnerStoresSelect__item__label">Lojas</span>

        <div title="Lojas">
          <Select
            id="partnerStoresSelectBox"
            disabled={isLoading}
            noDataText="Nada para exibir"
            placeholder="Selecione a loja"
            dataSource={partnerStores}
            defaultValue={storeId}
            displayExpr={(e) => handleDisplayName(e)}
            valueExpr={"cdLoja"}
            onValueChanged={onValueChanged}
            searchEnabled={true}
            searchMode="contains"
            searchExpr={"nomeLoja"}
            searchTimeout={500}
            showDataBeforeSearch={false}
          />
        </div>
      </div>

      <button
        onClick={() => handleInsertNewRoom()}
        className="addRoomBtn"
        disabled={isNaN(storeId)}
      >
        Incluir nova sala
      </button>

      <button onClick={() => router.replace("home")} className="backBtn">
        Voltar
      </button>
    </div>
  );
}
