import axios from "axios";
import { Calendar } from "components/Calendar";
import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import { Grid, StatusIconMapProps } from "components/Grid";
import { GridFilter } from "components/GridFilter";
import Header from "components/Header";
import Main from "components/Main";
import { AgendaBlockRelease } from "components/ModalGroup/AgendaBlockRelease";
import { MotivoBloqueioLiberacao } from "components/ModalGroup/MotivoBloqueioLiberacao";
import { ServiceFilterModal } from "components/ModalGroup/ServiceFilterModal";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { NoResults } from "components/NoResults";
import { PeriodCard } from "components/PeriodCard";
import { useStore } from "hooks/store";
import { useLoader } from "hooks/loader";
import IconAction from "images/icons/icon-action.svg";
import IconAlert2 from "images/icons/icon-alert2.svg";
import IconBlock from "images/icons/icon-block.svg";
import iconStatusCanceled from "images/icons/icon-canceled.svg";
import iconStatusConfirmed from "images/icons/icon-confirmed.svg";
import iconStatusNext from "images/icons/icon-next.svg";
import IconRelese from "images/icons/icon-release.svg";
import IconSucess from "images/icons/icon-sucess.svg";
import IconError from "images/iconError.png";
import IconError2 from "images/icons/icon-block.svg";
import { ReactNode, useEffect, useState } from "react";
import { colors } from "tokens";
import { debounce } from "utils/debounce";
import IconService1 from "../../assets/IconService1.svg";
import IconService10 from "../../assets/IconService10.svg";
import IconService11 from "../../assets/IconService11.svg";
import IconService12 from "../../assets/IconService12.svg";
import IconService13 from "../../assets/IconService13.svg";
import IconService14 from "../../assets/IconService14.svg";
import IconService15 from "../../assets/IconService15.svg";
import IconService16 from "../../assets/IconService16.svg";
import IconService17 from "../../assets/IconService17.svg";
import IconService18 from "../../assets/IconService18.svg";
import IconService2 from "../../assets/IconService2.svg";
import IconService3 from "../../assets/IconService3.svg";
import IconService4 from "../../assets/IconService4.svg";
import IconService5 from "../../assets/IconService5.svg";
import IconService6 from "../../assets/IconService6.svg";
import IconService7 from "../../assets/IconService7.svg";
import IconService8 from "../../assets/IconService8.svg";
import IconService9 from "../../assets/IconService9.svg";
import {
  ActionAgenda,
  CalendarContainer,
  Container,
  Content,
  DashboardContainer,
  Overlay,
} from "./styles";
import {
  isAfter,
  isBefore,
  isEqual,
  addHours,
  addMinutes as addMinutesDateFns,
  formatDistance,
} from "date-fns";
import pt from "date-fns/locale/pt";
import { SchedulingDetailsModal } from "components/ModalGroup/SchedulingDetailsModal";
import { MotivoCancelamento } from "components/ModalGroup/MotivoCancelamento";
import { convert, addMinutes } from "utils";
import { useRescheduling } from "hooks/rescheduling";

interface PeriodProps {
  name: "" | "manha" | "tarde" | "noite";
  hour: string;
}

interface ModalDataProps {
  dateInicial: string;
  dateFinal: string;
  idPeriodo: number;
  diaSemana: string;
  horaInicial: string;
  horaFinal: string;
}

interface AgendamentoResponse {
  id: number;
  idAgendamento: number;
  codigoLoja: number;
  status: number;
  loja: string;
  data: string;
  hora: string;
  servicos: Servico[];
}

interface AgendamentoGrid {
  id: number;
  idAgendamento: number;
  status: number;
  codigoLoja: number;
  loja: string;
  data: string;
  hora: string;
  idServicos: number[];
  servicos: string;
  nomeServicos: string;
}

interface Servico {
  id: number;
  nome: string;
  nomeAbreviado: string;
  total: number;
  icone: number;
}

interface LojaServicosData {
  result: string;
  items: {
    agendamentos: AgendamentoResponse[];
    total: number;
    servicos: Servico[];
    totalManha: number;
    totalTarde: number;
    totalNoite: number;
  };
}

interface FilterData {
  ufSelected: string;
  cidadeSelected: string;
  bairroSelected: string;
  horarioSelected: string;
  servicoSelected: number;
}

interface CancelarProps {
  data: string;
  hora: string;
  idAgendamento: number;
}

export function AgendaBloqueioLiberacao() {
  const { handleRequestReschedule, setAgendamentoSelecionado } =
    useRescheduling();
  const { selectedStores, storeList } = useStore();
  const { handleSetIsLoader } = useLoader();

  const [selectedPeriod, setSelectedPeriod] = useState({
    name: "",
    hour: "",
  } as PeriodProps);
  const [openAction, setOpenAction] = useState(false);

  const [filter, setFilter] = useState({} as FilterData);

  const [isLoading, setIsLoading] = useState(false);

  const [
    isWarningExistsActiveAppointmentsModalOpen,
    setIsWarningExistsActiveAppointmentsModalOpen,
  ] = useState(false);
  const [isWarningSelectStoreModalOpen, setIsWarningSelectStoreModalOpen] =
    useState(false);
  const [isBlockScheduleModalOpen, setIsBlockScheduleModalOpen] =
    useState(false);
  const [isReleaseScheduleModalOpen, setIsReleaseScheduleModalOpen] =
    useState(false);
  const [isMotivoLiberacaoModalOpen, setIsMotivoLiberacaoModalOpen] =
    useState(false);
  const [
    isReleaseScheduleSuccessModalOpen,
    setIsReleaseScheduleSuccessModalOpen,
  ] = useState(false);
  const [isBlockScheduleSuccessModalOpen, setIsBlockScheduleSuccessModalOpen] =
    useState(false);
  const [hasAppointmentsActiveModalOpen, setHasAppointmentsActiveModalOpen] =
    useState(false);
  const [isModalSchedulingDetailsOpen, setIsModalSchedulingDetailsOpen] =
    useState(false);
  const [isModalMotivoCancelamentoOpen, setIsModalMotivoCancelamentoOpen] =
    useState(false);
  const [isModalCancelSchedulingSuccess, setIsModalCancelSchedulingSuccess] =
    useState(false);
  const [blockMotivo, setBlockMotivo] = useState(false);
  const [dateBlock, setDateBlock] = useState<string[]>([]);
  const [hourBlock, setHourBlock] = useState<string[]>([]);
  const [activeFlow, setActiveFlow] = useState<
    "bloqueio" | "cancelamento" | ""
  >("");
  const [isServiceFilterModalOpen, setIsServiceFilterModalOpen] =
    useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode>("");
  const [cancelamentoConfirmado, setCancelamentoConfirmado] = useState(false);

  const [errorAuthorization, setErrorAuthorization] = useState(false);

  const [selectedIdAgendamento, setSelectedIdAgendamento] = useState(0);
  const [idAgendamento, setIdAgendamento] = useState(0);

  const [filterDate, setFilterDate] = useState<Date[] | undefined>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [agendamentos, setAgendamentos] = useState<AgendamentoGrid[]>([]);
  const [filterText, setFilterText] = useState("");
  const [totalData, setTotalData] = useState(0);
  const pageSize = 8;

  const [totalManha, setTotalManha] = useState(0);
  const [totalTarde, setTotalTarde] = useState(0);
  const [totalNoite, setTotalNoite] = useState(0);

  const [modalData, setModalData] = useState<ModalDataProps>(
    {} as ModalDataProps
  );
  const [selectedDateInicial, setSelectedDateInicial] = useState<Date>(
    new Date()
  );
  const [selectedDateFinal, setSelectedDateFinal] = useState<Date>(new Date());

  const iconsServices = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
  ];

  useEffect(() => {
    fetchAgendamentos();
  }, [handleRequestReschedule]);

  function handleSetModalData({
    dateInicial,
    dateFinal,
    idPeriodo,
    diaSemana,
    horaInicial,
    horaFinal,
  }: ModalDataProps) {
    setModalData({
      dateInicial,
      dateFinal,
      idPeriodo,
      diaSemana,
      horaInicial,
      horaFinal,
    });
  }
  useEffect(() => {
    debounce(() => {
      fetchAgendamentos();
    }, 500);
  }, [
    currentPage,
    selectedStores,
    filterDate,
    selectedPeriod,
    filterText,
    filter,
  ]);

  function fetchAgendamentos() {
    let initialDate = !filterDate?.length ? filterDate : filterDate[0];
    let finalDate = !filterDate?.length ? filterDate : filterDate[1];

    const today = new Date();

    if (!String(initialDate).length && !String(finalDate).length) {
      initialDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      finalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      );

      setFilterDate([initialDate, finalDate]);
    }

    if (filterDate !== undefined) {
      setSelectedDateInicial(filterDate[0]);

      if (filterDate[1] !== undefined) setSelectedDateFinal(filterDate[1]);
      else {
        //filterDate[1] = filterDate[0];
        setSelectedDateFinal(filterDate[0]);
      }
    }

    const formattedInitialDate = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(String(initialDate)));

    const formattedFinalDate = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(
      finalDate !== undefined
        ? new Date(String(finalDate))
        : new Date(String(initialDate))
    );

    getAgendamentos(
      currentPage,
      pageSize,
      selectedStores.map((store) => String(store.codigo)),
      formattedInitialDate,
      formattedFinalDate,
      filterText,
      filter.ufSelected,
      filter.cidadeSelected,
      filter.bairroSelected,
      filter.servicoSelected
    );
  }

  function getAgendamentos(
    currentPage: number,
    pageSize: number,
    lojas: string[],
    dataInicial: string,
    dataFinal: string,
    search: string,
    uf: string,
    cidade: string,
    bairro: string,
    idServico: number
  ) {
    handleSetIsLoader(true);

    let startIndex = 0;

    if (currentPage > 1) {
      startIndex = (currentPage - 1) * pageSize;
    }

    if (lojas.length > 0 && lojas[0] !== "0") {
      if (!totalData) {
        setIsLoading(true);
      }

      if (storeList.length > 1 && selectedStores.length === storeList.length)
        lojas = [];

      axios
        .get<LojaServicosData>(
          `/AgendaData/GetAgendamentos?startIndex=${startIndex}&pageSize=${pageSize}&lojas=${lojas}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&periodo=${
            selectedPeriod.name
          }&search=${search}&uf=${filter.ufSelected || ""}&cidade=${
            filter.cidadeSelected || ""
          }&bairro=${filter.bairroSelected || ""}&idServico=${
            filter.servicoSelected || ""
          }&horario=${
            filter.horarioSelected === "Selecione" ||
            filter.horarioSelected === "Todos os horários"
              ? ""
              : filter.horarioSelected || ""
          }`
        )
        .then((response) => {
          if (response.data.items) {
            setTotalData(response.data.items.total);

            setTotalManha(response.data.items.totalManha);
            setTotalTarde(response.data.items.totalTarde);
            setTotalNoite(response.data.items.totalNoite);

            const formattedAppointments: AgendamentoGrid[] =
              response.data.items.agendamentos.map((agendamento) => ({
                id: agendamento.id,
                idAgendamento: agendamento.idAgendamento,
                status: agendamento.status,
                codigoLoja: agendamento.codigoLoja,
                /*data: new Intl.DateTimeFormat("pt-BR", {
                  timeZone: "UTC",
                  day: "2-digit",
                  month: "2-digit",
                  year: "2-digit",
                }).format(new Date(agendamento.data)),*/
                data: agendamento.data,
                hora: agendamento.hora,
                loja: agendamento.loja,
                idServicos: agendamento.servicos.map((servico) => servico.id),
                servicos: agendamento.servicos
                  .map((servico) => {
                    return `<img key=${servico.id} src=${
                      iconsServices.find((icon) => icon.id === servico.icone)
                        ?.icon
                    } alt="Serviço" style="cursor: pointer; margin-right: 10px" />`;
                  })
                  .join(""),
                nomeServicos: agendamento.servicos
                  .map((servico) => servico.nomeAbreviado)
                  .join("<br />"),
              }));
            handleSetIsLoader(false);
            setAgendamentos(formattedAppointments);
            setIsLoading(false);
          } else {
            setAgendamentos([]);
            handleSetIsLoader(false);
            setIsLoading(false);
          }
        });
    } else {
      setAgendamentos([]);
      handleSetIsLoader(false);
      setIsLoading(false);
    }
  }

  const statusIconMap: StatusIconMapProps[] = [
    {
      status: 1,
      icon: <img src={iconStatusNext} alt="Agenda Aberta" />,
    },
    {
      status: 2,
      icon: <img src={iconStatusConfirmed} alt="Agendamento confirmado" />,
    },
    {
      status: 3,
      icon: <img src={iconStatusCanceled} alt="Agenda Bloqueada" />,
    },
  ];

  function handleOpenAction() {
    setOpenAction((preventState) => !preventState);
  }

  function permitirCancelar({ data, hora, idAgendamento }: CancelarProps) {
    const newDate = data.split("/");
    const newHoras = hora.split(":");

    const date = new Date(`${newDate[1]}/${newDate[0]}/${newDate[2]}`);
    date.setHours(parseInt(newHoras[0]), parseInt(newHoras[1]));

    const dateNow = new Date();

    if (isAfter(dateNow, date)) {
      setErrorMessage(
        <p style={{ lineHeight: "1.49" }}>
          Não é possível solicitar um cancelamento após o horário do agendamento
          marcado.
        </p>
      );
      setErrorAuthorization(true);
      return;
    }

    let dateAux: Date = addHours(dateNow, 1);
    dateAux = addMinutesDateFns(dateAux, 59);

    if (!isBefore(dateAux, date) || isEqual(dateAux, date)) {
      setErrorMessage(() => {
        return (
          <>
            <span>
              Não é possível realizar um cancelamento faltando
              <b>
                {" "}
                {formatDistance(date, dateNow, {
                  includeSeconds: true,
                  locale: pt,
                })}
              </b>{" "}
              para o <br /> serviço marcado.
            </span>
          </>
        );
      });
      setErrorAuthorization(true);
      return;
    }
    setSelectedIdAgendamento(idAgendamento);
    setCancelamentoConfirmado(true);
  }

  function checkIfCurrentDateIsGreaterThanAppointmentDate(
    appointmentDate: string[],
    appointmentHour: string[]
  ) {
    const now = new Date();

    const currentDateFormatted = `${convert({
      dateString: Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(now),
      pattern: "yyyy-mm-dd",
    })} ${now.getHours()}:${now.getMinutes()}:00`;
    const appointmentDateFormatted = `${appointmentDate[0]} ${appointmentHour[0]}:00`;

    return isAfter(
      new Date(currentDateFormatted),
      new Date(appointmentDateFormatted)
    );
  }

  async function handleRequestRescheduleAppointment(appointmentData: any) {
    handleRequestReschedule(appointmentData);
  }

  function ActionButtonModalContent({ data }: { data: AgendamentoGrid }) {
    return (
      <>
        {data.status === 2 && (
          <span>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.167 0.125H0.833333C0.44375 0.125 0.125 0.44375 0.125 0.833333V12.167C0.125 12.5562 0.44375 12.875 0.833333 12.875H12.167C12.5562 12.875 12.875 12.5562 12.875 12.167V0.833333C12.875 0.44375 12.5562 0.125 12.167 0.125ZM4.375 2.95833H2.95833V4.375H4.375V2.95833ZM9.33333 4.375H6.5C6.11042 4.375 5.79167 4.05625 5.79167 3.66667C5.79167 3.27708 6.11042 2.95833 6.5 2.95833H9.33333C9.72292 2.95833 10.0417 3.27708 10.0417 3.66667C10.0417 4.05625 9.72292 4.375 9.33333 4.375ZM9.33333 7.20833H6.5C6.11042 7.20833 5.79167 6.88958 5.79167 6.5C5.79167 6.11042 6.11042 5.79167 6.5 5.79167H9.33333C9.72292 5.79167 10.0417 6.11042 10.0417 6.5C10.0417 6.88958 9.72292 7.20833 9.33333 7.20833ZM6.5 10.0417H9.33333C9.72292 10.0417 10.0417 9.72292 10.0417 9.33333C10.0417 8.94375 9.72292 8.625 9.33333 8.625H6.5C6.11042 8.625 5.79167 8.94375 5.79167 9.33333C5.79167 9.72292 6.11042 10.0417 6.5 10.0417ZM2.95833 5.79167H4.375V7.20833H2.95833V5.79167ZM4.375 8.625H2.95833V10.0417H4.375V8.625ZM1.54167 11.4583H11.4583V1.54167H1.54167V11.4583Z"
                fill="#0054A6"
              />
            </svg>
            <button
              style={{ color: "#0054A6" }}
              type="button"
              onClick={() => {
                setActiveFlow("cancelamento");
                setIdAgendamento(data.idAgendamento);
                setIsModalSchedulingDetailsOpen(true);
              }}
            >
              Ver detalhes
            </button>
          </span>
        )}
        {data.status === 2 && (
          <span>
            <svg
              width="20"
              style={{ marginLeft: "-3px" }}
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#F5AA07" }}
                fillRule="evenodd"
                d="M15.8333 3.33335H15V2.50002C15 2.04169 14.625 1.66669 14.1667 1.66669C13.7083 1.66669 13.3333 2.04169 13.3333 2.50002V3.33335H6.66667V2.50002C6.66667 2.04169 6.29167 1.66669 5.83333 1.66669C5.375 1.66669 5 2.04169 5 2.50002V3.33335H4.16667C3.24167 3.33335 2.50833 4.08335 2.50833 5.00002L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00002C17.5 4.08335 16.75 3.33335 15.8333 3.33335ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667H5C4.54167 16.6667 4.16667 16.2917 4.16667 15.8334V7.50002H15.8333V15.8334ZM7.5 10.8334V9.16669H5.83333V10.8334H7.5ZM9.16667 9.16669H10.8333V10.8334H9.16667V9.16669ZM14.1667 9.16669H12.5V10.8334H14.1667V9.16669Z"
              />
            </svg>

            <button
              style={{ color: "#F5AA07" }}
              type="button"
              onClick={() => {
                handleRequestRescheduleAppointment(data);
                setAgendamentoSelecionado({
                  idAgendamento: data.idAgendamento,
                  idServicos: data.idServicos,
                  loja: data.codigoLoja,
                });
              }}
            >
              Reagendar
            </button>
          </span>
        )}
        {(data.status === 1 || data.status === 2) && (
          <span>
            <svg
              width="16.67"
              height="16.67"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "5px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00001 0.666687C4.40001 0.666687 0.666672 4.40002 0.666672 9.00002C0.666672 13.6 4.40001 17.3334 9.00001 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 9.00001 0.666687ZM2.33334 9.00002C2.33334 5.31669 5.31667 2.33335 9.00001 2.33335C10.5417 2.33335 11.9583 2.85835 13.0833 3.74169L3.74167 13.0834C2.85834 11.9584 2.33334 10.5417 2.33334 9.00002ZM4.91667 14.2584C6.04167 15.1417 7.45834 15.6667 9 15.6667C12.6833 15.6667 15.6667 12.6834 15.6667 9.00002C15.6667 7.45835 15.1417 6.04169 14.2583 4.91669L4.91667 14.2584Z"
                style={{ fill: "#F14A50" }}
              />
            </svg>

            <button
              type="button"
              style={{ color: "#F14A50" }}
              onClick={() => {
                const newDateBlock = [
                  convert({ dateString: data.data, pattern: "yyyy-mm-dd" }),
                  convert({ dateString: data.data, pattern: "yyyy-mm-dd" }),
                ];
                const newHourBlock = [data.hora, data.hora];
                if (
                  checkIfCurrentDateIsGreaterThanAppointmentDate(
                    newDateBlock,
                    newHourBlock
                  )
                ) {
                  setErrorMessage("Data inferior que a data atual");
                  setIsErrorModalOpen(true);
                  return;
                }

                setActiveFlow("bloqueio");
                setDateBlock(newDateBlock);
                setHourBlock(newHourBlock);
                // setSelectedIdAgendamento(data.idAgendamento);
                setSelectedIdAgendamento(data.id);
                if (data.status === 2) {
                  setHasAppointmentsActiveModalOpen(true);
                  setIdAgendamento(data.idAgendamento);
                  return;
                }
                setBlockMotivo(true);
              }}
            >
              Bloquear agenda
            </button>
          </span>
        )}
        {data.status === 2 && (
          <span>
            <svg
              width="16.67"
              height="16.67"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00008 0.666668C4.39175 0.666668 0.666748 4.39167 0.666748 9C0.666748 13.6083 4.39175 17.3333 9.00008 17.3333C13.6084 17.3333 17.3334 13.6083 17.3334 9C17.3334 4.39167 13.6084 0.666668 9.00008 0.666668ZM10.5751 6.25001L9.00008 7.82501L7.42508 6.25001C7.26939 6.09397 7.05801 6.00627 6.83758 6.00627C6.61715 6.00627 6.40577 6.09397 6.25008 6.25001C5.92508 6.57501 5.92508 7.10001 6.25008 7.42501L7.82508 9.00001L6.25008 10.575C5.92508 10.9 5.92508 11.425 6.25008 11.75C6.57508 12.075 7.10008 12.075 7.42508 11.75L9.00008 10.175L10.5751 11.75C10.9001 12.075 11.4251 12.075 11.7501 11.75C12.0751 11.425 12.0751 10.9 11.7501 10.575L10.1751 9.00001L11.7501 7.42501C12.0751 7.10001 12.0751 6.57501 11.7501 6.25001C11.4251 5.93334 10.8917 5.93334 10.5751 6.25001ZM2.33341 9C2.33341 12.675 5.32508 15.6667 9.00008 15.6667C12.6751 15.6667 15.6667 12.675 15.6667 9C15.6667 5.325 12.6751 2.33333 9.00008 2.33333C5.32508 2.33333 2.33341 5.325 2.33341 9Z"
                style={{ fill: "#F14A50" }}
              />
            </svg>

            <button
              type="button"
              style={{ color: "#F14A50" }}
              onClick={() => {
                setActiveFlow("cancelamento");
                setSelectedIdAgendamento(data.id);
                setIdAgendamento(data.idAgendamento);
                permitirCancelar({
                  data: data.data,
                  hora: data.hora,
                  idAgendamento: data.idAgendamento,
                });
              }}
            >
              Cancelar agendamento
            </button>
          </span>
        )}
        {data.status === 3 && (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.025 9.20415L15.3 0.92915C15.95 0.27915 17 0.27915 17.6583 0.92915L18.825 2.09582C19.475 2.74582 19.475 3.80415 18.825 4.45415L8.2 15.0708C7.55 15.7208 6.49167 15.7208 5.84167 15.0708L1.175 10.4042C0.525 9.75415 0.525 8.69582 1.175 8.04582L2.34167 6.87915C2.99167 6.22915 4.05 6.22915 4.7 6.87915L7.025 9.20415ZM17.0583 3.86248C17.3833 3.53748 17.3833 3.02082 17.0583 2.69582C16.7333 2.37082 16.2167 2.37082 15.8917 2.69582L7.025 11.5625L4.10833 8.64582C3.78333 8.32082 3.26667 8.32082 2.94167 8.64582C2.78571 8.79986 2.69793 9.00994 2.69793 9.22915C2.69793 9.44836 2.78571 9.65844 2.94167 9.81248L6.44167 13.3125C6.76667 13.6375 7.28333 13.6375 7.60833 13.3125L17.0583 3.86248Z"
                style={{ fill: "#237B4B" }}
              />
            </svg>

            <button
              type="button"
              style={{ color: "#237B4B" }}
              onClick={() => {
                const newDateBlock = [
                  convert({ dateString: data.data, pattern: "yyyy-mm-dd" }),
                  convert({ dateString: data.data, pattern: "yyyy-mm-dd" }),
                ];
                const newHourBlock = [data.hora, data.hora];
                if (
                  checkIfCurrentDateIsGreaterThanAppointmentDate(
                    newDateBlock,
                    newHourBlock
                  )
                ) {
                  setErrorMessage("Data inferior que a data atual");
                  setIsErrorModalOpen(true);
                  return;
                }

                setDateBlock(newDateBlock);
                setHourBlock(newHourBlock);
                setSelectedIdAgendamento(data.id);
                setIsMotivoLiberacaoModalOpen(true);
              }}
            >
              Liberar agenda
            </button>
          </span>
        )}
      </>
    );
  }

  function existeAgendamento() {
    const _lojas = selectedStores.length
      ? selectedStores.map((store) => String(store.codigo))
      : [];
    axios
      .get(
        `/AgendaData/ExisteAgendamento?lojas=${_lojas}&dataInicial=${modalData.dateInicial}&dataFinal=${modalData.dateFinal}&horaInicial=${modalData.horaInicial}&horaFinal=${modalData.horaFinal}`
      )
      .then((response) => {
        //SE EXISTE, EXIBE MSG SE NÃO...
        if (response.data.result === "Success") {
          if (response.data.existeAgendamento) {
            setIsBlockScheduleModalOpen(false);
            setIsWarningExistsActiveAppointmentsModalOpen(true);
          } else {
            setIsBlockScheduleModalOpen(false);
            setBlockMotivo(true);
          }
        }
        /*/PODE SER QUE RETORNE ERRO INFORMANDO QUE EXISTE AGENDAMENTOS EM ABERTO
                if (data.result === "Error") {
                    if (data.code === "ja_existe_agendamentos") {
                        //setIsBlockScheduleModalOpen(false);
                        setBlockMotivo(false);
                        setIsWarningExistsActiveAppointmentsModalOpen(true);
                    } else {
                        //setIsBlockScheduleModalOpen(false);
                        setBlockMotivo(false);
                        setErrorMessage(data.message);
                        setIsErrorModalOpen(true);
                    }
                } else if (data.result === "Success") {
                    //setIsBlockScheduleModalOpen(false);
                    setBlockMotivo(false);
                    setIsBlockScheduleSuccessModalOpen(true);
                    //setBlockMotivo(true);
                } else {
                    setIsBlockScheduleModalOpen(false);
                    setErrorMessage(data.message);
                    setIsErrorModalOpen(true);
                }*/
      });
  }

  function bloquearAgenda(idMotivo: number, motivo: string) {
    handleSetIsLoader(true);
    const _lojas = selectedStores.length
      ? selectedStores.map((store) => String(store.codigo))
      : [];

    const novaDataInicial =
      new Date(dateBlock[0]) ||
      new Date(convert({ dateString: dateBlock[0], pattern: "yyyy-mm-dd" }));
    const novaDataFinal =
      new Date(dateBlock[1]) ||
      new Date(convert({ dateString: dateBlock[0], pattern: "yyyy-mm-dd" }));

    const dataInicialFormatada = Intl.DateTimeFormat("pt-BR", {
      timeZone: "UTC",
    }).format(
      new Date(
        novaDataInicial.getUTCFullYear(),
        novaDataInicial.getUTCMonth(),
        novaDataInicial.getUTCDate()
      )
    );

    const dataFinalFormatada = Intl.DateTimeFormat("pt-BR", {
      timeZone: "UTC",
    }).format(
      new Date(
        novaDataFinal.getUTCFullYear(),
        novaDataFinal.getUTCMonth(),
        novaDataFinal.getUTCDate()
      )
    );

    const _data = {
      idAgenda: selectedIdAgendamento,
      lojas: _lojas.join(","),
      dataInicial: `${dataInicialFormatada} ${hourBlock[0]}`,
      dataFinal: `${dataFinalFormatada} ${hourBlock[1]}`,
      horaInicial: hourBlock[0],
      horaFinal: hourBlock[1],
      idMotivo,
      motivo,
    };
    const hash = localStorage.getItem("@portalAdmin:hash") || "";
    let url = "/AgendaData/Bloquear";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        pm_hash: hash,
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        //PODE SER QUE RETORNE ERRO INFORMANDO QUE EXISTE AGENDAMENTOS EM ABERTO
        setBlockMotivo(false);
        if (data.result === "Error") {
          handleSetIsLoader(false);
          if (data.code === "ja_existe_agendamentos") {
            //setIsBlockScheduleModalOpen(false);
            // setIsWarningQuestionHasAppointmentsModalOpen(false);
            setIsWarningExistsActiveAppointmentsModalOpen(true);
          } else {
            //setIsBlockScheduleModalOpen(false);
            // setIsWarningQuestionHasAppointmentsModalOpen(false);
            setErrorMessage(data.message);
            setIsErrorModalOpen(true);
          }
        } else if (data.result === "Success") {
          handleSetIsLoader(false);
          fetchAgendamentos();
          //setIsBlockScheduleModalOpen(false);
          // setIsWarningQuestionHasAppointmentsModalOpen(false);
          setIsBlockScheduleSuccessModalOpen(true);
        } else {
          handleSetIsLoader(false);
          setIsBlockScheduleModalOpen(false);
          setErrorMessage(data.message);
          setIsErrorModalOpen(true);
          fetchAgendamentos();
        }
      })
      .catch((error) => {
        handleSetIsLoader(false);
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  function desbloquearAgenda(idMotivo: number, motivo: string) {
    handleSetIsLoader(true);
    const _lojas = selectedStores.length
      ? selectedStores.map((store) => String(store.codigo))
      : [];

    const novaDataInicial =
      new Date(dateBlock[0]) ||
      new Date(convert({ dateString: dateBlock[0], pattern: "yyyy-mm-dd" }));
    const novaDataFinal =
      new Date(dateBlock[1]) ||
      new Date(convert({ dateString: dateBlock[0], pattern: "yyyy-mm-dd" }));

    const dataInicialFormatada = Intl.DateTimeFormat("pt-BR", {
      timeZone: "UTC",
    }).format(
      new Date(
        novaDataInicial.getUTCFullYear(),
        novaDataInicial.getUTCMonth(),
        novaDataInicial.getUTCDate()
      )
    );

    const dataFinalFormatada = Intl.DateTimeFormat("pt-BR", {
      timeZone: "UTC",
    }).format(
      new Date(
        novaDataFinal.getUTCFullYear(),
        novaDataFinal.getUTCMonth(),
        novaDataFinal.getUTCDate()
      )
    );

    const _data = {
      idAgenda: selectedIdAgendamento,
      lojas: _lojas.join(","),
      dataInicial: `${dataInicialFormatada} ${hourBlock[0]}`,
      dataFinal: `${dataFinalFormatada} ${hourBlock[1]}`,
      horaInicial: hourBlock[0],
      horaFinal: hourBlock[1],
      idMotivo,
      motivo,
    };
    const hash = localStorage.getItem("@portalAdmin:hash") || "";
    let url = "/AgendaData/Desbloquear";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        pm_hash: hash,
      },
      body: JSON.stringify(_data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          handleSetIsLoader(false);
          setErrorMessage(data.message);
          setIsReleaseScheduleModalOpen(false);
          setIsErrorModalOpen(true);
        } else if (data.result === "Success") {
          handleSetIsLoader(false);
          fetchAgendamentos();
          setIsReleaseScheduleModalOpen(false);
          setIsReleaseScheduleSuccessModalOpen(true);
        } else {
          handleSetIsLoader(false);
          setErrorMessage(data.message);
          setIsReleaseScheduleModalOpen(false);
          setIsErrorModalOpen(true);
        }
      })
      .catch((error) => {
        handleSetIsLoader(false);
        alert("Erro chamada API");
        console.error("Error:", error);
      });
  }

  async function cancelarAgendamento(
    idAgendamento: number,
    idMotivo: number,
    motivo: string
  ) {
    try {
      handleSetIsLoader(true);
      const hash = localStorage.getItem("@portalAdmin:hash") || "";

      const response = await axios.put(
        "/AgendamentoData/Cancelar",
        {},
        {
          headers: {
            pm_hash: hash,
          },
          params: {
            idAgendamento,
            idMotivo,
            motivo,
          },
        }
      );

      if (response.data.result === "Success") {
        setIsModalCancelSchedulingSuccess(true);
        handleSetIsLoader(false);
        fetchAgendamentos();
      } else {
        setErrorMessage("Erro ao cancelar o agendamento.");
        setIsErrorModalOpen(true);
        handleSetIsLoader(false);
      }
    } catch (error) {
      console.error(error);
      handleSetIsLoader(false);
    }
  }
  function renderHour() {
    const splitHourInitial = hourBlock[0].split(":");
    const splitHourFinal = addMinutes(hourBlock[1], 29).split(":");

    const hourInitial =
      splitHourInitial[0].padStart(2, "0") +
      ":" +
      splitHourInitial[1].padStart(2, "0");
    const hourFinal =
      splitHourFinal[0].padStart(2, "0") +
      ":" +
      splitHourFinal[1].padStart(2, "0");
    return `${hourInitial} às ${hourFinal}`;
  }

  function renderDate() {
    if (new Date(dateBlock[0]).getDate() === new Date(dateBlock[1]).getDate()) {
      return `${new Intl.DateTimeFormat("pt-BR", {
        day: "numeric",
        month: "long",
        timeZone: "UTC",
      }).format(new Date(dateBlock[0]))}`;
    }

    return `${new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      timeZone: "UTC",
    }).format(new Date(dateBlock[0]))} até ${new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      timeZone: "UTC",
    }).format(new Date(dateBlock[1]))}`;
  }

  return (
    <>
      <Header />
      <Main>
        <Container>
          <div>
            <DashboardContainer>
              <DashboardUser displayStore multSelection={true} />
            </DashboardContainer>
            <br />
            <p>Bloqueie ou desbloqueie agendas em massa</p>

            <div className={"bulkActions"}>
              <span
                className={"blockBtn"}
                onClick={() => {
                  setOpenAction(false);
                  if (selectedStores.length) {
                    if (
                      filterDate !== undefined &&
                      filterDate[1] === undefined
                    ) {
                      filterDate[1] = filterDate[0];
                    }
                    setIsBlockScheduleModalOpen(true);
                  } else {
                    setErrorMessage("Por favor selecione uma loja");
                    setIsErrorModalOpen(true);
                  }
                }}
              >
                <svg
                  width="16.67"
                  height="16.67"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.00001 0.666687C4.40001 0.666687 0.666672 4.40002 0.666672 9.00002C0.666672 13.6 4.40001 17.3334 9.00001 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 9.00001 0.666687ZM2.33334 9.00002C2.33334 5.31669 5.31667 2.33335 9.00001 2.33335C10.5417 2.33335 11.9583 2.85835 13.0833 3.74169L3.74167 13.0834C2.85834 11.9584 2.33334 10.5417 2.33334 9.00002ZM4.91667 14.2584C6.04167 15.1417 7.45834 15.6667 9 15.6667C12.6833 15.6667 15.6667 12.6834 15.6667 9.00002C15.6667 7.45835 15.1417 6.04169 14.2583 4.91669L4.91667 14.2584Z"
                    fill="#F14A50"
                  />
                </svg>
                Bloquear agenda
              </span>
              <span
                className={"releaseBtn"}
                onClick={() => {
                  setOpenAction(false);
                  if (selectedStores.length) {
                    if (
                      filterDate !== undefined &&
                      filterDate[1] === undefined
                    ) {
                      filterDate[1] = filterDate[0];
                    }
                    setIsReleaseScheduleModalOpen(true);
                  } else {
                    setErrorMessage("Por favor selecione uma loja");
                    setIsErrorModalOpen(true);
                  }
                }}
              >
                <img
                  src={IconRelese}
                  width="18.62"
                  height="15.12"
                  alt="block"
                />
                Liberar agenda
              </span>
            </div>

            <CalendarContainer>
              <div className="header-calendar-action">
                <h2>Data do agendamento</h2>
                <img
                  src={IconAction}
                  alt="action"
                  className="action"
                  onClick={() => handleOpenAction()}
                />
              </div>
              <p>Selecione a data para filtrar ou fazer ação</p>
              <br />
              {/*<img
                src={IconAction}
                alt="action"
                className="action"
                onClick={() => handleOpenAction()}
              />*/}
              {/*{openAction && (
                <>
                  <Overlay onClick={handleOpenAction} />
                  <ActionAgenda>
                    <div>
                      <span
                        onClick={() => {
                          setOpenAction(false);
                          if (selectedStores.length) {
                            if (
                              filterDate !== undefined &&
                              filterDate[1] === undefined
                            ) {
                              filterDate[1] = filterDate[0];
                            }
                            setIsBlockScheduleModalOpen(true);
                          } else {
                            setErrorMessage("Por favor selecione uma loja");
                            setIsErrorModalOpen(true);
                          }
                        }}
                      >
                        <svg
                          width="16.67"
                          height="16.67"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: "5px" }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.00001 0.666687C4.40001 0.666687 0.666672 4.40002 0.666672 9.00002C0.666672 13.6 4.40001 17.3334 9.00001 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 9.00001 0.666687ZM2.33334 9.00002C2.33334 5.31669 5.31667 2.33335 9.00001 2.33335C10.5417 2.33335 11.9583 2.85835 13.0833 3.74169L3.74167 13.0834C2.85834 11.9584 2.33334 10.5417 2.33334 9.00002ZM4.91667 14.2584C6.04167 15.1417 7.45834 15.6667 9 15.6667C12.6833 15.6667 15.6667 12.6834 15.6667 9.00002C15.6667 7.45835 15.1417 6.04169 14.2583 4.91669L4.91667 14.2584Z"
                            fill="#F14A50"
                          />
                        </svg>
                        Bloquear agenda
                      </span>
                      <span
                        onClick={() => {
                          setOpenAction(false);
                          if (selectedStores.length) {
                            if (
                              filterDate !== undefined &&
                              filterDate[1] === undefined
                            ) {
                              filterDate[1] = filterDate[0];
                            }
                            setIsReleaseScheduleModalOpen(true);
                          } else {
                            setErrorMessage("Por favor selecione uma loja");
                            setIsErrorModalOpen(true);
                          }
                        }}
                      >
                        <img
                          src={IconRelese}
                          width="18.62"
                          height="15.12"
                          alt="block"
                        />
                        Liberar agenda
                      </span>
                    </div>
                  </ActionAgenda>
                </>
              )}*/}
              <div className="calendar">
                <Calendar
                  defaultDateSelected={filterDate}
                  handleClickDay={(date: any) => {
                    setCurrentPage(1);

                    if (!date) {
                      return;
                    }

                    if (!filterDate || filterDate.length === 2) {
                      setFilterDate([...date]);
                      return;
                    }

                    setFilterDate(
                      [...(filterDate as Date[]), ...date]
                        .sort((a, b) => a.getDate() - b.getDate())
                        .sort((a, b) => a.getMonth() - b.getMonth())
                    );
                  }}
                />
              </div>
            </CalendarContainer>
          </div>
          <Content>
            <div className="titleContainer">
              <div>
                <h2>RESULTADO DOS SERVIÇOS</h2>
                <p>
                  Resumo dos serviços do dia{" "}
                  {new Intl.DateTimeFormat("pt-BR").format(selectedDateInicial)}{" "}
                  {selectedDateInicial?.getTime() !==
                    selectedDateFinal?.getTime() && (
                    <>
                      ao dia{" "}
                      {new Intl.DateTimeFormat("pt-BR").format(
                        selectedDateFinal
                      )}
                    </>
                  )}
                </p>
              </div>
              <GridFilter
                onClick={() => setIsServiceFilterModalOpen(true)}
                showInput
                onChange={(event) => setFilterText(event.target.value)}
              />
            </div>
            <br />
            {/*<div className="periodContainer">
              <PeriodCard
                key="manha"
                period="Manhã"
                hour="06:00 às 11:59"
                numberOfAppointments={totalManha}
                onClick={() =>
                  setSelectedPeriod({
                    name: "manha",
                    hour: "07:00 às 12:00",
                  })
                }
                selected={selectedPeriod.name === "manha"}
              />
              <PeriodCard
                key="tarde"
                period="Tarde"
                hour="12:00 às 17:59"
                numberOfAppointments={totalTarde}
                onClick={() =>
                  setSelectedPeriod({
                    name: "tarde",
                    hour: "13:00 às 18:00",
                  })
                }
                selected={selectedPeriod.name === "tarde"}
              />
              <PeriodCard
                key="noite"
                period="Noite"
                hour="18:00 às 05:59"
                numberOfAppointments={totalNoite}
                onClick={() =>
                  setSelectedPeriod({
                    name: "noite",
                    hour: "18:00 às 22:00",
                  })
                }
                selected={selectedPeriod.name === "noite"}
              />
            </div>*/}
            {!!agendamentos.length && (
              <>
                <Grid
                  columns={["Status", "Data", "Horas", "Loja", "Serviço"]}
                  columnsWidth={[
                    {
                      column: "Status",
                      width: "62px",
                    },
                    {
                      column: "Data",
                      width: "85px",
                    },
                    {
                      column: "Horas",
                      width: "67px",
                    },
                    {
                      column: "Loja",
                      width: "268px",
                    },
                    {
                      column: "Serviço",
                      width: "331px",
                    },
                    {
                      column: "Action",
                      width: "116px",
                    },
                  ]}
                  omit={[
                    "id",
                    "idAgendamento",
                    "codigoLoja",
                    "cliente",
                    "idServicos",
                    "nomeServicos",
                  ]}
                  data={
                    agendamentos
                    /*!!agendamentosFilter.length || !!filterText
                      ? agendamentosFilter
                      : agendamentos*/
                  }
                  statusIconMap={statusIconMap}
                  maxHeight="450px"
                  enableColumnSort
                  actionButtonModalContent={
                    <ActionButtonModalContent data={{} as AgendamentoGrid} />
                  }
                  enableActionButton
                  enablePagination
                  onChangePage={(page) => setCurrentPage(page)}
                  totalData={totalData}
                  itemsPerPage={8}
                  disableRowBy="status"
                  disableRowByValueToDisable={3}
                  emptyRowMessage="Sem agendamentos"
                  columnToDisplayMessageEmpty="servicos"
                  // disableAll={isScheduleBlocked}
                />
              </>
            )}
            {!agendamentos.length && !selectedStores.length && (
              <>
                {handleSetIsLoader(false)}
                <NoResults
                  title="Selecione uma loja"
                  message="Para ver a agenda de serviços do dia, é preciso antes selecionar uma das lojas disponiveis para visualização."
                  sizeTitle="22px"
                  sizeMessage="14px"
                  colorTitle={colors.dark}
                  colorMessage={colors.gray.text}
                  containerStyle={{
                    height: "75%",
                  }}
                  messageStyle={{
                    minWidth: "100%",
                  }}
                />
              </>
            )}
          </Content>
        </Container>

        {isBlockScheduleModalOpen && (
          <AgendaBlockRelease
            isOpen={isBlockScheduleModalOpen}
            onRequestClose={() => {
              setDateBlock([]);
              setHourBlock([]);
              setIsBlockScheduleModalOpen(false);
            }}
            onContinue={(dateBlock: string[], hourBlock: string[]) => {
              setDateBlock(dateBlock);
              setHourBlock(hourBlock);
              setSelectedIdAgendamento(0);
              existeAgendamento();
            }}
            Icon={IconBlock}
            Title="Bloquear agenda"
            Info={
              <>
                Informe abaixo qual dia e horário que deseja <br /> bloquear.
              </>
            }
            onSetModalData={handleSetModalData}
            defaultValueDate={filterDate}
          />
        )}
        {isReleaseScheduleModalOpen && (
          <AgendaBlockRelease
            isOpen={isReleaseScheduleModalOpen}
            onRequestClose={() => {
              setDateBlock([]);
              setHourBlock([]);
              setIsReleaseScheduleModalOpen(false);
            }}
            onContinue={(dateBlock: string[], hourBlock: string[]) => {
              setDateBlock(dateBlock);
              setHourBlock(hourBlock);
              setSelectedIdAgendamento(0);
              setIsReleaseScheduleModalOpen(false);
              setIsMotivoLiberacaoModalOpen(true);
            }}
            Icon={IconAlert2}
            Title="Liberar agenda"
            Info={
              <>
                Informe abaixo qual dia e horário que deseja <br /> liberar.
              </>
            }
            onSetModalData={handleSetModalData}
            defaultValueDate={filterDate}
          />
        )}
        {isMotivoLiberacaoModalOpen && (
          <MotivoBloqueioLiberacao
            onRequestClose={() => setIsMotivoLiberacaoModalOpen(false)}
            onContinue={(data) => {
              setIsMotivoLiberacaoModalOpen(false);
              desbloquearAgenda(data.motivoSelecionado, data.descricaoMotivo);
            }}
            isOpen={isMotivoLiberacaoModalOpen}
            dateBlock={dateBlock}
            hourBlock={hourBlock}
            type="release"
          />
        )}
        {blockMotivo && (
          <MotivoBloqueioLiberacao
            onRequestClose={() => setBlockMotivo(false)}
            onContinue={(data) => {
              setBlockMotivo(false);
              bloquearAgenda(data.motivoSelecionado, data.descricaoMotivo);
            }}
            isOpen={blockMotivo}
            dateBlock={dateBlock}
            hourBlock={hourBlock}
            type="block"
          />
        )}
        {isModalMotivoCancelamentoOpen && (
          <MotivoCancelamento
            isOpen={isModalMotivoCancelamentoOpen}
            onContinue={(event) => {
              cancelarAgendamento(
                idAgendamento,
                event.motivoSelecionado,
                event.descricaoMotivo
              );
              setIsModalMotivoCancelamentoOpen(false);
            }}
            onRequestClose={() => {
              setIsModalMotivoCancelamentoOpen(false);
              setSelectedIdAgendamento(0);
              setIdAgendamento(0);
            }}
          />
        )}
        {isReleaseScheduleSuccessModalOpen && (
          <SimpleModal
            onRequestClose={() => {
              setIsReleaseScheduleSuccessModalOpen(false);
            }}
            confirm={() => {
              setIsReleaseScheduleSuccessModalOpen(false);
            }}
            isOpen={isReleaseScheduleSuccessModalOpen}
            icon={IconSucess}
            title="Sucesso"
            message={
              dateBlock[0] === dateBlock[1] ? (
                <>
                  Horário das{" "}
                  <strong>
                    {renderHour()} do dia {renderDate()}
                  </strong>
                  <br />
                  foi liberada para agendamentos
                </>
              ) : (
                <>
                  Horário das{" "}
                  <strong>
                    {hourBlock[0]} às {addMinutes(hourBlock[0], 29)} dos dias{" "}
                    {new Intl.DateTimeFormat("pt-BR", {
                      day: "numeric",
                      month: "long",
                    }).format(new Date(dateBlock[0]))}{" "}
                  </strong>
                  até{" "}
                  <strong>
                    {new Intl.DateTimeFormat("pt-BR", {
                      day: "numeric",
                      month: "long",
                    }).format(new Date(dateBlock[1]))}
                  </strong>{" "}
                  foi liberada para agendamentos
                </>
              )
            }
            continueButtonText="Fechar"
            widthButtonContinue="110px"
            buttonsMarginTop="3px"
          />
        )}
        <SimpleModal
          onRequestClose={() => {
            setIsBlockScheduleSuccessModalOpen(false);
          }}
          confirm={() => {
            setIsBlockScheduleSuccessModalOpen(false);
          }}
          isOpen={isBlockScheduleSuccessModalOpen}
          icon={IconSucess}
          title="Sucesso"
          message="Agenda foi bloqueada com sucesso."
          continueButtonText="Fechar"
          widthButtonContinue="110px"
          buttonsMarginTop="27px"
        />
        <SimpleModal
          onRequestClose={() => {
            setIsWarningSelectStoreModalOpen(false);
          }}
          confirm={() => {
            setIsWarningSelectStoreModalOpen(false);
          }}
          isOpen={isWarningSelectStoreModalOpen}
          icon={IconAlert2}
          title="Atenção"
          message="Selecione uma loja para realizar uma busca"
          continueButtonText="Fechar"
        />
        <SimpleModal
          onRequestClose={() => {
            setIsWarningExistsActiveAppointmentsModalOpen(false);
          }}
          confirm={() => {
            setIsWarningExistsActiveAppointmentsModalOpen(false);
          }}
          isOpen={isWarningExistsActiveAppointmentsModalOpen}
          icon={IconAlert2}
          title="Atenção"
          message="Existem agendamentos ativos para o período solicitado, entre em contato com o cliente e realize o cancelamento antes de bloquear o horário."
          continueButtonText="Voltar"
          buttonsMarginTop="47px"
          widthButtonContinue="104px"
          heightButton="48px"
          marginTitleBottom="20px"
          marginButton="marginButton"
        />
        <ServiceFilterModal
          isOpen={isServiceFilterModalOpen}
          onRequestClose={() => setIsServiceFilterModalOpen(false)}
          defaultData={{
            filterUf: filter.ufSelected,
            filterCidade: filter.bairroSelected,
            filterBairro: filter.bairroSelected,
            filterHorario: filter.horarioSelected,
            filterServico: filter.servicoSelected,
          }}
          onContinue={(
            ufSelected: string,
            cidadeSelected: string,
            bairroSelected: string,
            horarioSelected: string,
            servicoSelected: number
          ) => {
            setIsServiceFilterModalOpen(false);
            setFilter({
              ufSelected,
              cidadeSelected,
              bairroSelected,
              horarioSelected,
              servicoSelected,
            });
          }}
        />
        <SimpleModal
          onRequestClose={() => {
            setIsErrorModalOpen(false);
          }}
          confirm={() => {
            setIsErrorModalOpen(false);
          }}
          isOpen={isErrorModalOpen}
          icon={IconError}
          title="Erro"
          message={errorMessage}
          continueButtonText="Fechar"
        />
        {hasAppointmentsActiveModalOpen && (
          <SimpleModal
            onRequestClose={() => {
              setHasAppointmentsActiveModalOpen(false);
              setSelectedIdAgendamento(0);
              setIdAgendamento(0);
            }}
            confirm={() => {
              setHasAppointmentsActiveModalOpen(false);
              setIsModalSchedulingDetailsOpen(true);
            }}
            isOpen={hasAppointmentsActiveModalOpen}
            icon={IconAlert2}
            title="Atenção"
            message={
              <>
                Existe um agendamento ativo para o período selecionado, entre em
                contato com o cliente antes de cancelar o serviço marcado, para
                assim realizar o bloqueio do horário.
                <br /> <br />
                Caso o cliente já tenha sido comunicado, basta prosseguir e
                realizar o cancelamento.
              </>
            }
            showCancel
            buttonsMarginTop="39.8px"
            cancelButtonText="Voltar"
            continueButtonText="Prosseguir"
          />
        )}
        {isModalSchedulingDetailsOpen && (
          <SchedulingDetailsModal
            isOpen={isModalSchedulingDetailsOpen}
            onRequestClose={() => {
              setIsModalSchedulingDetailsOpen(false);
              setSelectedIdAgendamento(0);
            }}
            onClickCancelScheduling={(event) => {
              setIdAgendamento(event.idAgendamento);
              setIsModalSchedulingDetailsOpen(false);
              setIsModalMotivoCancelamentoOpen(true);
            }}
            idAgendamento={idAgendamento}
          />
        )}
        <SimpleModal
          isOpen={errorAuthorization}
          onRequestClose={() => setErrorAuthorization(false)}
          confirm={() => setErrorAuthorization(false)}
          icon={IconError2}
          title="Cancelamento não autorizado"
          marginTitle="15px"
          message={errorMessage}
          widthButtonContinue="110px"
          continueButtonText="Fechar"
          buttonsMarginTop="40.35px"
        />
        <SimpleModal
          isOpen={cancelamentoConfirmado}
          onRequestClose={() => setCancelamentoConfirmado(false)}
          confirm={() => {
            setIsModalMotivoCancelamentoOpen(true);
            setCancelamentoConfirmado(false);
          }}
          icon={IconAlert2}
          title="Atenção"
          message="Existe um agendamento ativo para o horário selecionado, o cliente foi informando do cancelamento antes de realização desta ação?"
          showCancel
          onCancel={() => {
            setCancelamentoConfirmado(false);
            setErrorMessage(
              "Para cancelar este agendamento, primeiro entre em contato com o cliente e informe o motivo do cancelamento dos serviços contratados."
            );
            setErrorAuthorization(true);
          }}
          marginTitle="16px"
          marginTitleBottom="7px"
          cancelButtonText="Não"
          continueButtonText="Sim"
          widthButtonClose="90px"
          widthButtonContinue="90px"
          marginButton="32px"
        />
        {isModalCancelSchedulingSuccess && (
          <SimpleModal
            onRequestClose={() => {
              setIsModalCancelSchedulingSuccess(false);
            }}
            confirm={() => {
              setIsModalCancelSchedulingSuccess(false);
              if (activeFlow === "bloqueio") {
                setBlockMotivo(true);
              }
            }}
            isOpen={isModalCancelSchedulingSuccess}
            icon={IconSucess}
            title="Sucesso"
            marginTitle="19px"
            message="Cancelamento foi realizado com sucesso."
            buttonsMarginTop="32px"
            widthButtonContinue={activeFlow === "bloqueio" ? "170px" : "110px"}
            continueButtonText={
              activeFlow === "bloqueio" ? "Ir para bloqueio" : "Fechar"
            }
          />
        )}
      </Main>
      <Footer />
    </>
  );
}
