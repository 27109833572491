import IconService1 from "../../../assets/IconService1.svg"
import IconService2 from "../../../assets/IconService2.svg"
import IconService3 from "../../../assets/IconService3.svg"
import IconService4 from "../../../assets/IconService4.svg"
import IconService5 from "../../../assets/IconService5.svg"
import IconService6 from "../../../assets/IconService6.svg"
import IconService7 from "../../../assets/IconService7.svg"
import IconService8 from "../../../assets/IconService8.svg"
import IconService9 from "../../../assets/IconService9.svg"
import IconService10 from "../../../assets/IconService10.svg"
import IconService11 from "../../../assets/IconService11.svg"
import IconService12 from "../../../assets/IconService12.svg"
import IconService13 from "../../../assets/IconService13.svg"
import IconService14 from "../../../assets/IconService14.svg"
import IconService15 from "../../../assets/IconService15.svg"
import IconService16 from "../../../assets/IconService16.svg"
import IconService17 from "../../../assets/IconService17.svg"
import IconService18 from "../../../assets/IconService18.svg"
import AdvanceIcon from "../../../assets/AdvanceIcon.svg"
import GoBackIcon from "../../../assets/GoBackIcon.svg"

import {
  Container,
  Header,
  ContainerScroll,
  Card,
  TotalServices,
} from "./styles"

import { useEffect, useState } from "react"
import { NoResults } from "../../NoResults"
import { usePartnerContext } from "contexts/partnerContextProvider"
import { format } from "date-fns-tz"

interface ServiceData {
  id: number
  nome: string
  subTitulo: string
  total: number
  iconeId: number
}

interface SlideProps {
  services: ServiceData[]
  loading: boolean
}

export default function ServicesSlider({ services, loading }: SlideProps) {
  const { startDate, endDate } = usePartnerContext()

  const iconsServices = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
  ]

  const [inicio, setInicio] = useState(true)
  const [fim, setFim] = useState(1)

  function move(direcao: number) {
    var element = document.querySelector(".containerScroll")
    if (element) {
      if ((direcao < 0 && inicio !== true) || (direcao > 0 && fim !== 0)) {
        element.scrollLeft += 100 * direcao
        if (element) {
          setInicio(element.scrollLeft === 0)
          setFim(
            element.clientWidth + element.scrollLeft - element.scrollWidth
          )
        }
      }
    }
  }

  function handleScroll(position: number) {
    var element = document.querySelector(".containerScroll")
    setInicio(position === 0)
    if (element) {
      setFim(element.clientWidth + position - element.scrollWidth)
    }
  }

  const totalServices = services.reduce(
    (accumulator: number, service: ServiceData) => {
      return (accumulator += service.total)
    },
    0
  )

  return (
    <Container inicio={inicio} fim={fim}>
      <Header>
        <div className="header">
          <p>SERVIÇOS AGENDADOS</p>
          {format(startDate, "dd/MM/yyyy") === format(endDate, "dd/MM/yyyy")
            ? (
              <span>Quantidade de cada serviço do dia {format(startDate, "dd/MM/yyyy")}</span>
            )
            : (
              <span>Quantidade de cada serviço de {format(startDate, "dd/MM/yyyy")} à {format(endDate, "dd/MM/yyyy")}</span>
            )}
        </div>
        <TotalServices>
          {" "}
          <span>Total de serviços</span>
          <strong>{totalServices}</strong>
        </TotalServices>
      </Header>
      <div className="iconBack">
        <img src={GoBackIcon} className="goBack" onClick={() => move(-1)} />
      </div>
      {!!services.length && !loading && (
        <>
          <div className="iconAdvance">
            <img src={AdvanceIcon} className="advance" onClick={() => move(1)} />
          </div>
          <ContainerScroll
            className="containerScroll"
            onScroll={(position) => handleScroll(position)}
          >
            {services.map((service) => (
              <Card key={service.id}>
                <img
                  src={
                    iconsServices.find((icone) => icone.id === service.iconeId)
                      ?.icon
                  }
                  alt="icone"
                />
                <strong>{String(service.total).padStart(2, "0")}</strong>
                <p>{service.nome}</p>
                <span>{service.subTitulo}</span>
              </Card>
            ))}
          </ContainerScroll>
        </>
      )}
      {loading && (
        <NoResults
          title=""
          message="Carregando..."
          containerStyle={{ marginTop: "67px" }}
        />
      )}
      {!services.length && !loading && (
        <NoResults
          title="Atenção!"
          message="Nenhum agendamento realizado."
          containerStyle={{ marginTop: "67px" }}
        />
      )}
    </Container>
  )
}
