import "./weekDay.scss";
import SchedulePeriod from "./SchedulePeriod/schedulePeriod";

export default function WeekDay({ weekDayInfo, setOpen }) {
  const {
    CodigoLoja,
    DiaDaSemana,
    IdParceiro,
    periodos,
    nomediaSemana,
    horaInicioFuncionamentoLoja,
    horaFimFuncionamentoLoja,
    ativo,
  } = weekDayInfo;

  return (
    <>
      <div className="weekDay">
        <header className="weekDay__header">
          <div className="weekDay__header__dayInfo">
            <span className="weekDay__header__dayInfo__dayName">
              {nomediaSemana}
            </span>
            <span className="weekDay__header__dayInfo__dayWorkingHours">
              Aberto das {horaInicioFuncionamentoLoja} às{" "}
              {horaFimFuncionamentoLoja}
            </span>
          </div>

          <div className="weekDay__header__actionButtons">
            <button
              disabled={!ativo}
              onClick={() => {
                setOpen({
                  nomediaSemana,
                  DiaDaSemana,
                });
              }}
            >
              Incluir período
            </button>
          </div>
        </header>

        <div className="weekDay__periods">
          {periodos?.length > 0 &&
            periodos.map((dayPeriod, index) => {
              return (
                <SchedulePeriod
                  key={dayPeriod.idAgendaLojaSalaConfig}
                  periodName={`${index + 1}° Período`}
                  dayPeriod={{ ...dayPeriod, IdParceiro, CodigoLoja }}
                />
              );
            })}
          {!periodos?.length && <span>Nenhum período configurado</span>}
        </div>
      </div>
    </>
  );
}
