import { useEffect, useState } from "react";
import axios from "axios";
import { ModalBase } from "components/ModalGroup/ModalBase";
import Button from "components/Button";
import { useAuth } from "hooks/auth";
import {
  Container,
  CloseModal,
  ContainerTableOne,
  ContainerTableThree,
  ContainerTableThreeEnd,
  ContainerTotal,
  ContainerButton,
} from "./styles";

import closeModalImg from "images/icons/icon-close-modal.png";

interface Servico {
  id: number;
  codProduto: string;
  nome: string;
  nomeAbreviado: string;
  preco: number;
}

interface Cliente {
  id: number;
  nome: string;
  telefone: string;
  email: string;
  cpf: string;
}

interface Agendamento {
  id: number;
  cliente: Cliente;
  data: string;
  hora: string;
  origemAgendamento: string;
  servicos: Servico[];
  status: number;
}

interface CancelarProps {
  data: string;
  hora: string;
  idAgendamento: number;
}

interface SchedulingDetailsModalProps {
  onRequestClose: () => void;
  onClickCancelScheduling?: ({ data, hora, idAgendamento }: CancelarProps) => void;
  onClickPreSaleScheduling?: ({ data, hora, idAgendamento }: CancelarProps) => void;
  onClickExecuteServiceScheduling?: ({ data, hora, idAgendamento }: CancelarProps) => void;
  isOpen: boolean;
  idAgendamento: number;
}

export function SchedulingDetailsModal({
  onRequestClose,
  onClickCancelScheduling = () => {},
  onClickPreSaleScheduling = () => {},
  onClickExecuteServiceScheduling = () => {},
  isOpen,
  idAgendamento,
}: SchedulingDetailsModalProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [agendamento, setAgendamento] = useState({} as Agendamento);
  const { nivelAcesso } = useAuth();
  const isAllowed = nivelAcesso?.trim() !== "OPERADOR";

  useEffect(() => {
    if (idAgendamento !== 0) {
      setIsLoading(true);
      axios
        .get(`agendamentoData/GetAgendamento/${idAgendamento}`)
        .then((response: any) => {          
          setAgendamento(response.data);
          setIsLoading(false);
        });
    }
  }, [idAgendamento]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <CloseModal onClick={onRequestClose}>
        <img src={closeModalImg} alt="Fechar Modal" />
      </CloseModal>
      {isLoading && <p>Carregando...</p>}

      {!isLoading && (
        <Container>
          <h2>Detalhes do agendamento</h2>
          <p># {agendamento.id}</p>

          {/* {![2, 3, 6, 7].includes(agendamento.status) && (
            <span
              className="pre-sale"
              onClick={() => {
                let formatData: string[] = agendamento.data.split("-");
                onClickPreSaleScheduling({
                  data: `${formatData[2]}/${formatData[1]}/${formatData[0]}`,
                  hora: agendamento.hora,
                  idAgendamento
                });
              }}
            >
              Gerar Pré-Venda
            </span>
          )} */}

          {agendamento.status === 2 && (
            <span className="canceled">
              Agendamento cancelado
            </span>
          )}
          {![3, 2].includes(agendamento.status) && (
            <span
              className="cancel-appointment"
              onClick={() => {
                let formatData: string[] = agendamento.data.split("-");
                onClickCancelScheduling({
                  data: `${formatData[2]}/${formatData[1]}/${formatData[0]}`,
                  hora: agendamento.hora,
                  idAgendamento
                });
              }}
            >
              Cancelar agendamento
            </span>
          )}

        {isAllowed && [6,7].includes(agendamento.status) && (
          <span
            className="execute-service"
            onClick={() => {
              let formatData: string[] = agendamento.data.split("-");
              onClickExecuteServiceScheduling({
                data: `${formatData[2]}/${formatData[1]}/${formatData[0]}`,
                hora: agendamento.hora,
                idAgendamento
              })
            }}
          >
            Executar Serviço
            {/* <button
              type="button"
              style={{ color: "#0054A6" }}
            > </button> */}
          </span>
        )}

          <ContainerTableOne>
            <table>
              <thead>
                <tr>
                  <th>Cliente</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{agendamento.cliente?.nome}</td>
                </tr>
              </tbody>
            </table>
          </ContainerTableOne>

          <ContainerTableThree>
            <table>
              <thead>
                <tr>
                  <th>Telefone</th>
                  <th>Email</th>
                  <th>CPF</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{agendamento.cliente?.telefone}</td>
                  <td>{agendamento.cliente?.email}</td>
                  <td>{agendamento.cliente?.cpf}</td>
                </tr>
              </tbody>
            </table>
          </ContainerTableThree>

          <ContainerTableThree>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Horário </th>
                  <th>Rastreio de entrada</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {Intl.DateTimeFormat("pt-BR", {
                      timeZone: "UTC",
                    }).format(
                      agendamento.data
                        ? new Date(String(agendamento.data))
                        : new Date()
                    )}
                  </td>
                  <td>{agendamento.hora}</td>
                  <td>{agendamento.origemAgendamento}</td>
                </tr>
              </tbody>
            </table>
          </ContainerTableThree>

          <ContainerTableThreeEnd>
            <table>
              <thead>
                <tr>
                  <th>Cod</th>
                  <th>Serviço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {agendamento.servicos?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.codProduto}</td>
                      <td>{item.nomeAbreviado}</td>
                      <td>R$ {item.preco.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </ContainerTableThreeEnd>

          <ContainerTotal>
            <p>Total</p>
            <p>
              R${" "}
              {agendamento.servicos
                ?.reduce((a, b) => a + (b["preco"] || 0), 0)
                .toFixed(2)}
            </p>
          </ContainerTotal>

          <ContainerButton>
            <Button onClick={onRequestClose} inverted>
              Fechar
            </Button>
          </ContainerButton>
        </Container>
      )}
    </ModalBase>
  );
}
