import "./scheduleControl.scss";
import PageContainer from "../../../components/PartnerComponents/PartnerPageContainer/pageContainer";
import PartnerStoresSelect from "../../../components/PartnerComponents/SidebarFilterSelects/PartnerStoresSelect/partnerStoresSelect";
import { useState } from "react";
import WeekView from "components/PartnerComponents/WeekView/weekView";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function ScheduleControl() {
  const [scheduleData, setScheduleData] = useState<any>(null);

  const RoomTabs = () => {
    return (
      <>
        <Tabs style={{ padding: "8px" }} defaultIndex={0}>
          <TabList>
            {scheduleData.map((s, index) => {
              return (
                <Tab key={index}>
                  <strong>{s.nomeSala}</strong>
                </Tab>
              );
            })}
          </TabList>

          {scheduleData.map((s, index) => {
            return (
              <TabPanel key={index}>
                <div className="scheduleControlContent__header">
                  <h1>
                    Controle de Agenda{" "}
                    {scheduleData !== null ? `- ${s.nomeSala}` : ""}
                  </h1>
                  {scheduleData !== null && (
                    <h4>
                      Ajuste os horários desejados nos dias da semana abaixo
                    </h4>
                  )}
                </div>

                <WeekView scheduleData={s} />
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  };

  return (
    <PageContainer
      sidebar={<PartnerStoresSelect setScheduleData={setScheduleData} />}
      content={
        <div className="scheduleControlContent">
          <div className="scheduleControlContent__header">
            {scheduleData === null && (
              <>
                <h1>Controle de Agenda</h1>
                <h4>
                  Selecione a loja para programar os horários de atendimento
                </h4>
              </>
            )}
          </div>
          {scheduleData !== null && <RoomTabs />}
        </div>
      }
    />
  );
}

export { ScheduleControl };
