import Header from "../components/Header";
import Main from "../components/Main";
import { useEffect, useState } from "react";
import { Footer } from "components/Footer";

export function Agendamento() {
    const [linkAgendamento, setLinkAgendamento] = useState("");
    const [innerHeight, setInnerHeight] = useState(1490);

    useEffect(() => {
        const hash = localStorage.getItem("@portalAdmin:hash") || "";
        const apiUrl = `agendamentoData/LinkAgendamento`;
        fetch(apiUrl, {
            method: "GET",
            headers: { "pm_hash": hash }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.result === "Success") setLinkAgendamento(data.linkAgendamento);
            });
    }, []);

    useEffect(() => {
        resizeScreen();
        window.addEventListener('resize', (event) => {
            resizeScreen();
        });
    }, []);

    function resizeScreen() {
        setInnerHeight(window.innerHeight - 150);
    }

    return (
        <>
            <Header />
            <Main _paddingLeft={'0px'} _paddingRight={'0px'}>
                <iframe
                    id="iframeHubSaude"
                    src={linkAgendamento}
                    height={innerHeight}
                    width="100%"
                ></iframe>
            </Main>
            <Footer />
        </>
    );
}
