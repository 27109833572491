import { useEffect, useState } from "react";
import { ModalBase } from "../ModalBase";
import axios from "axios";

import iconRescheduling from "images/icons/rescheduling.svg";

import { Container, Description, ButtonContinue } from "./styles";
import Button from "components/Button";

type MotivoCancelamentoData = {
  motivoSelecionado: number;
  descricaoMotivo: string;
};

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (data: MotivoCancelamentoData) => void;
}

interface MotivoProps {
  descricao: string;
  id: number;
}

export function MotivoReagendamento({
  isOpen,
  onRequestClose,
  onContinue,
}: ModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const [motivoSelecionado, setMotivoSelecionado] = useState(-1);
  const [descricaoMotivo, setDescricaoMotivo] = useState("");
  const [motivos, setMotivos] = useState<MotivoProps[]>([]);

  useEffect(() => {
    async function loadMotivo(){
      setIsLoading(true);
      console.log(`Buscando motivos ${loadCount}x`);
      const response = await axios.get("/AgendamentoData/GetReagendamentoMotivos");
        if (response.data.result === "Error") {
            if (response.data.code === "001" && loadCount < 3) {
                let count = loadCount + 1;
                setLoadCount(count);               
            }
        } else {
            setMotivos(response.data.items);
        }
        setIsLoading(false);
    }
    loadMotivo();
  }, [loadCount])

  useEffect(() => {
    setDescricaoMotivo("");
  }, [motivoSelecionado]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type={"medium"}>
      <Container>
        <Description>
          <div>
            <img
              src={iconRescheduling}
              alt="Ícone de cancelamento"
            />
          </div>
          <h2>Reagendamento</h2>
          <p>
          Qual o motivo para o reagendamento?
          </p>
        </Description>

        <div className="motivosContainer">
          {isLoading && <p>carregando....</p>}
          {motivos?.map((motivo) => (
            <span key={motivo.id}>
              <input
                type="radio"
                name="motivo"
                id={"motivo-" + motivo.id}
                className={motivoSelecionado === motivo.id ? "checked" : ""}
                onClick={() => setMotivoSelecionado(motivo.id)}
              />
              <label htmlFor={"motivo-" + motivo.id}>
                {motivo.descricao}
              </label>
            </span>
          ))}
          <span>
            <input
              type="radio"
              name="motivo"
              className={motivoSelecionado === 0 ? "checked" : ""}
              id="motivo-outro"
              onClick={() => setMotivoSelecionado(0)}
            />
            <label htmlFor="motivo-outro">Outro</label>
          </span>
        </div>

        {motivoSelecionado === 0 && (
          <span className="descricaoContainer">
            <label htmlFor="motivo-descricao">Qual o motivo?</label>
            <input
              name="motivo-descricao"
              id="motivo-descricao"
              placeholder="Limite de 40 caracteres"
              maxLength={40}
              value={descricaoMotivo}
              onChange={(event) => setDescricaoMotivo(event.target.value)}
            />
          </span>
        )}

        <div className="buttons">
          <Button
            inverted
            width="123px"
            onClick={onRequestClose}
          >
            Cancelar
          </Button>
          <ButtonContinue
            disabled={
              motivoSelecionado === -1 ||
              (motivoSelecionado === 0 && !descricaoMotivo)
            }
            onClick={() => {
              onContinue({ motivoSelecionado, descricaoMotivo });
            }}
          >
            Prosseguir
          </ButtonContinue>
        </div>
      </Container>
    </ModalBase>
  );
}
