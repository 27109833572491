import { useEffect, useState } from "react";

import { max, min } from "utils";

import arrowUp from "images/icons/arrow-up.svg";
import arrowDown from "images/icons/arrow-down.svg";

import * as Styled from "./styles";

interface HourBoxProps {
  idPeriod: number;
  diaSemana: string;
  horaInicio: string;
  horaFim: string;
  defaultValue?: string[];
  updatePeriod: (
    id: number,
    diaSemana: string,
    horaInicio: string,
    horaFim: string
  ) => void;
}

export function HourBox({
  idPeriod,
  diaSemana,
  horaInicio,
  horaFim,
  defaultValue,
  updatePeriod,
}: HourBoxProps) {
  const [hourFrom, setHourFrom] = useState(() => {
    const horaInicioSplit = horaInicio.split(":");

    return {
      hour: Number(horaInicioSplit[0]) || 0,
      minute: Number(horaInicioSplit[1]) || 0,
    };
  });
  const [hourUntil, setHourUntil] = useState(() => {
    const horaFimSplit = horaFim.split(":");

    return {
      hour: Number(horaFimSplit[0]) || 0,
      minute: Number(horaFimSplit[1]) || 0,
    };
  });

  const [isMount, setIsMount] = useState(false);

  useEffect(() => {}, [diaSemana, idPeriod]);

  useEffect(() => {
    if (isMount) {
      updatePeriod(
        idPeriod,
        diaSemana,
        `${hourFrom.hour?.toString()}:${hourFrom.minute?.toString()}:00`,
        `${hourUntil.hour?.toString()}:${hourUntil.minute?.toString()}:00`
      );
      setIsMount(false);
    }
  }, [hourFrom.hour, hourFrom.minute, hourUntil.hour, hourUntil.minute]);

  const hourFromFormatted = (hour: number) => {
    return hour?.toString();
  };

  const minuteFromFormatted = (minute: number) => {
    return minute?.toString();
  };

  const hourUntilFormatted = (hour: number) => {
    return hour?.toString();
  };

  const minuteUntilFormatted = (minute: number) => {
    return minute?.toString();
  };

  function handleIncrementTimeFrom(type: "hour" | "minute") {
    if (type === "hour") {
      if (hourFrom.hour === 23) {
        return;
      }

      setHourFrom({
        ...hourFrom,
        hour: hourFrom.hour + 1,
      });
    } else {
      if (hourFrom.minute === 59) {
        return;
      }

      setHourFrom({
        ...hourFrom,
        minute: hourFrom.minute + 1,
      });
    }

    setIsMount(true);
  }

  function handleDecrementTimeFrom(type: "hour" | "minute") {
    if (type === "hour") {
      if (hourFrom.hour === 0) {
        return;
      }

      setHourFrom({
        ...hourFrom,
        hour: hourFrom.hour - 1,
      });
    } else {
      if (hourFrom.minute === 0) {
        return;
      }

      setHourFrom({
        ...hourFrom,
        minute: hourFrom.minute - 1,
      });
    }

    setIsMount(true);
  }

  function handleIncrementTimeUntil(type: "hour" | "minute") {
    if (type === "hour") {
      if (hourUntil.hour === 23) {
        return;
      }

      setHourUntil({
        ...hourUntil,
        hour: hourUntil.hour + 1,
      });
    } else {
      if (hourUntil.minute === 59) {
        return;
      }

      setHourUntil({
        ...hourUntil,
        minute: hourUntil.minute + 1,
      });
    }

    setIsMount(true);
  }

  function handleDecrementTimeUntil(type: "hour" | "minute") {
    if (type === "hour") {
      if (hourUntil.hour === 0) {
        return;
      }

      setHourUntil({
        ...hourUntil,
        hour: hourUntil.hour - 1,
      });
    } else {
      if (hourUntil.minute === 0) {
        return;
      }

      setHourUntil({
        ...hourUntil,
        minute: hourUntil.minute - 1,
      });
    }

    setIsMount(true);
  }

  return (
    <Styled.Container isDefaultValue={!!defaultValue && (defaultValue[0] === horaInicio && defaultValue[1] === horaFim)}>
      <Styled.HourInterval>
        <Styled.Hour>
          <button type="button" onClick={() => handleIncrementTimeFrom("hour")}>
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={hourFromFormatted(hourFrom.hour).padStart(2, "0")}
            onChange={(event) => {
              setHourFrom({
                ...hourFrom,
                hour: min(0, max(23, Number(event.target.value))),
              });

              setIsMount(true);
            }}
          />
          <button type="button" onClick={() => handleDecrementTimeFrom("hour")}>
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Hour>
        <span>:</span>
        <Styled.Minute>
          <button
            type="button"
            onClick={() => handleIncrementTimeFrom("minute")}
          >
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={minuteFromFormatted(hourFrom.minute).padStart(2, "0")}
            onChange={(event) => {
              setHourFrom({
                ...hourFrom,
                minute: min(0, max(59, Number(event.target.value))),
              });

              setIsMount(true);
            }}
          />
          <button
            type="button"
            onClick={() => handleDecrementTimeFrom("minute")}
          >
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Minute>
      </Styled.HourInterval>
      <span>às</span>
      <Styled.HourInterval>
        <Styled.Hour>
          <button
            type="button"
            onClick={() => handleIncrementTimeUntil("hour")}
          >
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={hourUntilFormatted(hourUntil.hour).padStart(2, "0")}
            onChange={(event) => {
              setHourUntil({
                ...hourUntil,
                hour: min(0, max(23, Number(event.target.value))),
              });

              setIsMount(true);
            }}
          />
          <button
            type="button"
            onClick={() => handleDecrementTimeUntil("hour")}
          >
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Hour>
        <span>:</span>
        <Styled.Minute>
          <button
            type="button"
            onClick={() => handleIncrementTimeUntil("minute")}
          >
            <img
              src={arrowUp}
              alt="Seta para cima"
              width="11.18px"
              height="6.59px"
            />
          </button>
          <input
            type="number"
            value={minuteUntilFormatted(hourUntil.minute).padStart(2, "0")}
            onChange={(event) => {
              setHourUntil({
                ...hourUntil,
                minute: min(0, max(59, Number(event.target.value))),
              });

              setIsMount(true);
            }}
          />
          <button
            type="button"
            onClick={() => handleDecrementTimeUntil("minute")}
          >
            <img
              src={arrowDown}
              alt="Seta para baixo"
              width="11.18px"
              height="6.59px"
            />
          </button>
        </Styled.Minute>
      </Styled.HourInterval>
    </Styled.Container>
  );
}
