import { useEffect, useRef } from "react";
import { components } from "react-select";
import { SelectExtend } from "./styles";
import arrowDownImg from "../../images/icons/arrow-down2.png";
import { useField } from "@unform/core";

interface Props {
  name: string;
  placeholder: string;
  newValue?: any;
  options: any;
  value?: any;
  isSearchable?: boolean;
  styleSingleValue?: string;
}

type SelectDropdownProps = JSX.IntrinsicElements["select"] & Props;

export default function SelectDropdown({
  name,
  newValue,
  placeholder,
  options,
  value,
  isSearchable = false,
  styleSingleValue,
  ...rest
}: SelectDropdownProps) {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField]);

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={arrowDownImg}
          alt="Seta para baixo"
          style={{ cursor: "pointer" }}
        />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      background: "white",
      padding: "0 8px",
      border: "1px solid #aec0d2",
      boxShadow: "none",
      fontFamily: "Source Sans Pro",
    }),
  };

  return (
    <SelectExtend
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: "#D7E2ED",
          primary: "#aec0d2",
          primary50: "#D7E2ED",
          neutral50: "#7B8FA5",
        },
      })}
      styles={customStyles}
      styleSingleValue={styleSingleValue}
      isSearchable={isSearchable}
      maxMenuHeight={500}
      minMenuHeight={500}
      defaultValue={newValue}
      value={newValue}
      ref={selectRef}
      isNotSelected={newValue && newValue.label === "Selecione"}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      options={[
        {
          value: "",
          label: "Selecione",
        },
        ...options,
      ]}
      placeholder={placeholder}
      isDisabled={rest.disabled}
      {...rest}
    />
  );
}
