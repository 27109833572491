import "./sidebar.scss";
import { useState } from "react";
import { usePartnerContext } from "../../../../contexts/partnerContextProvider";
import { useRouter } from "../../../../hooks/useRouter";

const Sidebar = ({ backToHome = true, partnerId = 0 }) => {
  const { isLoading, setIsLoading } = usePartnerContext();
  const [partnerStores, setPartnerStores] = useState([]);
  const router = useRouter();

  return (
    <div className="sideBar">
      <button
        onClick={() => {
          if (backToHome) router.replace("home");
          else router.replace(`manage-services?pl=${partnerId}|0`);
        }}
        className="backBtn"
      >
        Voltar
      </button>
    </div>
  );
};

export default Sidebar;
