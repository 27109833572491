import styled from "styled-components";
import colors from "tokens/colors";

export const Container = styled.div`
  max-height: 480px;
  margin: 32px 0 24px 32px;
  overflow-y: auto;
  overflow-x: hidden;

  font-family: "Source Sans Pro", sans-serif;

  p {
    font-weight: normal;
    font-size: 14px;
    color: ${colors.gray.text};
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  .formRow {
    & + .formRow {
      margin-top: 24px;
    }
    .inputCpf {
      button {
        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }
    }

    .labels-grid-beneficios {
      display: flex;
      margin-right: 1rem;
      justify-content: space-between;
    }

    .benefits-table,
    .partnerships-table {
      margin-right: 1rem;

      .button-action {
        button {
          background-color: transparent;
          border: 1px solid ${colors.blue};
          color: ${colors.blue};
          &:hover {
            background: ${colors.blueLight};
            color: white;
            border-color: ${colors.blueLight};
            transition: background 0.3s;
          }
        }
        button:first-child {
          border-right-width: 0;
        }
        button:last-child {
          border-left-width: 0;
        }
      }
    }

    .grid-custom {
      table {
        width: auto !important;
      }
    }

    .cpfNome {
      display: flex;

      label {
        font-weight: 600;
        color: ${colors.dark};
      }

      span {
        color: ${colors.gray.text};
      }

      div {
        display: flex;
        flex-direction: column;
        margin-right: 24px;
      }
    }

    .parceriaTable {
      padding-bottom: 2rem;
    }
  }
`;
