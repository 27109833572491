import './servicesModal.scss'

export default function ServicesModal({ open, setOpen, icons, clientName }) {
    const overlayHandler = (e: any) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            setOpen(false)
        }
    }

    return (
        <>
            {open && (
                <div className="servicesModalOverlay" onClick={(e) => overlayHandler(e)}>
                    <div className="servicesModal">
                        <div className="servicesModal__clientName">
                            {clientName}
                        </div>
                        <div
                            className="servicesModal__serviceIcons">
                            {icons.map((icon, index) => (
                                <div key={index} className="servicesModal__serviceIcons__service">
                                    <span className="servicesModal__serviceIcons__service__icon" >
                                        <img src={icon?.icon} alt={icon?.name} />
                                    </span>
                                    <span className="servicesModal__serviceIcons__service__name" >
                                        {icon?.name}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <button className="closeModalBtn" onClick={() => setOpen(false)}>
                            Confirmar
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
