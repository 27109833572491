import styled from "styled-components";

import { colors } from "tokens";

export const DashboardContainer = styled.div`
  margin-right: 20px;

  button {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  font-family: "Source Sans Pro";

  h2 {
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark};
    margin-bottom: 16px;
  }

  .contentBody {
    position: relative;

    min-height: 100%;

    border: 1px solid ${colors.gray.light};
    border-radius: 4px;
    margin-bottom: 2rem;

    .p-card-body {
      padding-top: 0;
    }

    .p-field-radiobutton {
      margin-bottom: .5rem;
      margin-top: .5rem;
    }

    .form {
      label {
        margin-bottom: 4px;
        color: ${colors.dark};
      }

      .required label:first-child::after {
        content: " (obrigatório)";
        color: ${colors.gray.blue};
      }

      input {
        height: 40px;

        &:disabled {
          cursor: not-allowed;
          background: #d7e2ed;
          color: #7B8FA5;
        }

        #calendarComponent {
            margin-left: -180px;
        }

        #calendarComponent {
            margin-left: -180px;
        }
      }
    }
  }

  .contentFooter {

    display: flex;
    justify-content: flex-end;

    button {
      max-width: 123px;
      min-width: 123px;
    }

    button:disabled {
      background: #f2f2f2;
      color: ${colors.gray.text};
      cursor: not-allowed;
    }

    button + button {
      margin-left: 24px;
    }
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  max-width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  }
`;
