import "./resqueduleModal.scss";
import { Calendar } from "react-date-range";
import { ptBR } from "react-date-range/src/locale";
import { useEffect, useState } from "react";
import { SelectBox } from "devextreme-react";
import { partnerDashboardService } from "services/partnerDashboardService";
import { usePartnerContext } from "contexts/partnerContextProvider";
import { format } from "date-fns-tz";
import pt from "date-fns/locale/pt-BR";
import Swal from "sweetalert2";
import { titleCase } from "utils";
import { setTimeout } from "timers";
import { partnerLoginService } from "services/partnerLoginService";
import { usePartnerAuth } from "hooks/partnerAuth";
import StepWizard from "react-step-wizard";
import { toast } from "react-toastify";

export default function ResqueduleModal({
  open,
  setOpen,
  minDate,
  maxDate,
  ids,
  handleDashboardData,
  availableDates
}) {
  const [serviceIds, storeId, scheduleId] = ids;
  const { setIsLoading, wipeOutPartnerData } = usePartnerContext();
  const { handlePartnerSignout, partnerEmail } = usePartnerAuth();
  const [date, setDate] = useState<Date|undefined>(undefined);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  const [availablePeriods, setAvailablePeriods] = useState([]);
  const [rescheduleReasons, setRescheduleReasons] = useState([{}]);
  const [reasonText, setReasonText] = useState("");
  const [newPeriod, setNewPeriod] = useState(null);
  const [rescheduleReasonId, setRescheduleReasonId] = useState(-1);
  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    
    console.log(minDate, maxDate,availableDates, ids)
    
    if(scheduleId > 0 && open)
      disabledDays(maxDate);
  }, [open, availableDates]);

  const handleStep = async (step, nextStep) => {
    // STEP 1
    if (step === 1) {
      setIsLoading(true);

      let availableHours =
        await partnerDashboardService.acquireAvailableDatePeriods({
          storeId,
          serviceIds,
          date:
            !date
              ? format(new Date(), "dd/MM/yyyy")
              : format(date, "dd/MM/yyyy"),
        });

      const {result, manha, tarde, noite} = availableHours;

      const m = manha?.map(({item1}) => item1) || [];
      const t = tarde?.map(({item1}) => item1) || [];
      const n = noite?.map(({item1}) => item1) || [];
      const hours = m.concat(t, n)

      console.log(m, t, n, hours)

      if (result === "Success") {
        if (hours.length) {
          setAvailablePeriods(hours);
          setCanProceed(false);
          setIsLoading(false);
          nextStep();
        } else {
          Swal.fire({
            title: "Ops...",
            text: "Nenhum horário disponível encontrado na(s) data(s) informada(s).",
            icon: "info",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          }).then(() => {
            handleCancel();
          });
        }
      } else {
        handleCancel();
      }
    }

    //STEP 2
    if (step === 2) {
      setIsLoading(true);

      let reasons = await partnerDashboardService.acquireReschedulingReasons();

      if (reasons.result === "Success") {
        reasons = [
          ...reasons.items,
          {
            id: 0,
            descricao: "OUTRO",
          },
        ];

        setRescheduleReasons(reasons);
        setIsLoading(false);
        setCanProceed(false);
        nextStep();
      } else {
        Swal.fire({
          title: "Ops...",
          text: "Houve um problema ao listar os motivos do reagendamento, tente novamente em instantes.",
          icon: "warning",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        }).then(() => {
          setCanProceed(false);
          setIsLoading(false);
          handleCancel();
          setTimeout(() => {
            handleCancel();
          }, 300);
        });
      }
    }

    //STEP 3
    if (step === 3) {
      setCanProceed(false);

      const partnerId = await partnerLoginService.acquirePartnerId(
        partnerEmail
      );
      if (partnerId == null || partnerId < 0) {
        wipeOutPartnerData()
        handlePartnerSignout();
      }

      const reqObj = {
        IdAgendamento: scheduleId,
        DataAgendamento: `${format(
          date || new Date(),
          "dd/MM/yyyy"
        )} ${newPeriod}`,
        IdMotivo: rescheduleReasonId,
        Motivo: reasonText,
        IdParceiro: partnerId,
      };

      setOpen(false);

      Swal.fire({
        allowOutsideClick: false,
        title: `Confirmar Reagendamento`,
        text: `Deseja realizar o reagendamento para o dia ${format(
          date || new Date(),
          "dd/MM/yyyy"
        )} (${titleCase(
          format(date || new Date(), "EEEE", { locale: pt })
        )}) às ${newPeriod}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0054A6",
        cancelButtonColor: "#ed1d24",
        confirmButtonText: "Reagendar",
        cancelButtonText: "Abandonar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const resquedule =
            await partnerDashboardService.performServiceRescheduling(reqObj);
          if (resquedule.result === "Success") {
            setIsLoading(false);
            Swal.fire({
              title: "Serviços Reagendados",
              text: "Os serviços foram reagendados com sucesso para a nova data e horário.",
              icon: "success",
              confirmButtonColor: "#0054A6",
              confirmButtonText: "Entendi",
            }).then(() => {
              setOpen(false);
              handleCancel();
              handleDashboardData();
            });
          } else {
            setIsLoading(false);
          }
        } else {
          handleCancel();
          setTimeout(() => {
            handleCancel();
          }, 300);
        }
      });
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
    setAvailablePeriods([]);
    setRescheduleReasons([{}]);
    setRescheduleReasonId(-1);
    setNewPeriod(null);
    setDate(undefined);
    setCanProceed(false);
    setOpen(false);
  };

  const getDays = (endDate: Date) => {
    var date = new Date();
    endDate.setDate(endDate.getDate());

    var days: string[] = [];
    while (date <= endDate) {
        let dia = date.getDate().toString();
        dia = (dia.length === 1) ? '0' + dia : dia;

        const mes = date.getMonth() + 1;
        let mesStr = (mes < 10) ? '0' + mes : mes;
        dia = date.getFullYear() + '-' + mesStr + '-' + dia;

        days.push(dia);
        date.setDate(date.getDate() + 1);
    }
    return days;
  }

  const disabledDays = (maxDate: any) => {
    let dias = getDays(new Date(maxDate + "T10:00"));
    let indisponiveis: Date[] = [];
    dias.forEach((d) => {
        const disponivel = availableDates.filter((row: any) => row === d).length > 0;

        if (!disponivel) {
            indisponiveis.push(new Date(d + "T10:00"));//workarround UTC
        }
    });

    setDisabledDates(indisponiveis);
  }

  const StepOne = (props) => {
    const { nextStep } = props;

    return (
      <>
        <div className="resqueduleModal__dateSelection">
          <p>Selecione a nova data:</p>
          {
            disabledDates && disabledDates.length > 0 ?
            (<Calendar
              color={"#0054a6"}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              onChange={(item) => {
                setDate(item);
                setCanProceed(true);
              }}
              locale={ptBR}
              date={date}
              fixedHeight={true}
              disabledDates={disabledDates}
            />) :
            <Calendar
              color={"#0054a6"}
              minDate={new Date(minDate)}
              maxDate={new Date(minDate)}
              locale={ptBR}
              date={date}
              fixedHeight={true}
            />
          }
        </div>

        <div className="resqueduleModal__buttons">
          <button
            className="cancelBtn"
            onClick={() => {
              handleCancel();
              setTimeout(() => {
                handleCancel();
              }, 300);
            }}
          >
            Cancelar
          </button>
          &nbsp;&nbsp;
          <button
            className="proceedBtn"
            onClick={async () => {
              await handleStep(1, nextStep);
            }}
            disabled={!canProceed}
          >
            Prosseguir
          </button>
        </div>
      </>
    );
  };

  const StepTwo = (props) => {
    const { nextStep } = props;

    const onTimeValueChanged = (e) => {
      if (e.value === null) {
        setCanProceed(false);
        return;
      }
      setNewPeriod(e.value);
      setCanProceed(true);
    };

    return (
      <>
        <style>
          {`
                    .dx-scrollview-content {
                        max-height: 180px !important;
                    }
                `}
        </style>
        <div className="resqueduleModal__timeSelection">
          <p>Selecione o novo horário:</p>
          <strong>
            {format(date || new Date(), "dd/MM/yyyy")} (
            {titleCase(format(date || new Date(), "EEEE", { locale: pt }))}) às:
          </strong>
          <br />
          <SelectBox
            defaultValue={newPeriod}
            items={availablePeriods}
            placeholder="Selecione..."
            showClearButton={true}
            onValueChanged={onTimeValueChanged}
          />
        </div>

        <div className="resqueduleModal__buttons">
          <button
            className="cancelBtn"
            onClick={() => {
              handleCancel();
              setTimeout(() => {
                handleCancel();
              }, 300);
            }}
          >
            Cancelar
          </button>
          &nbsp;&nbsp;
          <button
            className="proceedBtn"
            onClick={async () => {
              await handleStep(2, nextStep);
            }}
            disabled={!canProceed}
          >
            Prosseguir
          </button>
        </div>
      </>
    );
  };

  const FinalStep = (props) => {
    const { nextStep } = props;

    const handleText = async () => {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Motivo do reagendamento",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Confirmar",
        inputPlaceholder: "Descreva brevemente o motivo...",
        inputAttributes: {
          "aria-label": "Descreva brevemente o motivo...",
        },
        showCancelButton: false,
        allowOutsideClick: false,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value && value.trim().length) {
              resolve(null);
            } else {
              resolve("Por favor, descreva motivo acima");
            }
          });
        },
      });

      return text;
    };

    const onReasonValueChanged = async (e) => {
      if (e.value === null) {
        setCanProceed(false);
        return;
      }

      if (e.value === 0) {
        setRescheduleReasonId(e.value);

        let text = await handleText();

        if (text) {
          Swal.fire("Motivo", text, "success");
          setReasonText(text);
        } else {
          toast.error("Digite um motivo válido!");
          setCanProceed(false);
          return;
        }
      }

      setRescheduleReasonId(e.value);
      setCanProceed(true);
    };

    return (
      <>
        <style>
          {`
            .dx-scrollview-content {
              max-height: 180px !important;
            }
          `}
        </style>
        <div className="resqueduleModal__reasonSelection">
          <p>Selecione o motivo:</p>

          <SelectBox
            defaultValue={rescheduleReasonId}
            items={rescheduleReasons}
            noDataText="Nada para exibir"
            displayExpr={"descricao"}
            valueExpr={"id"}
            searchEnabled={true}
            searchMode="contains"
            searchExpr={"descricao"}
            searchTimeout={500}
            showDataBeforeSearch={false}
            placeholder="Selecione..."
            showClearButton={true}
            onValueChanged={onReasonValueChanged}
          />
        </div>

        <div className="resqueduleModal__buttons">
          <button
            className="cancelBtn"
            onClick={() => {
              handleCancel();
              setTimeout(() => {
                handleCancel();
              }, 300);
            }}
          >
            Cancelar
          </button>
          &nbsp;&nbsp;
          <button
            className="proceedBtn"
            onClick={async () => {
              await handleStep(3, nextStep);
            }}
            disabled={!canProceed}
          >
            Prosseguir
          </button>
        </div>
      </>
    );
  };

  return (
    <>
      {open && (
        <div className="resqueduleModalOverlay">
          <div className="resqueduleModal">
            <div className="resqueduleModal__header">
              Reagendamento de Serviços
            </div>

            <StepWizard className="resqueduleModal__stepper">
              <StepOne />
              <StepTwo />
              <FinalStep />
            </StepWizard>
          </div>
        </div>
      )}
    </>
  );
}
