import axios from "axios";
import axiosConfig from "../config/axiosConfig";

export const transferenciaService = {
  getHistoricoData: async (cpf: string) => {
    const req = await axios.get(
      `/BeneficioData/GetHistoricoTransferencia/${cpf}`,
      axiosConfig
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      return null;
    }
  },
  transfererBenefit: async (payload: any, header: any) => {
    const req = await axios.post(
      `/BeneficioData/transferir-titularidade`,
      payload,
      {
        headers: header,
      }
    );
    const { data } = req;
    return data;
  },
};
