import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTotalNotification } from 'hooks/totalNotification';
import {
  Container,
  ContainerImg,
  ButtonText,
  ButtonBell,
  ButtonMenu,
  ContainerMenu,
  ContainerDropdown,
} from "./styles";
import CardNotification from 'components/CardNotification';
import logoImg from "../../images/logo-paguemenos.png";
import { useAuth } from "hooks/auth";

export default function Header() {
  const { totalNotification, handleTotalNotification } = useTotalNotification();
  const { handleSignOut, permissoes } = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const [isNotification, setIsNotification] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  // const [totalNotification, setTotalNotification] = useState<number>(0);
  useOnClickOutside(ref, () => setIsMenuModalOpen(false));

  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

  // useEffect(() => {
  //   if(selectedStores.length === 1){
  //   axios.get(`/AgendamentoData/GetTotalNotificacoes?codigoLoja=${selectedStores[0].codigo}`).then((response) => {
  //     console.log(response.data);
  //       if(response.data.result === "Success"){
  //         if(response.data.total > 0){
  //           setTotalNotification(response.data.total);
  //           setIsNotification(true);
  //         } else {
  //           setIsNotification(false);
  //         }
  //       }
  //   })}
  // }, [setTimeout(() => {}, 2000), openNotification, isMenuModalOpen])

    // TODO: NOTIFICAÇÃO
    // useEffect(() => {
    //     let isMounted = true
    //     const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
    //         handleTotalNotification();
    //     }, 300000)

    //     return () => {
    //         clearInterval(intervalId); //This is important
    //         isMounted = false // Let's us know the component is no longer mounted.
    //     }

    // }, [useState])
    // TODO: NOTIFICAÇÃO

  // // useEffect(() => {
  // //   debugger;
  // //   window.removeEventListener("click", detectClickOusideMenu);
  // //   window.addEventListener("click", detectClickOusideMenu);

  // //   return () => window.removeEventListener("click", detectClickOusideMenu);
  // // }, [isMenuModalOpen]);

  // // function detectClickOusideMenu(e: MouseEvent) {
  // //   const menuModal = document.getElementById("menuModal");
  // //   debugger;
  // //   // @ts-ignore
  // //   if (!menuModal?.contains(e.target)) {
  // //     if (!menuModal) {
  // //       return;
  // //     }

  // //     // if (isMenuModalOpen) {
  // //       setIsMenuModalOpen(isMenuModalOpen);
  // //     // }
  // //   }
  // // }

  function useOnClickOutside(ref: any, handler: any) {
    useEffect(
      () => {
        const listener = (event: MouseEvent) => {
          if (!ref.current || ref.current.contains(event.target))
            return;

          handler(event);
        };
        document.addEventListener("mousedown", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
        };
      },
      [ref, handler]
    );
  }

  useEffect(() => {
    window.removeEventListener("click", detectClickOusideNotification);
    window.addEventListener("click", detectClickOusideNotification);

    return () => window.removeEventListener("click", detectClickOusideNotification);
  }, [openNotification]);

  function detectClickOusideNotification(e: MouseEvent) {
    const myNotification = document.getElementById("notification");

    // @ts-ignore
    if (!myNotification?.contains(e.target)) {
      if (!myNotification) {
        return;
      }

      if (openNotification) {
        setOpenNotification(false);
      }
    }
  }

  function handleOpenNotification() {
    setOpenNotification(prevState => !prevState);
  }

  return (
    <Container>
      <header>
        <ContainerImg>
          <Link to="/portaladmin/home">
            <img src={logoImg} alt="Logo PagueMenos" />
          </Link>
        </ContainerImg>

        <nav>
          {permissoes?.filter((item: any) => item.route[0] !== "agendamento")
            .length > 0 && (
            <ButtonText to="/portaladmin/agendamento">Agendar</ButtonText>
          )}
          <ButtonBell isNotification={(totalNotification>0)} totalNotification={totalNotification?.toString()} onClick={() => handleOpenNotification()}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="bell"
            >
              <g id="Social / notifications">
                <mask
                  id="mask0"
                  mask-type="alpha"
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="2"
                  width="16"
                  height="20"
                >
                  <g id="Icon Mask">
                    <path
                      id="Round"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.0013 10.75V15.75L19.2913 17.04C19.9213 17.67 19.4713 18.75 18.5813 18.75H5.41127C4.52127 18.75 4.08127 17.67 4.71127 17.04L6.00127 15.75V10.75C6.00127 7.67 7.63127 5.11 10.5013 4.43V3.75C10.5013 2.92 11.1713 2.25 12.0013 2.25C12.8313 2.25 13.5013 2.92 13.5013 3.75V4.43C16.3613 5.11 18.0013 7.68 18.0013 10.75ZM14.0013 19.75C14.0013 20.85 13.1013 21.75 12.0013 21.75C10.8913 21.75 10.0013 20.85 10.0013 19.75H14.0013Z"
                      fill="black"
                    />
                  </g>
                </mask>
                <g mask="url(#mask0)">
                  <rect id="Color Fill" width="24" height="24" style={{ fill: `${(totalNotification>0) ? '#C91C17' : '#FFFFFF'}`, }} />
                </g>
              </g>
            </svg>
          </ButtonBell>
          <ContainerMenu>
            <ButtonMenu onClick={() => setIsMenuModalOpen(!isMenuModalOpen)}>
              <svg
                width="17"
                height="20"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5625 2.89062H15.1875C15.4688 2.89062 15.75 2.64453 15.75 2.32812V0.921875C15.75 0.640625 15.4688 0.359375 15.1875 0.359375H0.5625C0.246094 0.359375 0 0.640625 0 0.921875V2.32812C0 2.64453 0.246094 2.89062 0.5625 2.89062ZM0.5625 8.51562H15.1875C15.4688 8.51562 15.75 8.26953 15.75 7.95312V6.54688C15.75 6.26562 15.4688 5.98438 15.1875 5.98438H0.5625C0.246094 5.98438 0 6.26562 0 6.54688V7.95312C0 8.26953 0.246094 8.51562 0.5625 8.51562ZM0.5625 14.1406H15.1875C15.4688 14.1406 15.75 13.8945 15.75 13.5781V12.1719C15.75 11.8906 15.4688 11.6094 15.1875 11.6094H0.5625C0.246094 11.6094 0 11.8906 0 12.1719V13.5781C0 13.8945 0.246094 14.1406 0.5625 14.1406Z"
                  fill="white"
                />
              </svg>
            </ButtonMenu>
            {isMenuModalOpen && (
              <div ref={ref}>
                <ContainerDropdown id="menuModal">
                  <div>
                    {permissoes?.map((item: any) => {
                      return (
                        <p key={item.route[0]}>
                          <Link
                            to={`/portaladmin/${item.route[0]}`}
                            onClick={() => setIsMenuModalOpen(false)}
                          >
                            {item.label}
                          </Link>
                        </p>
                      );
                    })}
                    <p>
                      <a href="/" onClick={handleSignOut}>
                        SAIR
                      </a>
                    </p>
                  </div>
                </ContainerDropdown>
              </div>
            )}
          </ContainerMenu>
        </nav>
      </header>
      {openNotification && 
      <div id="notification">
        <CardNotification />
      </div>
      }
    </Container>
  );
}
