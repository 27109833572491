import {
  Container,
  Description,
  Content,
  ButtonCancel,
  ButtonContinue,
} from "./styles";
import { ModalBase } from "../ModalBase/index";
import { HourBox } from "../../HourBox";
import { ReactNode, useEffect, useState } from "react";
import IconInputCalendar from "images/icons/icon-inputCalendar.svg";
import { Calendar } from "components/Calendar";
import { isEqual } from "date-fns";
import { convert } from "utils";

interface ModalDataProps {
  dateInicial: string;
  dateFinal: string;
  idPeriodo: number;
  diaSemana: string;
  horaInicial: string;
  horaFinal: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (dateBlock: string[], hourBlock: string[]) => void;
  onSetModalData: (data: ModalDataProps) => void;
  Icon: string;
  Title: string;
  Info: string | ReactNode;
  defaultValueDate?: Date[];
}

const today = new Date();

export function AgendaBlockRelease({
  onRequestClose,
  onContinue,
  onSetModalData,
  isOpen,
  Icon,
  Title,
  Info,
  defaultValueDate = [],
}: ModalProps) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [dateSelec, setDateSelec] = useState("");
  const [idPeriodo, setIdPeriodo] = useState<number>({} as number);
  const [diaSemana, setDiaSemana] = useState<string>("");

  const [horaInicial, setHoraInicial] = useState<string>("00:00");
  const [horaFinal, setHoraFinal] = useState<string>("23:30");

  const [dateInicial, setDateInicial] = useState<string>("");
  const [dateFinal, setDateFinal] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onSetModalData({
      dateInicial,
      dateFinal,
      idPeriodo,
      diaSemana,
      horaInicial,
      horaFinal,
    });
    
    if (dateSelec === "") {
        handleDateSelect(defaultValueDate);
    }

    window.removeEventListener("click", detectClickOusideModalCalendar);
    if (openCalendar) {
      window.addEventListener("click", detectClickOusideModalCalendar);
    }

    return () => {
      window.removeEventListener("click", detectClickOusideModalCalendar);
    };
  }, [
    openCalendar,
    dateInicial,
    dateFinal,
    idPeriodo,
    diaSemana,
    horaInicial,
    horaFinal,
  ]);

  function detectClickOusideModalCalendar(e: MouseEvent) {
    const calendarComponent = document.getElementById("calendarComponent");

    // @ts-ignore
    if (!calendarComponent?.contains(e.target)) {
      if (!calendarComponent) {
        return;
      }

      if (openCalendar) {
        setOpenCalendar(false);
      }
    }
  }

  function handleDateSelect(date: Date[] | undefined) {
      if (date === undefined)
          return;

    let initialDate = date;
    if (date && date.length) {
      setSelectedDates(date);
    }

    const formattedInitialDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[0]
    );
    const formattedFinalDate = new Intl.DateTimeFormat("pt-BR").format(
      initialDate[1]
    );

    setDateInicial(formattedInitialDate);
    setDateFinal(formattedFinalDate);

    const dateIsEqual = checkIfDateIsEqual(formattedInitialDate, formattedFinalDate);

    if (dateIsEqual) {
      setDateSelec(formattedInitialDate);
    } else {
      setDateSelec(formattedInitialDate + " até " + formattedFinalDate);
    }
  }
  
  function handleUpdatePeriod(
    id: number,
    diaSemana: string,
    horaInicial: string,
    horaFinal: string
  ) {
    setIdPeriodo(id);
    setDiaSemana(diaSemana);
    const splitHourInitial = horaInicial.split(":");
    const splitHourFinal = horaFinal.split(":");
    const hourInitial =
      splitHourInitial[0].padStart(2, "0") +
      ":" +
      splitHourInitial[1].padStart(2, "0");
    const hourFinal =
      splitHourFinal[0].padStart(2, "0") +
      ":" +
      splitHourFinal[1].padStart(2, "0");
    setHoraInicial(hourInitial);
    setHoraFinal(hourFinal);
  }

  function handleOpenCalendar() {
    setOpenCalendar(!openCalendar);
  }

  function checkIfDateIsEqual(dataInicial: string, dataFinal: string) {
    return isEqual(
      new Date(
        convert({
          dateString: dataInicial,
          pattern: "yyyy-mm-dd",
        })
      ),
      new Date(
        convert({
          dateString: dataFinal,
          pattern: "yyyy-mm-dd",
        })
      )
    );
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="medium">
      <Container>
        <Description>
          <div>
            <img src={Icon} alt="" />
          </div>
          <label>{Title}</label>
          <p>{Info}</p>
        </Description>

        <Content openCalendar={openCalendar}>
          <div className="calendar">
            <span>Data do agendamento</span>
            <div className="inputCalendar" onClick={() => handleOpenCalendar()}>
              <input
                type="text"
                placeholder="Selecione uma data"
                value={dateSelec}
                readOnly
              />
              <img src={IconInputCalendar} alt="calendar" />
            </div>

            {openCalendar && (
              <div className="calendarComponent" id="calendarComponent">
                <Calendar
                  handleSetDate={(date) => {
                    handleDateSelect(date);
                    if (!!date && date.length === 2) {
                      const dataInicial = Intl.DateTimeFormat("pt-BR").format(
                        date[0]
                      );
                      const dataFinal = Intl.DateTimeFormat("pt-BR").format(
                        date[1]
                      );

                      const dateIsEqual = checkIfDateIsEqual(dataInicial, dataFinal);

                      if (dateIsEqual) {
                        setDateSelec(dataInicial);
                      } else {
                        setDateSelec(dataInicial + " até " + dataFinal);
                      }
                      setOpenCalendar(false);
                    }
                  }}
                  defaultDateSelected={defaultValueDate}
                />
              </div>
            )}
          </div>

          <div className="horario">
            <span className="select">Selecione um horário</span>
            <div>
              <HourBox
                key={1}
                diaSemana=""
                horaInicio={horaInicial}
                horaFim={horaFinal}
                defaultValue={["00:00", "23:30"]}
                idPeriod={0}
                updatePeriod={handleUpdatePeriod}
              />
            </div>
          </div>
        </Content>

        {isLoading && <p>Carregando...</p>}

        {!isLoading && (
          <div className="buttons">
            <ButtonCancel onClick={onRequestClose}>Cancelar</ButtonCancel>
            <ButtonContinue
              disabled={dateSelec.length === 0}
              onClick={() => {
                setIsLoading(true);
                onContinue(
                  selectedDates.map((date) => date?.toLocaleDateString("en")),
                  [horaInicial, horaFinal]
                );
              }}
            >
              Continuar
            </ButtonContinue>
          </div>
        )}
      </Container>
    </ModalBase>
  );
}
