import { CSSProperties, useEffect, useRef, useState } from "react";
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

import { ModalBase } from "components/ModalGroup/ModalBase";
import Button from "components/Button";

import IconClose from "images/icons/icon-close-modal.png";

import { Container } from "./styles";

interface FormUserAskMaintainerProps {
    onChange: (e: any) => void;
    onRequestClose: () => void;
    confirm: () => void;
    onCancel?: () => void;
    title: string;
    isOpen: boolean;
    showCancel?: boolean;
    continueButtonText?: string;
    cancelButtonText?: string;
    buttonsMarginTop?: string;
    widthButtonContinue?: string;
    widthButtonClose?: string;
    heightButton?: string;
    marginTitle?: string;
    marginTitleBottom?: string;
    marginButton?: string;
    containerStyle?: CSSProperties;
    loading?: boolean;
    value?: any;
}

export const FormUserAskMaintainer = ({
    onChange,
    onRequestClose,
    confirm,
    title,
    isOpen,
    showCancel = false,
    continueButtonText = "Continuar",
    cancelButtonText = "Cancelar",
    buttonsMarginTop = "32px",
    widthButtonClose = "123px",
    widthButtonContinue = "132px",
    heightButton = "48px",
    marginTitle = "24px",
    marginTitleBottom = "",
    marginButton,
    onCancel,
    containerStyle = {},
    loading = false,
    value
}: FormUserAskMaintainerProps) => {

    const [isLoading, setIsLoading] = useState(false);
    const [valueState, setValueState] = useState(value);
    const [showMessage, setShowMessage] = useState(false);
    const inputNumber  = useRef(null);

    useEffect(() => setIsLoading(loading), [loading]);
    useEffect(() => setShowMessage(false), [isOpen]);

    const getFormErrorMessage = (name: string) => {
        return <small className="p-error d-flex">{`*Campo ${name} obrigatório.`}</small>
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
        
        let inputRef: any = inputNumber.current;
        let value = inputRef.value;
        if(!value || value <= 0)
        {
            setShowMessage(true);
            return;
        }

        setShowMessage(false);
        confirm();
    };
    
    return (
        <ModalBase isOpen={isOpen} onRequestClose={onRequestClose}>
            <Container style={containerStyle} showCancel={showCancel} buttonsMarginTop={buttonsMarginTop}>
                <img
                src={IconClose}
                alt="close"
                style={{
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer"
                }}
                onClick={onRequestClose}
                />
            <h2 style={{ marginTop: marginTitle, marginBottom: marginTitleBottom }}>
                {title}
            </h2>            
            <form className="p-fluid" onSubmit={onSubmit}>
                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="firstname2">Digite a matrícula do <strong>FARMACÊUTICO</strong></label>
                        <span className="p-float-label">
                            <InputNumber
                                inputRef={inputNumber}
                                inputId="integeronly" mode="decimal" useGrouping={false}
                                value={valueState} onValueChange={onChange}
                                placeholder="Ex: 99999999" autoFocus
                                className={classNames({ 'p-invalid': showMessage })}
                                onKeyDown={(e: any) =>
                                {
                                    if(e.target?.value && e.target?.value.length > 8)
                                        e.preventDefault();
                                }}
                                onBlur={e => {
                                    let value = e.target.value;
                                    setShowMessage((!value || value.length <= 0) ? true : false);
                                }}
                            />
                        </span>
                        {showMessage && getFormErrorMessage('Matrícula')}
                    </div>
                </div>
 
                <div className={classNames({ marginButton, 'd-flex flex-1': true })}>
                    {showCancel && (
                    <Button
                        onClick={onCancel ? onCancel : onRequestClose}
                        width={widthButtonClose}
                        inverted
                        disabled={isLoading}
                        className='mw-unset flex-1'
                    >
                        <i className="pi pi-times" style={{'marginRight': '0.5rem'}}></i>
                        {cancelButtonText}
                    </Button>
                    )}
                    <Button
                        width={widthButtonContinue}
                        height={heightButton}
                        disabled={isLoading}
                        className='mw-unset flex-1'
                    >
                        <i className={"pi " + (isLoading ? "pi-spin pi-spinner" : "pi-check")} style={{'marginRight': '0.5rem'}}></i>
                        {continueButtonText}
                    </Button>
                </div>
            </form>
            </Container>
        </ModalBase>
    );
}