import './storeSelect.scss'
import DropDownBox from "devextreme/ui/drop_down_box";
import DataGrid from "devextreme/ui/data_grid";
import { useEffect, useState } from 'react';
import { usePartnerContext } from 'contexts/partnerContextProvider';
import { partnerDashboardService } from 'services/partnerDashboardService';
import { toast } from 'react-toastify';
import { DropDownBox as DropDown } from 'devextreme-react/drop-down-box';
import {
    DataGrid as Grid,
    Selection, Paging, FilterRow, Scrolling, Column,
} from 'devextreme-react/data-grid';
import { partnerLoginService } from 'services/partnerLoginService';
import { usePartnerAuth } from 'hooks/partnerAuth';

export const clearStoreSelectBox = () => {
    const selectBox: any = document.querySelector('#storeSelectDropDownBox')
    let instance = DropDownBox.getInstance(selectBox) as DropDownBox;
    instance.reset()

    const selectBoxGrid: any = document.querySelector('#storeSelectDropDownBoxGrid')
    let grid_instance = DataGrid.getInstance(selectBoxGrid) as DataGrid;
    grid_instance.option('dataSource', [])
}

interface Store {
    cdLoja: number;
    nomeLoja: string;
}

export default function StoreSelect({ disabled, filterCities, setFilterStores }) {
    const { setIsLoading, selectedCities, wipeOutPartnerData } = usePartnerContext();
    const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
    const [stores, setStores] = useState<Store[]>([])
    const [gridBoxValue, setgridBoxValue] = useState<Store[]>([])
    const [inputPlaceholder, setInputPlaceholder] = useState('Selecione a(s) loja(s)')

    useEffect(() => {
        async function handleStores() {
            setIsLoading(true)

            return partnerLoginService.acquirePartnerId(partnerEmail)
                .then(async partnerId => {
    
                    if (partnerId == null || partnerId < 0) {
                        wipeOutPartnerData()
                        handlePartnerSignout();
                    }
    
                    const statesData = await partnerDashboardService.acquireStores(filterCities, partnerId);
                    const { items } = statesData;
        
                    if (items && items.length) setStores(items);
                    else toast.error("Ocorreu um erro ao buscar as lojas.");
                })
                .catch(_ => {
                    wipeOutPartnerData()
                    handlePartnerSignout()
                })
                .finally(() => setIsLoading(false));
        }

        if (selectedCities[0] !== "" && selectedCities !== null && selectedCities[0] !== null) {
            handleStores()
        }
    }, [filterCities, selectedCities])

    function dataGridRender() {
        return (
            <>
                <style>
                    {`
                        #storeSelectGrid .dx-command-select {  
                            width: 25px!important;  
                            min-width: 25px!important;  
                        }  

                        .dx-popup-wrapper > .dx-overlay-content{
                            width: auto !important;
                        }
                    `}
                </style>
                <div id="storeSelectGrid">
                    <Grid
                        noDataText="Nada para exibir"
                        id="storeSelectDropDownBoxGrid"
                        style={{ maxHeight: '250px', width: '100%' }}
                        dataSource={stores}
                        hoverStateEnabled={true}
                        allowColumnResizing
                        selectedRowKeys={gridBoxValue}
                        onSelectionChanged={dataGridOnSelectionChanged}>
                        <Selection mode="multiple" />
                        <Paging enabled={true} pageSize={10} />
                        <FilterRow visible={true} />
                        <Scrolling mode="virtual" />
                        <Column
                            width="auto"
                            allowSorting={true}
                            dataField="cdLoja"
                            caption="Cód." />
                        <Column
                            width="auto"
                            allowSorting={true}
                            dataField="nomeLoja"
                            caption="Lojas" />
                    </Grid>
                </div>
            </>
        );
    }

    const dataGridOnSelectionChanged = (e) => {
        setgridBoxValue(e.selectedRowKeys)
        if (e?.selectedRowKeys?.length > 0) {
            setInputPlaceholder(e.selectedRowKeys.map((value) => {
                return value.nomeLoja
            }).join(', '))
        } else {
            setInputPlaceholder("Selecione a(s) loja(s)")
        }
    }

    const syncDataGridSelection = (e) => {
        setgridBoxValue(e.value || [])
        if (e?.value?.length > 0) {
            setInputPlaceholder(e.value.map((value) => {
                return value.nomeLoja
            }).join(', '))
        } else {
            setInputPlaceholder("Selecione a(s) loja(s)")
        }
    }

    const handleSelectedStores = () => {
        const mapStores = gridBoxValue.map((store) => {
            return store.cdLoja
        })
        setFilterStores(mapStores)
    }

    return (
        <div className="storeSelect">
            <span className="storeSelect__label">Lojas</span>

            <div title={inputPlaceholder} className="citySelect__field">
                <DropDown
                    id="storeSelectDropDownBox"
                    disabled={disabled}
                    value={gridBoxValue}
                    valueExpr="cdLoja"
                    deferRendering={false}
                    displayExpr="nomeLoja"
                    placeholder={inputPlaceholder}
                    showClearButton={true}
                    dataSource={stores}
                    onValueChanged={syncDataGridSelection}
                    contentRender={dataGridRender}
                />

                <button
                    onClick={() => handleSelectedStores()}
                    className="citySelect__field__proceedButton"
                    disabled={!gridBoxValue.length}>
                    OK
                </button>
            </div>
        </div>
    )
}

