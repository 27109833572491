import styled from 'styled-components';
import { colors } from 'tokens';

export const Container = styled.div`
  display: flex;

  button {
    min-width: 32px;
    padding-left: 2px;
    padding-right: 2px;
    height: 32px;
    background: transparent;
    color: ${colors.blue};
    border: 1px solid ${colors.gray.light};
    border-radius: 4px;

    &.disabled {
      background: ${colors.gray.light};
      cursor: not-allowed;

      svg path {
        fill: ${colors.gray.blue};
      }
    }
  }

  > div {
    padding: 0 8px;
    
    button {
      color: ${colors.gray.text};

      &.selected {
        border-color: ${colors.blue};
        color: ${colors.blue};
      }
      
      & + button {
        margin-left: 8px;
      }
    }
  }
`;