import { ReactElement } from "react";
import * as S from "./styles";

interface TabProps {
  title: string;
  selected?: boolean;
  icon: ReactElement;
  onClick?: () => void;
}

export function Tab({ title, icon, selected = false, onClick }: TabProps) {
  return (
    <S.Container onClick={onClick} selected={selected}>
      {icon}
      <a>{title}</a>
    </S.Container>
  );
}
