import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  min-width: 565px;

  input {
    color: ${colors.dark};
  }

  input::placeholder {
    color: "#7B8FA5";
  }

  strong {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #111417;
  }

  .busca {
    width: 565px;
    height: 40px;
    margin-top: 16px;
    padding: 8px 8px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;

    svg {
      position: absolute;
      right: 40px;
      margin-top: 2px;
    }
  }

  .grid {
    margin-top: 20px;
  }

  .botao {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;
