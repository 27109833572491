import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
max-height: 318px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Source Sans Pro", sans-serif;
  text-align: center;

  img {
    width: 66px;
    height: 66px;
  }

  h2 {
    color: #2a333e;
    font-size: 26px;
    font-weight: 600;
    margin-top: 8px;
  }

  p {
    color: #404f61;
    margin: 0;
  }

  .container__buttons {
    margin-top: 20.8px;
    max-width: 279px;
    width: 100%;

    display: flex;
    justify-content: center;

    button {
      text-align: center;
    }

    div > button {
      margin-left: 24px;
      background: ${colors.blue};
      color: ${colors.white};
      border-radius: 4px;
      min-width: 125px;
      max-width: 125px;
      height: 48px;
    }
  }

  .marginButton {
    margin-bottom: 18px;
  }
`;
