import { useEffect, useState } from "react";
import { useLoader } from "hooks/loader";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";

import { transferenciaService } from "services/transferenciaService";

interface modalTransferenciaProps {
  isOpen: boolean;
  onRequestClose: () => void;
  cpf: string;
}

interface historicoTransferenciaProps {
  idTransf: number;
  servico: string;
  origem: string;
  destino: string;
  colaborador: string;
  status: string;
  data: Date;
}

const columns = [
  {
    field: "idTransf",
    header: "Código",
    style: { width: "4.5rem", textAlign: "center" },
  },
  {
    field: "servico",
    header: "Serviço transferido",
    style: { width: "16rem" },
    filterField: true,
  },
  {
    field: "origem",
    header: "Origem",
    style: { width: "8rem", textAlign: "center" },
  },
  {
    field: "destino",
    header: "Destino",
    style: { width: "8rem", textAlign: "center" },
  },
  {
    field: "status",
    header: "Status",
    style: { width: "8rem", textAlign: "center" },
  },
  {
    field: "colaborador",
    header: "Colab.",
    style: { width: "8rem", textAlign: "center" },
  },
  {
    field: "data",
    header: "Data",
    style: { width: "8rem", textAlign: "center" },
  },
];

const dynamicColumns = columns.map((col) => {
  return (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      style={col.style}
      columnKey={col.field}
      filterField={!!col.filterField ? col.field : undefined}
    />
  );
});

export function ModalHistoricoTransferencia({
  isOpen,
  onRequestClose,
  cpf,
}: modalTransferenciaProps) {
  const { handleSetIsLoader } = useLoader();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [dataFilter, setDataFilter] = useState<historicoTransferenciaProps[]>(
    []
  );

  useEffect(() => {
    if (cpf) loadHistoricoTransferencia(cpf);
  }, [cpf]);

  const renderHeader = () => {
    return (
      <div className="table-header w-100 mb-3">
        <span className="p-input-icon-left  w-100">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="w-100"
            onInput={(e: any) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
      </div>
    );
  };

  const loadHistoricoTransferencia = async (cpf: string) => {
    try {
      handleSetIsLoader(true);
      const response = await transferenciaService.getHistoricoData(cpf);

      if (response !== null) {
        const data = response.items.map((item: any) => {
          return {
            idTransf: item.id,
            codigo: item.id,
            servico: item.nomeServico,
            origem: item.cpfOrigem,
            destino: item.cpfDestino,
            colaborador: item.colaborador,
            status: item.direcao,
            data: item.dataTransferencia,
          } as historicoTransferenciaProps;
        });

        setDataFilter(data);
        handleSetIsLoader(false);
      }
    } catch (error) {
    } finally {
      handleSetIsLoader(false);
      return;
    }
  };

  return (
    <Dialog
      header="Histórico de transferências"
      visible={isOpen}
      style={{ width: "60vw" }}
      onHide={onRequestClose}
      className="modal--history-transfer"
    >
      {renderHeader()}
      <div className="table">
        <DataTable
          dataKey="idTransf"
          value={dataFilter}
          className="p-datatable-responsive-demo p-datatable-sm history-transfer-table"
          emptyMessage="Nenhuma informação para ser exibida"
          resizableColumns
          showGridlines
          scrollable
          rowHover
          scrollHeight="400px"
          globalFilter={globalFilter}
        >
          {dynamicColumns}
        </DataTable>
      </div>
    </Dialog>
  );
}
