import {
    createContext,
    useContext,
    useState,
} from "react";

interface PartnerData {
    aceite: boolean;
    cnpj: string;
    dataAtivacao: string;
    dataCriacao: string;
    dataDesativacao: string;
    emailResponsavel: string;
    emailSac: string;
    idParceiro: number;
    nomeResponsavel: string;
    razaoSocial: string;
    status: boolean
    telefoneResponsavel: string;
    telefoneSac: string;
    tipoParceiro: number
}

interface PartnerContextContract {
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;

    partnerData: PartnerData;
    setPartnerData: (value: PartnerData) => void;

    selectedState: string;
    setSelectedState: (value: string) => void;

    selectedCities: string[];
    setSelectedCities: (value: string[]) => void;

    selectedNeighborhoods: string[];
    setSelectedNeighborhoods: (value: string[]) => void;

    selectedStores: string[];
    setSelectedStores: (value: string[]) => void;

    startDate: Date;
    setStartDate: (value: Date) => void;

    endDate: Date;
    setEndDate: (value: Date) => void;

    wipeOutPartnerData: () => void;
}

const PartnerContext = createContext({} as PartnerContextContract);

function PartnerContextProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false)
    const [partnerData, setPartnerData] = useState<PartnerData>({
        aceite: false,
        cnpj: "",
        dataAtivacao: "",
        dataCriacao: "",
        dataDesativacao: "",
        emailResponsavel: "",
        emailSac: "",
        idParceiro: 0,
        nomeResponsavel: "",
        razaoSocial: "",
        status: false,
        telefoneResponsavel: "",
        telefoneSac: "",
        tipoParceiro: 0,
    })
    const [selectedState, setSelectedState] = useState("")
    const [selectedCities, setSelectedCities] = useState([""])
    const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([""])
    const [selectedStores, setSelectedStores] = useState([""])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const wipeOutPartnerData = () => {
        setPartnerData(
            {
                aceite: false,
                cnpj: "",
                dataAtivacao: "",
                dataCriacao: "",
                dataDesativacao: "",
                emailResponsavel: "",
                emailSac: "",
                idParceiro: 0,
                nomeResponsavel: "",
                razaoSocial: "",
                status: false,
                telefoneResponsavel: "",
                telefoneSac: "",
                tipoParceiro: 0,
            }
        )
        setSelectedState("")
        setSelectedCities([""])
        setSelectedNeighborhoods([""])
        setSelectedStores([""])
        setStartDate(new Date())
        setEndDate(new Date())
        console.log("PARCEIRO DESLOGADO")
    }

    return (
        <PartnerContext.Provider
            value={{
                isLoading,
                setIsLoading,

                partnerData,
                setPartnerData,

                selectedState,
                setSelectedState,

                selectedCities,
                setSelectedCities,

                selectedNeighborhoods,
                setSelectedNeighborhoods,

                selectedStores,
                setSelectedStores,

                startDate,
                setStartDate,

                endDate,
                setEndDate,

                wipeOutPartnerData
            }}
        >
            {children}
        </PartnerContext.Provider>
    );
}

function usePartnerContext() {
    const context = useContext(PartnerContext);
    return context;
}

export { PartnerContextProvider, usePartnerContext };
