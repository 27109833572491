import styled, { css } from "styled-components";
import { colors } from "tokens";

interface ContainerProps {
  checked: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 16px;
  height: 16px;
  background: ${({ checked }) => (checked ? "#0054A6" : "#ffffff")};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 1px;

  &:hover {
    cursor: pointer;
  }

  ${({ checked }) =>
    !checked &&
    css`
      border: 1px solid ${colors.gray.blue};
      border-radius: 1px;
    `}
`;
