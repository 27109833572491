import "./dateRangeModal.scss";
import { DateRange } from "react-date-range";
import { ptBR } from "react-date-range/src/locale";
import { useEffect, useState } from "react";
import { usePartnerContext } from "../../../contexts/partnerContextProvider";

export default function DateRangeModal({ open, setOpen, context }) {
  const { startDate, endDate, setStartDate, setEndDate } = usePartnerContext();
  const [dates, setDates] = useState(
    context === null
      ? [
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]
      : context[0]
  );

  useEffect(() => {
    if (context === null) {
      setStartDate(dates[0].startDate);
      setEndDate(dates[0].endDate);
    } else {
      const [selectedDates, setSelecteDates] = context;

      setSelecteDates([
        {
          startDate: dates[0].startDate,
          endDate: dates[0].endDate,
          key: "selection",
        },
      ]);
    }
  }, [dates]);

  const overlayHandler = (e: any) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  const handleSelect = (item: any) => {
    setDates([item.selection]);
  };

  return (
    <>
      {open && (
        <div
          className="dateRangePickerModalOverlay"
          onClick={(e) => overlayHandler(e)}
        >
          <div className="dateRangePickerModal">
            <DateRange
              weekdayDisplayFormat="EEEEEE"
              dayDisplayFormat="dd"
              dateDisplayFormat="dd/MM/yyyy"
              locale={ptBR}
              editableDateInputs={true}
              onChange={handleSelect}
              rangeColors={["#0054a6"]}
              moveRangeOnFirstSelection={false}
              ranges={dates}
              startDatePlaceholder="Data inicial"
              endDatePlaceholder="Data final"
              fixedHeight={true}
            />

            <button
              className="closeModalBtn"
              onClick={() => {
                setOpen(false);
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
