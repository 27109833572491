import { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { Input } from "./styles";

interface CheckboxParceriasAtivas {
  name: string;
  checked: boolean;
  onClickChecked: () => void;
}

type CheckboxProps = JSX.IntrinsicElements["input"] & CheckboxParceriasAtivas;

export function CheckboxParceriasAtivas({
  name,  
  checked,
  onClickChecked,
}: CheckboxProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    setIsChecked(checked);
  }, []);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref: any) => {
        if (!ref.current.value) {
          return "";
        }
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },      
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  const [isChecked, setIsChecked] = useState(checked);

  function handleClick() {
    setIsChecked(!isChecked);
    onClickChecked();
  }

  return (
    <Input 
     ref={inputRef}
      onClick={() => {
        handleClick();
      }}
      key={"somenteAtivas"}
      name="somenteAtivas"
      type="checkbox"
      checked={isChecked}
    />    
  );
}
