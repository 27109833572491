import styled from "styled-components";
import { colors } from "tokens";

interface ContainerProps {
  isDefaultValue: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: fit-content;

  display: flex;
  align-items: center;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;

  margin-left: 0.688rem;

  input {
    color: ${({ isDefaultValue }) => (isDefaultValue ? "#7B8FA5" : "#2A333E")};
  }
`;

export const HourInterval = styled.div`
  display: flex;
  flex-direction: row;

  div {
    display: flex;
  }

  span,
  input {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    width: 10px;
  }

  &:first-child {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }

  button {
    background: transparent !important;
    border: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const Hour = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40px;

  input {
    width: 40px;
    height: 40px;

    border: 1px solid ${colors.gray.light};
    border-radius: 4px;

    margin: 8px 0;
  }
`;

export const Minute = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40px;

  input {
    width: 40px;
    height: 40px;

    border: 1px solid ${colors.gray.light};
    border-radius: 4px;

    margin: 8px 0;
  }
`;
