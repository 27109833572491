import styled from "styled-components";
import colors from "../../../tokens/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    color: #2A333E;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .gridTitle {
    text-align: start;
    margin: 0;
    margin-bottom: 8px;
    font-weight: 600;
    color: ${colors.textDarkModal};
  }

  p {
    margin-bottom: 32px;
    font-family: "Source Sans Pro", sans-serif;
    color: ${colors.gray.text};
    font-size: 16px;
    text-align: center;
  }

  button {
    font-family: "Source Sans Pro", sans-serif;
  }

  .custom-display-grid {
    display: inherit;
  }
`;

export const CloseModal = styled.div`
  img {
    position: absolute;
    width: 12px;
    height: 13.62px;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`;

export const ContainerTableDetails = styled.div`
  table {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    thead {
      tr {
        th {
          color: ${colors.textDarkModal};
          padding-bottom: 8px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
          padding-left: 0;
          padding-top: 0;
          color: ${colors.gray.text};
        }

        td:first-child {
          width: 302px;
        }
      }
    }
  }
`;

export const ContainerTableDetailsCEP = styled.div`
  margin-bottom: 24px;

  table {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;

    thead {
      tr {
        th {
          color: ${colors.textDarkModal};
          padding-bottom: 8px;
        }
      }
    }

    tbody {
      tr {
        td {
          color: ${colors.gray.text};
          width: 194px;
        }

        td:first-child {
          width: 109px;
        }

        td:last-child {
          width: 160px;
        }
      }
    }
  }
`;

export const ContainerButton = styled.div`
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 48px;
  }
`;
