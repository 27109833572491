import { useEffect, useState } from "react";

import { Container } from "./styles";

interface PaginationProps {
  numberOfPages: number;
  onChangePage: (currentPage: number) => void;
}

export function Pagination({ numberOfPages, onChangePage }: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [numberOfPages]);

  const arrayPages = Array.from({
    length: numberOfPages,
  }).map((_, index) => index + 1);

  useEffect(() => {
    onChangePage(currentPage);
  }, [currentPage]);

  function handleNextPage() {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function renderPagination(page: number) {
    if (numberOfPages > 5 && page > 5) {
      return null;
    }

    return (
      <button
        key={page}
        type="button"
        className={page === currentPage ? "selected" : ""}
        onClick={() => setCurrentPage(page)}
      >
        {page}
      </button>
    );
  }

  function renderMoreThanFivePages() {
    const lastPages = [numberOfPages - 1, numberOfPages];

    return (
      <>
        {currentPage === 1 && (
          <>
            <button
              type="button"
              className="selected"
              onClick={() => setCurrentPage(1)}
            >
              {1}
            </button>
            <button type="button" onClick={() => setCurrentPage(2)}>
              {2}
            </button>
          </>
        )}
        {currentPage > 1 && (
          <>
            <button
              type="button"
              onClick={() =>
                setCurrentPage(
                  currentPage >= lastPages[0]
                    ? lastPages[0] - 2
                    : currentPage - 1
                )
              }
            >
              {currentPage >= lastPages[0] ? lastPages[0] - 2 : currentPage - 1}
            </button>
            <button
              type="button"
              className={currentPage >= lastPages[0] ? "" : "selected"}
              onClick={() =>
                setCurrentPage(
                  currentPage >= lastPages[0] ? lastPages[0] - 1 : currentPage
                )
              }
            >
              {currentPage >= lastPages[0] ? lastPages[0] - 1 : currentPage}
            </button>
          </>
        )}
        {currentPage < lastPages[0] -1 && (
          <button type="button" disabled>
            ...
          </button>
        )}
        {renderLastPages(lastPages)}
      </>
    );
  }

  function renderLastPages(lastPages: number[]) {
    return (
      <>
        <button
          type="button"
          className={lastPages[0] === currentPage ? "selected" : ""}
          onClick={() => setCurrentPage(lastPages[0])}
        >
          {lastPages[0]}
        </button>
        <button
          type="button"
          className={lastPages[1] === currentPage ? "selected" : ""}
          onClick={() => setCurrentPage(lastPages[1])}
        >
          {lastPages[1]}
        </button>
      </>
    );
  }

  return (
    <Container>
      <button
        type="button"
        className={currentPage === 1 ? "disabled" : ""}
        onClick={handlePreviousPage}
      >
        <svg
          width="6"
          height="8"
          viewBox="0 0 6 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.08408 0.243701C4.87992 0.0395345 4.55075 0.0395345 4.34658 0.243701L0.884082 3.7062C0.721582 3.8687 0.721582 4.1312 0.884082 4.2937L4.34658 7.7562C4.55075 7.96037 4.87992 7.96037 5.08408 7.7562C5.28825 7.55203 5.28825 7.22287 5.08408 7.0187L2.06742 3.99787L5.08825 0.977035C5.28825 0.777035 5.28825 0.443701 5.08408 0.243701Z"
            fill="#0054A6"
          />
        </svg>
      </button>
      <div>
        {numberOfPages <= 5 && arrayPages.map((page) => renderPagination(page))}
        {numberOfPages > 5 && renderMoreThanFivePages()}
      </div>
      <button
        type="button"
        className={currentPage === numberOfPages ? "disabled" : ""}
        onClick={handleNextPage}
      >
        <svg
          width="6"
          height="8"
          viewBox="0 0 6 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.915857 7.7562C1.12002 7.96037 1.44919 7.96037 1.65336 7.7562L5.11586 4.2937C5.27836 4.1312 5.27836 3.8687 5.11586 3.7062L1.65336 0.243701C1.44919 0.0395345 1.12002 0.0395345 0.915857 0.243701C0.71169 0.447868 0.71169 0.777035 0.915857 0.981201L3.93252 4.00203L0.91169 7.02287C0.71169 7.22287 0.71169 7.5562 0.915857 7.7562Z"
            fill="#0054A6"
          />
        </svg>
      </button>
    </Container>
  );
}
