import { Button } from 'primereact/button'
import { useEffect, useImperativeHandle, useState } from 'react'
import { partnerService } from 'services/partnerService'

export const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
  ref,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const [isPartner, setIsPartner] = useState(false)

  useEffect(() => {
    setIsPartner(!!props.state.infoPartner?.idParceiro)
  }, [props.state.infoPartner?.idParceiro])

  useImperativeHandle(ref, () => ({
    handleNextStepStats() {
      if (step === 1 && props.state.form['email']) {
        if (props.state.infoPartner?.idParceiro) {
          nextStep()
          return
        }

        setLoading(true)
        getPartnerByEmail(props.state.form['email'])
          .then(data => {
            if (data.success) {
              props.update('dadosapi', { ...data.data })
              nextStep()
            }
          })
          .catch(error =>
            props.setMessage(
              error.response.data['message'] || error.response.data.message
            )
          )
          .finally(() => setLoading(false))
      }
      if (step === 2) {
        if (isPartner) console.log('EDIÇÃO')
        props.onSubmit()
      }
      if (step === 3) {
        if (!props.selectedStore || props.selectedStore.length === 0)
          props.setMessage('Necessário selecionar pelo menos uma loja.')
        else {
          props.update('lojas', props.selectedStore)
          props.update('lojasDesvinculadas', props.selectedStoreUnlink)
          if (isPartner && props.hasChanges) {
            props.setHeaderDialog('Alerta')
            props.setMessage('Deseja realmente realizar essas alterações?')
          } else {
            nextStep()
          }
        }
      }
      if (step === 4) {
        if (!props.selectedService || props.selectedService.length === 0)
          props.setMessage(
            'Necessário selecionar pelo menos uma serviço ofertado.'
          )
        else {
          props.update('servicos', props.selectedService)
          props.update('servicosDesvinculados', props.selectedServiceUnlink)
          if (isPartner && props.hasChanges) {
            props.setHeaderDialog('Alerta')
            props.setMessage('Deseja realmente realizar essas alterações?')
          } else {
            nextStep()
          }
        }
      }
    }
  }))

  const getPartnerByEmail = (email: string) =>
    partnerService.getPartnerByEmail(email)

  const handleNextStep = () => {
    if (step === 1 && props.state.form['email']) {
      if (props.state.infoPartner?.idParceiro) {
        nextStep()
        return
      }

      setLoading(true)
      getPartnerByEmail(props.state.form['email'])
        .then(data => {
          if (data.success) {
            props.update('dadosapi', { ...data.data })
            nextStep()
          }
        })
        .catch(error =>
          props.setMessage(
            error.response.data['message'] || error.response.data.message
          )
        )
        .finally(() => setLoading(false))
    }
    if (step === 2) {
      if (isPartner) console.log('EDIÇÃO')
      props.onSubmit()
    }
    if (step === 3) {
      if (!props.selectedStore || props.selectedStore.length === 0)
        props.setMessage('Necessário selecionar pelo menos uma loja.')
      else {
        props.update('lojas', props.selectedStore)
        props.update('lojasDesvinculadas', props.selectedStoreUnlink)
        if (isPartner && props.hasChanges) {
          props.setHeaderDialog('Alerta')
          props.setMessage('Deseja realmente realizar essas alterações?')
        } else {
          nextStep()
        }
      }
    }
    if (step === 4) {
      if (!props.selectedService || props.selectedService.length === 0)
        props.setMessage(
          'Necessário selecionar pelo menos uma serviço ofertado.'
        )
      else {
        props.update('servicos', props.selectedService)
        props.update('servicosDesvinculados', props.selectedServiceUnlink)
        if (isPartner && props.hasChanges) {
          props.setHeaderDialog('Alerta')
          props.setMessage('Deseja realmente realizar essas alterações?')
        } else {
          nextStep()
        }
      }
    }
  }

  return (
    <>
      <hr />
      <div
        className={
          'buttons-interation ' + (step === 1 && 'first-step p-field p-col')
        }
      >
        {step > 1 && (
          <Button
            label="Voltar"
            className="p-button-outlined"
            onClick={previousStep}
          />
        )}
        {step < totalSteps ? (
          <Button
            label={'Continuar'}
            onClick={handleNextStep}
            disabled={loading}
            icon={`pi ${loading && 'pi-spin pi-spinner'}`}
            iconPos="left"
          />
        ) : (
          <Button
            label={
              !props.state.infoPartner?.idParceiro ? 'Salvar' : 'Atualizar'
            }
            icon={`pi ${props.loading ? 'pi-spin pi-spinner' : 'pi-check'}`}
            iconPos="left"
            disabled={props.loading}
            onClick={props.handleSave}
            className="p-button-success"
          />
        )}
        {/* <div style={{ fontSize: '21px', fontWeight: 'lighter' }}>
            <h4>Other Functions</h4>
            <div>Current Step: {currentStep}</div>
            <div>Total Steps: {totalSteps}</div>
            <button className='btn btn-block btn-default' onClick={firstStep}>First Step</button>
            <button className='btn btn-block btn-default' onClick={lastStep}>Last Step</button>
            <button className='btn btn-block btn-default' onClick={() => goToStep(2)}>Go to Step 2</button>
        </div> */}
      </div>
    </>
  )
}
