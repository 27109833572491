import { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import errorInputMessageIcon from "images/icons/error-input-message-icon.svg";

import { Container } from "./styles";

interface Props {
  name: string;
  type?: string;
  label?: string;
  inputPadding?: string;
}

type InputProps = JSX.IntrinsicElements["input"] & Props;

export function Input({ name, type = "text", label, inputPadding = "7px 60px 7px 15px", ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current?.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container inputPadding={inputPadding}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <input
        id={fieldName}
        ref={inputRef}
        type={type}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <span>
          <img src={errorInputMessageIcon} alt="Erro" />* {error}
        </span>
      )}
    </Container>
  );
}
