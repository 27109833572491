import styled from "styled-components";

import { colors } from "../../tokens";

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  width: 924px;
  height: 589px;
  margin-left: 20px;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-size: 22px;
    font-weight: 600;
    color: ${colors.dark};
    margin: 0;
  }

  .atualizarDados {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0054a6;

    position: absolute;
    right: 32px;
    top: 117px;

    img {
      margin-right: 8px;
    }

    &:hover {
      filter: brightness(1.2);
      cursor: pointer;
    }
  }

  .contentBody {
    position: relative;
    margin-top: 16px;

    height: 100%;

    border: 1px solid ${colors.gray.light};
    border-radius: 4px;

    .tabHeader {
      display: flex;

      border-bottom: 1px solid ${colors.gray.light};

      margin: 0 32px;
    }

    .form {
      margin: 32px;

      #required label::after {
        content: " (obrigatório)";
        color: ${colors.gray.blue};
      }

      .formRow {
        display: flex;
        margin-top: 24px;

        .inputCpf {
          display: flex;
          flex - direction: row;
        }

        input {
          color: ${colors.dark} !important;

          &::placeholder, &:disabled {
            color: ${colors.gray.blue} !important;
          }
        }

        .cpf {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
  .contentFooter {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 32px;
    right: 32px;
    margin-top: 62px;

    button {
      padding: 12px 24px;
    }

    button:disabled {
      background: #f2f2f2;
      color: ${colors.gray.text};
      cursor: not-allowed;
    }

    button + button {
      margin-left: 24px;
    }
  }

  .errorMessage {
    position: absolute;
    right: 32px;
    bottom: 0;
    color: #d14a4a;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const Container = styled.div`
  max-height: 480px;
  margin: 32px 0 24px 32px;
  overflow-y: auto;
  overflow-x: hidden;

  font-family: 'Source Sans Pro', sans-serif;

  p {
    font-weight: normal;
    font-size: 14px;
    color: #404F61;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  .formRow {
     & + .formRow {
       margin-top: 24px;
     }
    .inputCpf {
      display: flex;
      flex-direction: row;
    }

    .cpfNome {
      display: flex;

      label {
        font-weight: 600;
        color: #2A333E;
      }

      span {
        color: #404F61;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-right: 24px;
      }
    }

    .parceriaTable {
      margin-top: 56px;
    }
  }
`;
