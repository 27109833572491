import { Axios as axios } from "../config/axiosConfig";
import { toast } from "react-toastify";

export const partnerScheduleControlService = {
  acquirePartnerStates: async (partnerId, admin = false) => {
    const url = admin
      ? `/LojaData/GetEstados`
      : `/ParceiroLojaSalaData/ParceiroLojaSala/GetEstadosParceiro/${partnerId}`;

    const req = await axios.get(url);

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerCities: async (partnerId, state) => {
    const url = `/ParceiroLojaSalaData/ParceiroLojaSala/GetCidadesParceiro/${partnerId}?uf=${state}`;

    const req = await axios.get(url);

    const {data, status} = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerStores: async (partnerId, stateUF = null, admin = false) => {
    let url = admin
      ? `/LojaData/loja/GetLojasCidades`
      : `/ParceiroLojaSalaData/ParceiroLojaSala/GetLojasParceiro/${partnerId}`;

    if (stateUF !== null) {
      admin ? (url += `?estados=${stateUF}`) : (url += `?siglaUf=${stateUF}`);
    }

    const req = await axios.get(url);

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  acquirePartnerStoreSchedules: async (partnerId, storeId) => {
    const req = await axios.get(
      `/ParceiroLojaSalaData/ParceiroLojaSala/GetAgendaLojaSalaConfig/${partnerId}/${storeId}`
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  insertNewSchedulePeriod: async (insertData) => {
    const req = await axios.post(`/ParceiroLojaSalaData/ParceiroLojaSala/AddAgendaLojaSalaConfig`,
      insertData
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  deleteSchedulePeriod: async (partnerId, periodId) => {
    const req = await axios.delete(
      `/ParceiroLojaSalaData/ParceiroLojaSala/DeletaAgendaLojaSalaConfig?idParceiro=${partnerId}&idAgendaLojaSalaConfig=${periodId}`
    );

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  insertNewRoom: async (roomName, storeId) => {
    const req = await axios.post(
      `/LojaSalaData/LojaSala/loja/${storeId}/sala`,
      {
        nomeSala: `${roomName}`,
      });

    const { data, status } = req;

    if (status === 201) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  linkStoreToRoom: async (roomId, storeId) => {
    const req = await axios.post(
      `/LojaSalaData/LojaSala/loja/${storeId}/sala/${roomId}`);

    const { data, status } = req;

    if (status === 200 || status === 201) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },

  acquireReasons: async () => {

    const req = await axios.get(`/LojaSalaData/LojaSala/motivo`);

    const { data, status } = req;

    if (status === 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }

  },

  manageRoomStatus: async (sendData, userData) => {
    const { IdLojaSala, IdDesativacaoSalaMotivo, Motivo, action } = sendData;
    const { cod, name, role } = userData;

    let activation = {
      Adicionar: null,
      IdDesativacaoSalaMotivo,
      Motivo,
    };

    action === "REACTIVATE"
      ? (activation.Adicionar = true)
      : (activation.Adicionar = false);

    try {
      const req = await axios.put(
        `/LojaSalaData/LojaSala/${IdLojaSala}`,
        activation,
        {
          headers: {
            codigo: cod,
            nome: name,
            cargo: role
          },
        }
      );

      const { data, status } = req;

      if (status >= 200 && status <= 299) {
        return true;
      } else {
        toast.error(data.message);
        return false;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return false;
    }
  },
};
