import { ReactNode } from "react";
import Modal from "react-modal";

interface ModalBaseProps {
  children: ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  type?: "small" | "medium" | "large" | "large-width";
}

export function ModalBase({
  children,
  isOpen,
  onRequestClose,
  type = "medium",
}: ModalBaseProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className={
        type === "medium"
          ? "react-modal-content"
          : type === "large"
          ? "react-modal-content-large"
          : "react-modal-content-large-width"
      }
    >
      {children}
    </Modal>
  );
}
